import React, { Component } from "react";
import { connect } from "react-redux";
import AppFlowBreadcrumbStatic from "./AppFlowBreadcrumbStatic";
// import Button from '@mui/material/Button'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import { ApplicationAPICRUDOperations } from "../../statesandlogics/ApiCalls/Api";
import { IconButton } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RotateRightRoundedIcon from "@mui/icons-material/RotateRightRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import * as api from "../../statesandlogics/ApiCalls/Api";

class A00Workflow extends Component {
  componentDidUpdate(prevProps) {
    const { editDoc, cd, ag, st, APICRUDOperations } = this.props;
    if (
      this.props.isUserDataValid != prevProps.isUserDataValid &&
      editDoc.selectedIdx === 0
    ) {
      let selectedStages = editDoc.Stages.filter(
        (x) =>
          x.status === cd.status &&
          (x.AppUser === "Admin") === (ag.isCitizenLoggedIn === false)
      );
      let selectedStage = selectedStages.length > 0 ? selectedStages[0] : st;
      let selectedPage = selectedStage.ControlStates.Pages[editDoc.selectedIdx];
      if (this.props.isUserDataValid === true)
        APICRUDOperations(cd, selectedPage.Right.ButtonActionName);
    }
  }

  componentDidMount() {
    const { editDoc } = this.props;
    editDoc.selectedIdx = 0;
  }

  render() {
    const {
      rname,
      validateUserData,
      allInnerRoutes,
      setSelectedIdx,
      editDoc,
      cd,
      ag,
      st,
      APICRUDOperations,
      isUserDataValid,
      downloadReceipt,
    } = this.props;
    let selectedStages = editDoc.Stages.filter(
      (x) =>
        x.status === cd.status &&
        (x.AppUser === "Admin") === (ag.isCitizenLoggedIn === false)
    );
    let selectedStage = selectedStages.length > 0 ? selectedStages[0] : st;
    let selectedPage = selectedStage.ControlStates.Pages[editDoc.selectedIdx];
    let appNumberLabel = cd.application_id
      ? cd.application_id
      : "Application In Progress";
    let appStatusLabel =
      cd.status && cd.status.length > 0 ? cd.status : "NEW APPLICATION";
    appStatusLabel =
      appStatusLabel === "ToBeCreatedApplication"
        ? "NEW APPLICATION"
        : appStatusLabel === "In Progress"
        ? "IN PROGRESS"
        : appStatusLabel === "Pending"
        ? "APPROVAL PENDING"
        : appStatusLabel === "Approved"
        ? "APPROVED"
        : appStatusLabel === "Rejected"
        ? "REJECTED"
        : "ISSUED";

    return (
      <div className="wf-container-border padding-7px">
        <div className="wf-container padding-7px">
          <div className="wf-floatLeft">
            {/* <Typography variant="h7" gutterBottom sx={{ border: "ButtonText" }}>
              {appStatusLabel}
            </Typography> */}
          </div>
          <div className="wf-remainCenter">
            {/* <Typography variant="h7" gutterBottom sx={{ color: "blue" }}>
              APPLICATION - {appNumberLabel}
            </Typography> */}
            {appStatusLabel === "NEW APPLICATION" ? (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  border: `2px solid #3B67AF`,
                  backgroundColor: "white",
                  padding: "4px 12px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#3B67AF",
                  fontFamily: "poppins",
                }}
              >
                <LibraryAddRoundedIcon
                  style={{ marginRight: "8px", color: "#3B67AF" }}
                />
                <span> {appStatusLabel} : </span>

                <span style={{ marginLeft: "8px", fontWeight: 500 }}>
                  {appNumberLabel}
                </span>
              </div>
            ) : appStatusLabel === "IN PROGRESS" ? (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  border: `2px solid #C7C2E6`,
                  backgroundColor: "#FBFAFF",
                  padding: "4px 12px",
                  fontFamily: "poppins",
                }}
              >
                <RotateRightRoundedIcon
                  style={{ marginRight: "8px", color: "#AAA0EA" }}
                />
                <span
                  style={{
                    marginRight: "8px",
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Application
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  #{appNumberLabel}
                </span>
                <span
                  style={{
                    marginLeft: "2px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#AAA0EA",
                  }}
                >
                  - {appStatusLabel}
                </span>
              </div>
            ) : appStatusLabel === "APPROVAL PENDING" ? (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  border: `2px solid #F79247`,
                  backgroundColor: "#FEF7EC",
                  padding: "4px 12px",
                  fontFamily: "poppins",
                }}
              >
                <WarningRoundedIcon
                  style={{ marginRight: "8px", color: "#F79247" }}
                />
                <span
                  style={{
                    marginRight: "8px",
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Application
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  #{appNumberLabel}
                </span>
                <span
                  style={{
                    marginLeft: "2px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#F79247",
                  }}
                >
                  - {appStatusLabel}
                </span>
              </div>
            ) : appStatusLabel === "REJECTED" ? (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  border: `2px solid #FADBDF`,
                  backgroundColor: "#FFF9F9",
                  padding: "4px 12px",
                  fontFamily: "poppins",
                }}
              >
                <CancelRoundedIcon
                  style={{ marginRight: "8px", color: "#F9473F" }}
                />
                <span
                  style={{
                    marginRight: "8px",
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Application
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  #{appNumberLabel}
                </span>

                <span
                  style={{
                    marginLeft: "2px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#F9473F",
                  }}
                >
                  - {appStatusLabel}
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  border: `2px solid #C6E3CB`,
                  backgroundColor: "#F2FAF3",
                  padding: "4px 12px",
                  fontFamily: "poppins",
                }}
              >
                <CheckCircleRoundedIcon
                  style={{ marginRight: "8px", color: "#00954D" }}
                />
                <span
                  style={{
                    marginRight: "8px",
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Application
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  #{appNumberLabel}
                </span>
                <span
                  style={{
                    marginLeft: "2px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#00954D",
                  }}
                >
                  - {appStatusLabel}
                </span>
              </div>
            )}
          </div>
          <div>
            {appStatusLabel !== "NEW APPLICATION" ? (
              <Button
                variant="contained"
                startIcon={<DownloadRoundedIcon />}
                onClick={() => {
                  if (cd.status === "Approved" || cd.status === "Fulfilled") {
                    downloadReceipt(cd.application_id);
                  } else {
                    alert(
                      "This receipt is not final until the application is approved."
                    );
                    downloadReceipt(cd.application_id);
                  }
                }}
                className="wf-floatRight"
                sx={{
                  textTransform: "none",
                  marginTop: "2px",
                  fontSize: 16,
                  backgroundColor: "#162B42",
                  fontWeight: 400,
                  color: "white",
                  // borderRadius: 5,
                  "&:hover": { backgroundColor: "#106CE7" },
                }}
              >
                Receipt Download
              </Button>
            ) : (
              <div className="wf-floatRight"></div>
            )}
          </div>
        </div>

        <div className="wf-container padding-7px">
          {selectedPage.Left.ButtonVisibility === "Visible" ? (
            <Button
              variant="contained"
              color="secondary"
              className="wf-floatLeft"
              disabled={selectedPage.Left.ButtonState === "disabled"}
              onClick={(e) => {
                e.preventDefault();

                APICRUDOperations(cd, selectedPage.Left.ButtonActionName);
              }}
              sx={{
                backgroundColor: "#F2595F",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {selectedPage.Left.ButtonText}
            </Button>
          ) : null}
          <div className="wf-remainCenter"></div>
          {selectedPage.Right.ButtonVisibility === "Visible" ? (
            <Button
              variant="contained"
              color="primary"
              className="wf-floatRight"
              disabled={selectedPage.Right.ButtonState === "disabled"}
              onClick={(e) => {
                e.preventDefault();
                if (
                  editDoc.selectedIdx === 0
                  // &&
                  // selectedPage.Right.ButtonText === "Create Application"
                ) {
                  validateUserData();
                  // if (isUserDataValid === true) {
                  //   APICRUDOperations(cd, selectedPage.Right.ButtonActionName);
                  // }
                } else {
                  APICRUDOperations(cd, selectedPage.Right.ButtonActionName);
                }
              }}
              sx={{
                backgroundColor: "#06B58C",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {selectedPage.Right.ButtonText}
            </Button>
          ) : null}
        </div>
        <div className="wf-container padding-2px">
          <Button
            variant="contained"
            className="wf-floatLeft"
            disabled={editDoc.selectedIdx <= 0}
            label="Previous"
            onClick={(e) => {
              setSelectedIdx(editDoc.selectedIdx - 1);
            }}
            sx={{
              backgroundColor: "#162B42",
              color: "white",
              // borderRadius: 5,
              "&:hover": { backgroundColor: "#106CE7" },
            }}
          >
            <ChevronLeftIcon
              sx={{
                width: "25px",
                // marginLeft: "5%",
                "&:hover": { cursor: "pointer" },
                "@media (max-width: 1200px)": {
                  height: "30px",
                  width: "30px",
                },
                "@media (max-width: 634px)": {
                  height: "15px",
                  width: "15px",
                  padding: 0,
                },
              }}
            />
            <Typography
              sx={{
                "@media (max-width: 634px)": {
                  fontSize: 8,
                  padding: 0,
                },
                "@media (max-width: 434px)": {
                  display: "none",
                },
              }}
            >
              Previous
            </Typography>
          </Button>
          <div className="wf-remainCenter">
            <AppFlowBreadcrumbStatic />
          </div>
          <Button
            variant="contained"
            color="primary"
            className="wf-floatRight"
            disabled={editDoc.selectedIdx >= 2}
            onClick={() => {
              setSelectedIdx(editDoc.selectedIdx + 1);
            }}
            sx={{
              backgroundColor: "#162B42",
              color: "white",
              // borderRadius: 5,
              "&:hover": { backgroundColor: "#106CE7" },
            }}
          >
            <Typography
              sx={{
                "@media (max-width: 634px)": {
                  fontSize: 8,
                  padding: 0,
                },
                "@media (max-width: 434px)": {
                  display: "none",
                },
              }}
            >
              Next
            </Typography>

            <NavigateNextIcon
              sx={{
                width: "25px",
                "&:hover": { cursor: "pointer" },
                "@media (max-width: 634px)": {
                  height: "15px",
                  width: "15px",
                  padding: 0,
                },
              }}
            />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen,
  editDoc: state.editDoc,
  cd: state.currentDocument,
  ag: state.appGlobals,
  st: state.stageTemplate,
  isUserDataValid: state.appGlobals.isCurrentUserDataValid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    downloadReceipt: (val) => api.downloadReceipt(val, dispatch),
    validateUserData: () => dispatch({ type: "VALIDATE_USER_DATA" }),
    setSelectedIdx: (idx) =>
      dispatch({ type: allEvents.SET_AFB_SEL_IDX, value: idx }),
    APICRUDOperations: (cd, reqOp) =>
      ApplicationAPICRUDOperations(cd, reqOp, dispatch),
    openSidebar: (val) =>
      dispatch({ type: "OPEN_SIDEBAR", value: true, click: val }),

    closeSidebar: (val) =>
      dispatch({ type: "CLOSE_SIDEBAR", value: false, click: val }),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(A00Workflow);
