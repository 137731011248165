import { ApplicationOperations } from "./cnstshelpers";

export const initState = {
  transactionReportDate: {
    date: "",
    endDate: "",
  },
  transactionReportMode: "single",
  isModalOpen: false,
  appGlobals: {
    current_period: {},
    adminIdentity: null,
    searchFirstTimeLoaded: true,
    isLoggedIn: true,
    isCitizenLoggedIn: false,
    showApplication: false,
    showCitizenApplication: false,
    isCurrentUserDataValid: null,
    isAddingOrEditingVehicleRecordValid: null,
    newVehicleId: -1,
    newPetId: -1,
    isAddingOrEditingPetRecordValid: null,
    applicationErrorMessages: [
      {
        name: "ValidVehicle",
        errorMessage: "",
      },
    ],
    stickercardstyles: {
      psApplicationTab: {
        display: "flex",
        width: 280,
        borderRadius: 1,
        height: 120,
        padding: 2,
        paddingTop: 0.5,
        margin: 0,
        // marginRight: 5,
        //  marginRight: 5,
        "@media (max-width: 400px)": {
          marginRight: 0,
        },
        backgroundColor: "#F1FFF1",
        // marginBottom: 5,
        border: 3,
        borderRadius: 5,
        borderColor: "#367974",
      },
      vsApplicationTab: {
        display: "flex",
        width: 280,
        borderRadius: 1,
        padding: 2,
        paddingTop: 0.5,
        height: 120,
        // marginRight: 5,
        margin: 0,
        // marginRight: 5,
        "@media (max-width: 400px)": {
          marginRight: 0,
        },
        backgroundColor: "#E2F5FB",
        // marginBottom: 5,
        border: 3,
        borderRadius: 5,
        borderColor: "#046E9E",
      },
    },
  },

  currentUserValidations: {
    family_name: {
      error: false,
      errorMsg: "",
    },
    first_name: {
      error: false,
      errorMsg: "",
    },
    phone_number: {
      error: false,
      errorMsg: "",
    },
    email: {
      error: false,
      errorMsg: "",
    },
    address: {
      address_line_1: {
        error: false,
        errorMsg: "",
      },
      city: {
        error: false,
        errorMsg: "",
      },
      postal_code: {
        error: false,
        errorMsg: "",
      },
      state_id: {
        error: false,
        errorMsg: "",
      },
    },
  },
  currentlyEditingPet: {
    tag_number: null,
    pet_id: null,
    name: "",
    breed: "",
    color: "",
    sex: "",
    rabies_certificate_number: "",
    rabies_certificate_expiry: null,
    sticker_tag_number: null,
    sticker_tag_expiry: null,
    late_fee_applied: false,
    prorated_fee_applied: false,
    sticker_code: {
      code_id: null,
      code_description: "",
      fee: 0,
      code_type: "",
    },
  },
  currentlyEditingVehicle: {
    tag_number: null,
    vehicle_id: null,
    plate_number: "",
    make: "",
    model: "",
    color: "",
    model_year: null,
    sticker_tag_number: null,
    sticker_tag_expiry: null,
    late_fee_applied: false,
    prorated_fee_applied: false,
    sticker_code: {
      code_id: null,
      code_description: "",
      fee: 0,
      // formatted_fee:"",
      code_type: "",
    },
  },
  currentlyEditingVehicleValidations: {
    plate_number: {
      error: false,
      errorMsg: "",
    },
    make: {
      error: false,
      errorMsg: "",
    },
    model: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    model_year: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  },
  currentlyEditingPetValidations: {
    name: {
      error: false,
      errorMsg: "",
    },
    breed: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    sex: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_number: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_expiry: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  },
  stageTemplate: {
    status: "",
    AppUser: "",
    ControlStates: {
      Pages: [
        {
          stageMessage: "This is error",
          FocussedIndex: 0,
          Left: {
            ButtonText: "Not Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
          Right: {
            ButtonText: "Submit Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
        },
        {
          stageMessage: "This is error",
          FocussedIndex: 1,
          Left: {
            ButtonText: "Not Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
          Right: {
            ButtonText: "Submit Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
        },
        {
          stageMessage: "This is error",
          FocussedIndex: 2,
          Left: {
            ButtonText: "Not Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
          Right: {
            ButtonText: "Submit Application",
            ButtonActionName: ApplicationOperations.Approve,
            ButtonState: "disabled",
          },
        },
      ],
    },
  },
  editDoc: {
    Stages: [
      {
        status: "ToBeCreatedApplication",
        AppUser: "Admin",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Fill application here",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "enabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Application details to details tab",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Reject Application",
                ButtonActionName: ApplicationOperations.Reject,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Application details to details tab",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
          ],
        },
      },
      {
        status: "In Progress",
        AppUser: "Admin",
        ControlStates: {
          Pages: [
            {
              stageMessage:
                "Upload documents, take payments and update the application.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "enabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Reject Application",
                ButtonActionName: ApplicationOperations.Reject,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
          ],
        },
      },
      {
        status: "Pending",
        AppUser: "Admin",
        ControlStates: {
          Pages: [
            {
              stageMessage:
                "Upload documents, take payments and update the application.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Reject Application",
                ButtonActionName: ApplicationOperations.Reject,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "enabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
          ],
        },
      },
      {
        status: "Approved",
        AppUser: "Admin",
        ControlStates: {
          Pages: [
            {
              stageMessage:
                "Issue a Tag number and expiry date in the last screen.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage:
                "Issue a Tag number and expiry date in the last screen.",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Reject Application",
                ButtonActionName: ApplicationOperations.Reject,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage:
                "Issue a Tag number and expiry date in the last screen.",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
          ],
        },
      },
      {
        status: "Fulfilled",
        AppUser: "Admin",
        ControlStates: {
          Pages: [
            {
              stageMessage: "View Only Application.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: ApplicationOperations.Create,
                ButtonState: "disabled",
                ButtonVisibility: "NotVisible",
              },
              Right: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "View Only Application.",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Reject Application",
                ButtonActionName: ApplicationOperations.Reject,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
            {
              stageMessage: "View Only Application.",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
                ButtonVisibility: "Visible",
              },
              Right: {
                ButtonText: "Issue Tag",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
                ButtonVisibility: "Visible",
              },
            },
          ],
        },
      },
      {
        status: "ToBeCreatedApplication",
        AppUser: "Citizen",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Fill application here",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: "PostApplication",
                ButtonState: "enabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Application details to details tab",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: "PostApplication",
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Application details to details tab",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Create Application",
                ButtonActionName: "PostApplication",
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
          ],
        },
      },
      {
        status: "In Progress",
        AppUser: "Citizen",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Please fill the application.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "enabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "enabled",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Details to be updated in details tab",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
          ],
        },
      },
      {
        status: "Pending",
        AppUser: "Citizen",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Submit documents and get application approved.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Submit documents and get application approved.",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "enabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "enabled",
              },
            },
            {
              stageMessage: "Submit documents and get application approved.",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
              },
            },
          ],
        },
      },
      {
        status: "Approved",
        AppUser: "Citizen",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Tag number is yet to be alloted.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Tag number is yet to be alloted.",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Tag number is yet to be alloted.",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
              },
            },
          ],
        },
      },
      {
        status: "Fulfilled",
        AppUser: "Citizen",
        ControlStates: {
          Pages: [
            {
              stageMessage: "Tag number is available.",
              FocussedIndex: 0,
              Left: {
                ButtonText: "Update Application",
                ButtonActionName: ApplicationOperations.Update,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Submit Application",
                ButtonActionName: ApplicationOperations.Submit,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Tag number is available.",
              FocussedIndex: 1,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
              },
            },
            {
              stageMessage: "Tag number is available.",
              FocussedIndex: 2,
              Left: {
                ButtonText: "Approve Application",
                ButtonActionName: ApplicationOperations.Approve,
                ButtonState: "disabled",
              },
              Right: {
                ButtonText: "Update tag number",
                ButtonActionName: ApplicationOperations.IssueTag,
                ButtonState: "disabled",
              },
            },
          ],
        },
      },
    ],
    isCitizenViewOnly: false,
    isDocumentViewOnly: false,
    selectedIdx: 0,
    previewIdx: 1,
    isAdmin: false,
    isViewOnly: false,
    selectPages: [
      {
        NewappPage: "EditDetails",
        ViewPage: "EditDetails",
        AdminPage: "EditDetails",
      },
      {
        NewappPage: "AdminPreviewApplication",
        ViewPage: "PreviewApplication",
        AdminPage: "DocumentUpload",
      },
      {
        NewappPage: "VisitOfficeScreen",
        ViewPage: "VisitOfficeScreen",
        AdminPage: "ExitScreen",
      },
    ],
  },

  SelectionCurrentDocument: {
    isValidEmail: false,
    submission_date: "",
    application_id: null,
    status: "",
    applicant: {
      applicant_id: null,
      family_name: "",
      first_name: "",
      phone_number: "",
      email: "",
      address: {
        address_id: null,
        address_line_1: "",
        address_line_2: "",
        city: "",
        postal_code: "",
        state: {
          state_id: null,
          abbreviation: "",
          full_name: "",
        },
      },
    },

    pets: [
      {
        tag_number: null,
        pet_id: null,
        name: "",
        breed: "",
        color: "",
        sex: "",
        rabies_certificate_number: "",
        rabies_certificate_expiry: null,
        sticker_tag_number: null,
        sticker_tag_expiry: null,
        late_fee_applied: false,
        prorated_fee_applied: false,
        sticker_code: {
          code_id: null,
          code_description: "",
          fee: null,
          // formatted_fee:"",
          code_type: "",
        },
      },
    ],

    vehicles: [
      {
        tag_number: null,
        vehicle_id: null,
        plate_number: "",
        make: "",
        model: "",
        color: "",
        model_year: null,
        sticker_tag_number: null,
        sticker_tag_expiry: null,
        late_fee_applied: false,
        prorated_fee_applied: false,
        sticker_code: {
          code_id: null,
          code_description: "",
          fee: null,
          // formatted_fee:"",
          code_type: "",
        },
      },
    ],
    documents: [
      {
        filepath: "",
        upload_completed: true,
        pet_id: 0,
        vehicle_id: 0,
        session_id: null,
        document_id: 0,
      },
    ],
    document_session_id: null,
    citizen_user_id: null,
    paymentAddress: {
      billingAddress: "",
      city: "",
      stat: "",
      zipCode: "",
    },
  },
  SelectionCurrentDocumentTemplate: {
    submission_date: "",
    application_id: null,
    status: "",
    applicant: {
      applicant_id: null,
      family_name: "",
      first_name: "",
      phone_number: "",
      email: "",
      address: {
        address_id: null,
        address_line_1: "",
        address_line_2: "",
        city: "",
        postal_code: "",
        state: {
          state_id: null,
          abbreviation: "",
          full_name: "",
        },
      },
    },

    pets: [
      {
        tag_number: null,
        pet_id: null,
        name: "",
        breed: "",
        color: "",
        sex: "",
        sticker_tag_number: null,
        sticker_tag_expiry: null,
        late_fee_applied: false,
        prorated_fee_applied: false,
        rabies_certificate_number: "",
        rabies_certificate_expiry: null,
        sticker_code: {
          code_id: null,
          code_description: "",
          fee: null,
          // formatted_fee:"",
          code_type: "",
        },
      },
    ],

    vehicles: [
      {
        tag_number: null,
        vehicle_id: null,
        plate_number: "",
        make: "",
        model: "",
        color: "",
        model_year: null,
        sticker_tag_number: null,
        sticker_tag_expiry: null,
        late_fee_applied: false,
        prorated_fee_applied: false,
        sticker_code: {
          code_id: null,
          code_description: "",
          fee: null,
          // formatted_fee:"",
          code_type: "",
        },
      },
    ],
    documents: [
      {
        filepath: "",
        upload_completed: true,
        pet_id: 0,
        vehicle_id: 0,
        session_id: null,
        document_id: 0,
      },
    ],
    document_session_id: null,
    citizen_user_id: null,
    paymentAddress: {
      billingAddress: "",
      city: "",
      stat: "",
      zipCode: "",
    },
  },
  SelectedVehicleTemplate: {
    tag_number: null,
    vehicle_id: null,
    plate_number: "",
    make: "",
    model: "",
    color: "",
    model_year: null,
    sticker_tag_number: null,
    sticker_tag_expiry: null,
    late_fee_applied: false,
    prorated_fee_applied: false,
    sticker_code: {
      code_id: null,
      code_description: "",
      fee: 0,
      // formatted_fee:"",
      code_type: "",
    },
  },
  SelectedVehicles: [],
  SelectedPetTemplate: {
    tag_number: null,
    pet_id: null,
    name: "",
    breed: "",
    color: "",
    sex: "",
    rabies_certificate_number: "",
    rabies_certificate_expiry: null,
    sticker_tag_number: null,
    sticker_tag_expiry: null,
    late_fee_applied: false,
    prorated_fee_applied: false,
    sticker_code: {
      code_id: null,
      code_description: "",
      fee: 0,
      // formatted_fee:"",
      code_type: "",
    },
  },
  SelectedPets: [],
  SelectedPaymentPageVariablesTemplate: {
    isChecked: false,
    fname: "",
    lname: "",
    cvc: "",
    expiry: "",
    focus: "",
    number: "",
  },
  SelectedPaymentPageVariables: [],

  showAlert: false,
  applicationDataTableVariables: {
    currentPage: 0,
  },
  progresStatus: "progress-bar",
  sidebarOpen: false,
  type: "",
  vehicleIndex: 0,
  feeTextfield: 0,
  petIndex: 0,
  ApiLocationLoaded: false,
  step: 0,
  mainContent: 10,
  useSetState: 0,
  setPage: 0,
  UploadPageVariables: {
    showFiles: false,
    uploaded_files: [],
    preview_uploaded_files: [],
    currentClick: null,
    zip_files: [],
    drop_style: "",
    isUploadSuccessful: false,
  },
  TransactionVariables: {
    number: null,
    type: "",
  },
  PaymentPageVariables: {
    isChecked: false,
    fname: "",
    lname: "",
    cvc: "",
    expiry: "",
    focus: "",
    number: "",
    billingAddress: "",
    city: "",
    stat: "",
    zipCode: "",
    clientSecret: "",
  },

  userIsAdmin: false,
  applicationData: {
    allVillageUsersApplications: [],
  },

  currentDocument: {
    submission_date: "",
    application_id: null,
    status: "Pending",
    applicant: {
      applicant_id: null,
      family_name: "",
      first_name: "",
      phone_number: "",
      email: "",
      address: {
        address_id: null,
        address_line_1: "",
        address_line_2: "",
        city: "",
        postal_code: "",
        state: {
          state_id: null,
          abbreviation: "",
          full_name: "",
        },
      },
    },

    pets: [],
    vehicles: [],
    transaction_info: {
      payment_credit: 0,
      payment_types: [],
    },
    documents: [
      {
        filepath: "",
        upload_completed: true,
        pet_id: 0,
        vehicle_id: 0,
        session_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        document_id: 0,
      },
    ],
    document_session_id: null,
    citizen_user_id: null,
    paymentAddress: {
      billingAddress: "",
      city: "",
      stat: "",
      zipCode: "",
    },
  },

  stickersConfig: [],
  petStickersConfig: [],
  vehicleStickersConfig: [],
  allApplicationStatus: ["Pending", "Approved", "Fulfilled", "Rejected"],
  regionsConfig: [],
  car_colors: [
    "White",
    "Blue",
    "Red",
    "Silver",
    "Green",
    "Orange",
    "Yellow",
    "Bronze",
    "Black",
    "Gray",
  ],

  userVariables: {
    usernameType: "email",
    application_id: "",
    isLoggedIn: false,
    username: "",
    email: "",
    usernameError: null,
    usernameIsEmail: true,
    phone_number: "",
    emailError: false,
    emailHelperText: "",
    phoneNumberError: false,
    phoneNumberHelperText: "",
    enteredOTP: "",
    cognitoResponse: {},
    loginTokens: {},
    profileVerified: true,
    profileMenu: {
      anchorEl: null,
    },
  },
  adminViewVariables: {
    isLoggedIn: false,
    username: "",
    currentPageIndex: 0,
  },
  leftSidebarVariables: {
    isOpen: false,
    username: "John",
    currentPage: "Home",
  },
  searchSectionVariables: {
    sticker_code_ids: [],
    sticker_code: [],
    application_status: [],
    fname: "",
    lname: "",
    tag_number: "",
    application_number: "",
    address: "",
    license_plate: "",
    min_fee: "",
    max_fee: "",
    dateRange: {
      from: null,
      to: null,
    },
    searchText: "",
    searchResults: [],
    limit: 50,
    offset: 0,
    totalCount: 0,
    page: 1,
  },
  applicationVariables: {
    isDataChanged: false,
    currentPageIndex: 0,
    currentPage: "Register",
    paymentIsOnline: true,
    applicationIsNew: true,
    enterCode: false,
    dataTableVariables: {
      status: "",
      showPreview: "false",
    },
  },
  citizenViewVariables: {
    currentPage: "User Profile",
    citizenApplications: [],
  },
};
