import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";

class CheckPayment extends React.Component {
  render() {
    const { transactionDetails, updateTransactionDetails } = this.props;
    return (
      <>
        <Box>
          <Box>
            <TextField
              size="small"
              id="standard"
              label="Check Number"
              variant="standard"
              //   value={}
              name="number"
              value={transactionDetails.number}
              onChange={(e) => updateTransactionDetails(e)}
              InputProps={{
                sx: {
                  color: "#FFFFFF",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#FFFFFF",
                  fontSize: 16,
                },
              }}
              sx={{ marginBottom: 1, width: "100%" }}
              //   onChange={(e) => }
            />
          </Box>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionDetails: state.TransactionVariables,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateTransactionDetails: (val) =>
      dispatch({ type: "UPDATE_TRANSACTION_DETAILS", value: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPayment);
