/* eslint-disable react/prop-types */
import Drawer from '@mui/material/Drawer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DetailsForm from './DetailsForm'

const drawerWidth = 500

class SideDrawer extends Component {
  render () {
    const { open } = this.props
    return (
      <>
        <Drawer
          open={open}
          variant="persistent"
          anchor="right"
          onClose={this.handleClick}
          sx={{
            width: '0%',
            background: '#162B42',
            whiteSpace: 'nowrap',
            ...(open && {
              width: drawerWidth,
              transition: (theme) =>
                theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen
                })
            }),
            ...(!open && {
              transition: (theme) =>
                theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                }),
              overflowX: 'hidden'
              // width: (theme) => `calc(${theme.spacing(9)} + 1px)`,
              // width: {
              //   xs: 'auto',
              //   sm: (theme) => theme.spacing(9) + 1,
              // },
            })
          }}
          PaperProps={{
            sx: {
              width: '0%',
              background: '#3E536A',
              whiteSpace: 'nowrap',
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
              ...(open && {
                width: drawerWidth,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                  })
              }),
              ...(!open && {
                // width: drawerWidth,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                  }),
                overflowX: 'hidden'
                // width: (theme) => `calc(${theme.spacing(9)} + 1px)`,
                // width: (theme) => theme.spacing(9) + 1,
                // width: {
                //   xs: 'auto',
                //   sm: (theme) => theme.spacing(9) + 1,
                // },
              })
            }
          }}
        >
          <DetailsForm />
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  open: state.sidebarOpen
})

const mapDispatchToProps = (dispatch) => {
  return {
    openSidebar: () => dispatch({ type: 'OPEN_SIDEBAR' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
