import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class C1_1_1_1_C_V_P_A extends Component {
  render() {
    const { rname, allInnerRoutes } = this.props;
    return (
      <div>
        <Outlet />
      </div>
    );
  }
}

export default connect(mapStateToProps)(C1_1_1_1_C_V_P_A);
