import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import StickersDoc from "./StickersDoc";
import CardComponentNew from "./CardComponentNew";
import DocumentCard from "./DocumentCard";
import PaymentCards from "./PaymentCards";
import { allEvents } from "../../../statesandlogics/cnstshelpers";
import { Link } from "react-router-dom";

class CompiledComponent extends Component {
  componentDidMount() {
    //this.props.searchApplications("GetAll");
  }

  render() {
    return (
      <Box
        sx={{
          borderRadius: "12px",
          padding: "24px",
        }}
      >
        <CardComponentNew />
        <StickersDoc />
        <DocumentCard />
        <PaymentCards />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentIsOnline: state.applicationVariables.paymentIsOnline,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentMode: (text) =>
      dispatch({ type: "SET_PAYMENT_MODE", value: text }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompiledComponent);
