import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import A0_0_0_0_A_L_P from "../components/admin/A0_0_0_0_A_L_P";
import A0_1_0_0_A_S_A_A from "../components/admin/A0_1_0_0_A_S_A_A";
import A0_1_1_0_A_V_S_A from "../components/admin/A0_1_1_0_A_V_S_A";
import A0_1_1_1_A_V_P_A from "../components/admin/A0_1_1_1_A_V_P_A";
import A0_1_1_2_A_T_R_A from "../components/admin/A0_1_1_2_A_T_R_A";
import A0_2_0_0_A_M_U from "../components/admin/A0_2_0_0_A_M_U";
import C0_0_0_0_C_F_P from "../components/citizen/C0_0_0_0_C_F_P";
import C1_0_0_0_C_L_P from "../components/citizen/C1_0_0_0_C_L_P";
import C1_1_0_0_C_S_A_A from "../components/citizen/C1_1_0_0_C_S_A_A";
import C1_1_1_0_C_V_S_A from "../components/citizen/C1_1_1_0_C_V_S_A";
import C1_1_1_1_C_V_P_A from "../components/citizen/C1_1_1_1_C_V_P_A";
import NoLinkPage from "../components/global/NoLinkPage";
import NoDefaultBasePage from "../components/global/NoDefaultBasePage";
export const NotificationTypes = {
  Error: "Error",
  Info: "Info",
  Success: "Success",
};
export const ApplicationOperations = {
  Create: "Create",
  Update: "Update",
  Submit: "Submit",
  Approve: "Approve",
  Reject: "Reject",
  IssueTag: "IssueTag",
};
export const helpers = {
  JPJS: (inp) => JSON.parse(JSON.stringify(inp)),
  getForwardNavigationLink: (rname) => <Link to={rname}>{rname}</Link>,
  UINotification: (msg, msg_type, delay = 1000) => {
    let m_val = {
      position: "top-right",
      autoClose: delay,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };
    switch (msg_type) {
      case NotificationTypes.Error:
        toast.error(msg, m_val);
        break;
      case NotificationTypes.Info:
        toast.info(msg, m_val);
        break;
      case NotificationTypes.Success:
        toast.success(msg, m_val);
        break;
      default:
        break;
    }
  },
  upperCaseNullHandler: (val) => ((val ? val : "").toUpperCase()),
  formatFee: (val) => (Number(val).toFixed(2))
};
export const allactions = {};
const allRegisteredComponents = [
  {
    key: "A0_0_0_0_A_L_P",
    rname: "villageStickerApplicationAdmin",
    cmp: (a, b, c) => <A0_0_0_0_A_L_P key={a} rname={b} allInnerRoutes={c} />,
    allInnerRoutes: [
      {
        key: "A0_1_0_0_A_S_A_A",
        rname: "Applications",
        cmp: (a, b, c) => (
          <A0_1_0_0_A_S_A_A key={a} rname={b} allInnerRoutes={c} />
        ),
        allInnerRoutes: [
          {
            key: "A0_1_1_0_A_V_S_A",
            rname: "ReviewingApplication",
            cmp: (a, b, c) => (
              <A0_1_1_0_A_V_S_A key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
          {
            key: "A0_1_1_1_A_V_P_A",
            rname: "NewApplication",
            cmp: (a, b, c) => (
              <A0_1_1_1_A_V_P_A key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
          {
            key: "A0_1_1_2_A_T_R_A",
            rname: "GenerateTransactionReport",
            cmp: (a, b, c) => (
              <A0_1_1_2_A_T_R_A key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
          {
            key: "NoLinkPage",
            rname: "*",
            cmp: (a, b, c) => (
              <NoLinkPage key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
        ],
      },
      {
        key: "NoLinkPage",
        rname: "*",
        cmp: (a, b, c) => <NoLinkPage key={a} rname={b} allInnerRoutes={c} />,
        allInnerRoutes: [],
      },
    ],
  },
  {
    key: "C0_0_0_0_C_F_P",
    rname: "villageStickerApplicationIntro",
    cmp: (a, b, c) => <C0_0_0_0_C_F_P key={a} rname={b} allInnerRoutes={c} />,
    allInnerRoutes: [
      {
        key: "NoLinkPage",
        rname: "*",
        cmp: (a, b, c) => <NoLinkPage key={a} rname={b} allInnerRoutes={c} />,
        allInnerRoutes: [],
      },
    ],
  },
  {
    key: "C1_0_0_0_C_L_P",
    rname: "villageStickerApplication",
    cmp: (a, b, c) => <C1_0_0_0_C_L_P key={a} rname={b} allInnerRoutes={c} />,
    allInnerRoutes: [
      {
        key: "C1_1_0_0_C_S_A_A",
        rname: "Stickers",
        cmp: (a, b, c) => (
          <C1_1_0_0_C_S_A_A key={a} rname={b} allInnerRoutes={c} />
        ),
        allInnerRoutes: [
          {
            key: "C1_1_1_0_C_V_S_A",
            rname: "CurrentApplication",
            cmp: (a, b, c) => (
              <C1_1_1_0_C_V_S_A key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
          {
            key: "NoLinkPage",
            rname: "*",
            cmp: (a, b, c) => (
              <NoLinkPage key={a} rname={b} allInnerRoutes={c} />
            ),
            allInnerRoutes: [],
          },
        ],
      },
      {
        key: "NoLinkPage",
        rname: "*",
        cmp: (a, b, c) => <NoLinkPage key={a} rname={b} allInnerRoutes={c} />,
        allInnerRoutes: [],
      },
    ],
  },
  {
    key: "NoDefaultBasePage",
    rname: "*",
    cmp: (a, b, c) => (
      <NoDefaultBasePage key={a} rname={b} allInnerRoutes={c} />
    ),
    allInnerRoutes: [],
  },
];
export const allInnerRoutes = () => allRegisteredComponents;
export const allEvents = {
  ADMIN_LOGIN_CLICK: "ADMIN_LOGIN_CLICK",
  SHOW_APPLICATION_CLICK: "SHOW_APPLICATION_CLICK",
  SHOW_CITIZEN_STICKERS: "SHOW_CITIZEN_STICKERS",
  SHOW_CITIZEN_APPLICATION: "SHOW_CITIZEN_APPLICATION",
  SET_AFB_SEL_IDX: "SET_AFB_SEL_IDX",
  SET_CURRENT_DOCUMENT_STATUS_TO_NEW:"SET_CURRENT_DOCUMENT_STATUS_TO_NEW",
  user: {
    SET_USER: "SET_USER",
    UPDATE_USER_FNAME: "UPDATE_USER_FNAME",
    UPDATE_USER_MIDNAME: "UPDATE_USER_MIDNAME",
    UPDATE_USER_LNAME: "UPDATE_USER_LNAME",
    UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",
    UPDATE_USER_PHONE: "UPDATE_USER_PHONE",
    UPDATE_USER_BDAY: "UPDATE_USER_BDAY",
    UPDATE_USER_ADDRESS: "UPDATE_USER_ADDRESS",
  },
  citizen: {
    LOAD_CITIZEN_PAGE: "LOAD_CITIZEN_PAGE",
    LOAD_CITIZEN_APPLICATION_LIST: "LOAD_CITIZEN_APPLICATION_LIST",
    LOAD_CITIZEN_APPLICATION_CREATE: "LOAD_CITIZEN_APPLICATION_CREATE",
    LOAD_TEMP_CITIZEN_APPLICATION: "LOAD_TEMP_CITIZEN_APPLICATION",
    LOAD_APPLICATION_LOGIN: "LOAD_APPLICATION_LOGIN",
    UPDATE_USERNAME: "UPDATE_USERNAME",
    FORWARD_LOGIN: "FORWARD_LOGIN",
  },
  application: {
    SET_APPLICATION_TYPE: "SET_APPLICATION_TYPE",
    LOAD_APPLICATION_PAGE: "LOAD_APPLICATION_PAGE",
    SET_PAYMENT_MODE: "SET_PAYMENT_MODE",
    UPDATE_APPLICATION_ID: "UPDATE_APPLICATION_ID",
    UPDATE_APPLICATION_STATUS: "UPDATE_APPLICATION_STATUS",
    vehicle: {
      UPDATE_VEHICLE_TAG_NUMBER: "UPDATE_VEHICLE_TAG_NUMBER",
      UPDATE_VEHICLE_FEE_CATEGORY: "UPDATE_VEHICLE_FEE_CATEGORY",
      UPDATE_NEW_VEHICLE_DATA: "UPDATE_NEW_VEHICLE_DATA",
      REMOVE_VEHICLE_STICKER: "REMOVE_VEHICLE_STICKER",
      SET_VEHICLE_DATA: "SET_VEHICLE_DATA",
    },
    pet: {
      UPDATE_PET_FEES: "UPDATE_PET_FEES",
      REMOVE_PET_STICKER: "REMOVE_PET_STICKER",
      UPDATE_PET_FEE_CATEGORY: "UPDATE_PET_FEE_CATEGORY",
      UPDATE_PET_DATA: "UPDATE_PET_DATA",
      UPDATE_PET_TAG_NUMBER: "UPDATE_PET_TAG_NUMBER",
      SET_PET_DATA: "SET_PET_DATA",
    },
    status: {
      UPDATE_APPLICATION_STATUS: "UPDATE_APPLICATION_STATUS",
      UPDATE_DOCUMENT_STATUS: "UPDATE_DOCUMENT_STATUS",
      UPDATE_PAYMENT_STATUS: "UPDATE_PAYMENT_STATUS",
    },
  },
  admin: {
    UPDATE_ADMIN_USERNAME: "UPDATE_ADMIN_USERNAME",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    LOAD_ADMIN_HOME: "LOAD_ADMIN_HOME",
    SET_OFFICER_NAME: "SET_OFFICER_NAME",
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  },
  leftSidebar: {
    SET_EXPAND: "SET_EXPAND",
  },
  adminHomeSearch: {
    UPDATE_APPLICATION_NUMBER: "UPDATE_APPLICATION_NUMBER",
    UPDATE_TAG_NUMBER: "UPDATE_TAG_NUMBER",
    UPDATE_DATE_FROM: "UPDATE_DATE_FROM",
    UPDATE_DATE_TO: "UPDATE_DATE_TO",
    UPDATE_SEARCH_TEXT: "UPDATE_SEARCH_TEXT",
    UPDATE_SEARCH_OFFSET: "UPDATE_SEARCH_OFFSET",
    UPDATE_SEARCH_RESULT_LIMIT: "UPDATE_SEARCH_RESULT_LIMIT"
  },
};
