/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable multiline-ternary */
import PetsIcon from "@mui/icons-material/Pets";
import { Box, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Component } from "react";
import "react-credit-cards-2/es/styles-compiled.css";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import vehicle from "../../images/car.svg";
import Payments from "./Payments";
import AdminCardOnline from "./AdminCardOnline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import Cheque from "./Cheque";
import { helpers } from "../../statesandlogics/cnstshelpers";
import ErrorDrawer from "./ErrorDrawer";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { petLateFee, vehicleLateFee } from "./AdminApplicationPreview";

class DetailsForm extends Component {
  render() {
    const {
      current_period,
      applyVehicleLateFee,
      applyPetLateFee,
      applyVehicleProratedFee,
      applyPetProratedFee,
      updatePetData,
      stickersConfig,
      type,
      updateTransactionDetails,
      vehicles,
      pets,
      editDoc,
      TransactionVariables,
      updateVehicleData,
      currentlyEditingVehicle,
      currentlyEditingPet,
      setCurrentlyEditingVehicleData,
      setCurrentlyEditingPetData,
      currentDocument,
      currentlyEditingPetValidations,
      currentlyEditingVehicleValidations,
      isAddingOrEditingPetRecordValid,
      isAddingOrEditingVehicleRecordValid,
      vehicleStickersConfig,
      petStickersConfig,
    } = this.props;
    stickersConfig.sort((a, b) =>
      a.code_description.localeCompare(b.code_description)
    );

    console.log(currentlyEditingPet);

    let vehicleFee = 0;
    let petFee = 0;
    vehicles.map((v) => {
      if (v.late_fee_applied && v.prorated_fee_applied) {
        vehicleFee += (v.sticker_code.fee + 20) / 2;
      } else if (v.late_fee_applied) {
        vehicleFee += v.sticker_code.fee + 20;
      } else if (v.prorated_fee_applied) {
        vehicleFee += v.sticker_code.fee / 2;
      } else {
        vehicleFee += v.sticker_code.fee;
      }
    });

    pets.map((p) => {
      if (p.late_fee_applied && p.prorated_fee_applied) {
        petFee += (p.sticker_code.fee + 5) / 2;
      } else if (p.late_fee_applied) {
        petFee += p.sticker_code.fee + 5;
      } else if (p.prorated_fee_applied) {
        petFee += p.sticker_code.fee / 2;
      } else {
        petFee += p.sticker_code.fee;
      }
    });

    let totalFees = vehicleFee + petFee;

    return (
      <>
        <IconButton
          sx={{
            position: "absolute",
            "@media (max-width: 400px)": {
              right: 5,
            },
            top: 2,
            right: 10,
          }}
          onClick={() => {
            this.props.closeSidebar(false);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
        {type === "vehicle" ? (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: "poppins", color: "#FFF" }}
              >
                Vehicle Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <img src={vehicle} alt="vehicle" style={{ width: 150 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Grid
                container
                spacing={3}
                sx={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  paddingLeft: 5,
                  paddingRight: 3,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    id="outlined"
                    label="License Plate"
                    variant="standard"
                    value={currentlyEditingVehicle.plate_number}
                    error={
                      currentlyEditingVehicleValidations.plate_number.error
                    }
                    helperText={
                      currentlyEditingVehicleValidations.plate_number.errorMsg
                    }
                    required
                    fullWidth
                    name="plate_number"
                    InputProps={{
                      sx: {
                        color: "#FFFFFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingVehicleValidations.plate_number
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .plate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .plate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingVehicleValidations.plate_number
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingVehicleValidations.plate_number
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => {
                      setCurrentlyEditingVehicleData(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label={'MM/YYYY'}
                      views={["year"]}
                      size="small"
                      required
                      value={currentlyEditingVehicle.model_year}
                      error={
                        currentlyEditingVehicleValidations.model_year.error
                      }
                      helperText={
                        currentlyEditingVehicleValidations.model_year.errorMsg
                      }
                      id="standard"
                      label="Model Year"
                      inputFormat="YYYY"
                      maxDate={new Date()}
                      minDate={"1800-01-01"}
                      name="model_year"
                      variant="outlined"
                      onChange={(val) =>
                        setCurrentlyEditingVehicleData(val, "modelYear")
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{
                            svg: {
                              color: currentlyEditingVehicleValidations
                                .model_year.error
                                ? "#FFA07A"
                                : "#FFF",
                            },
                            label: {
                              color: currentlyEditingVehicleValidations
                                .model_year.error
                                ? "#FFA07A"
                                : "#FFF",
                            },
                            input: {
                              borderColor: currentlyEditingVehicleValidations
                                .model_year.error
                                ? "#FFA07A"
                                : "#FFF",
                            },
                            "& .MuiInputBase-root": {
                              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                borderColor: currentlyEditingVehicleValidations
                                  .model_year.error
                                  ? "#FFA07A"
                                  : "#FFF",
                              },
                            },
                            "& .Mui-required .MuiFormLabel-asterisk": {
                              color: currentlyEditingVehicleValidations
                                .model_year.error
                                ? "#FFA07A"
                                : "#FFFFFF",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: currentlyEditingVehicleValidations
                                .model_year.error
                                ? "#FFA07A"
                                : "#FFF",
                              fontSize: 16,
                            },
                          }}
                          required
                          error={
                            currentlyEditingVehicleValidations.model_year.error
                          }
                          helperText={
                            currentlyEditingVehicleValidations.model_year
                              .errorMsg
                          }
                          FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                        />
                      )}
                      InputProps={{
                        sx: { color: "white" },
                      }}
                      FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="outlined"
                    label="Make"
                    value={currentlyEditingVehicle.make}
                    error={currentlyEditingVehicleValidations.make.error}
                    helperText={
                      currentlyEditingVehicleValidations.make.errorMsg
                    }
                    variant="standard"
                    name="make"
                    required
                    fullWidth
                    InputProps={{
                      inputProps: {
                        maxLength: 40,
                      },
                      sx: {
                        color: "#FFFFFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingVehicleValidations.make.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .make.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .make.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingVehicleValidations.make.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingVehicleValidations.make.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => setCurrentlyEditingVehicleData(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    id="outlined"
                    label="Model"
                    required
                    value={currentlyEditingVehicle.model}
                    error={currentlyEditingVehicleValidations.model.error}
                    helperText={
                      currentlyEditingVehicleValidations.model.errorMsg
                    }
                    name="model"
                    variant="standard"
                    InputProps={{
                      sx: {
                        color: "#FFF",
                      },
                      inputProps: {
                        maxLength: 25,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingVehicleValidations.model.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .model.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .model.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingVehicleValidations.model.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingVehicleValidations.model.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => setCurrentlyEditingVehicleData(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={
                      helpers.upperCaseNullHandler(
                        currentlyEditingVehicle.color
                      ) || null
                    }
                    inputValue={helpers.upperCaseNullHandler(
                      currentlyEditingVehicle.color
                    )}
                    name="color"
                    freeSolo
                    id="controllable-states-demo"
                    options={this.props.carColors}
                    renderInput={(params) => (
                      <TextField {...params} label="Color" />
                    )}
                    onChange={(e, newValue) => {
                      setCurrentlyEditingVehicleData(newValue, "color");
                    }}
                    onInputChange={(e, newInputValue) => {
                      setCurrentlyEditingVehicleData(newInputValue, "color");
                    }}
                    required
                    helperText={
                      currentlyEditingVehicleValidations.color.errorMsg
                    }
                    error={currentlyEditingVehicleValidations.color.error}
                    inputProps={{
                      sx: {
                        color: "#FFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      color: "#FFF",
                      "& .MuiInputBase-input": {
                        color: "#FFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingVehicleValidations.color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingVehicleValidations.color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                  />
                  {currentlyEditingVehicleValidations.color.error && (
                    <FormHelperText
                      style={{
                        color: "#FFA07A",
                      }}
                    >
                      {currentlyEditingVehicleValidations.color.errorMsg}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={
                      currentlyEditingVehicle.sticker_code.code_description
                    }
                    inputValue={
                      currentlyEditingVehicle.sticker_code.code_description
                    }
                    name="feeCategory"
                    disabled={
                      currentDocument.status === "ToBeCreatedApplication"
                        ? false
                        : totalFees === 0 &&
                          currentDocument.status === "Pending"
                        ? false
                        : totalFees ===
                          currentDocument.transaction_info.payment_credit
                    }
                    // getOptionLabel={(option) => option.code_description}
                    id="controllable-states-demo"
                    options={vehicleStickersConfig.map(
                      (option) => option.code_description
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Fee Category" />
                    )}
                    onChange={(e, newValue) => {
                      setCurrentlyEditingVehicleData(newValue, "feeCategory");
                    }}
                    onInputChange={(e, newInputValue) => {
                      setCurrentlyEditingVehicleData(
                        newInputValue,
                        "feeCategory"
                      );
                    }}
                    required
                    helperText={
                      currentlyEditingVehicleValidations
                        .sticker_code_description.error
                    }
                    error={
                      currentlyEditingVehicleValidations
                        .sticker_code_description.error
                    }
                    inputProps={{
                      sx: {
                        color: "#FFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      color: "#FFFFFF",
                      "& .MuiInputBase-input": {
                        color: "#FFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingVehicleValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingVehicleValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingVehicleValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                  />
                  {currentlyEditingVehicleValidations.sticker_code_description
                    .error && (
                    <FormHelperText
                      style={{
                        color: "#FFA07A",
                      }}
                    >
                      {
                        currentlyEditingVehicleValidations
                          .sticker_code_description.errorMsg
                      }
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 12,
                    }}
                  >
                    Estimated Fee (in $)
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                    }}
                  >
                    {"$ " +
                      helpers.formatFee(
                        currentlyEditingVehicle.late_fee_applied &&
                          currentlyEditingVehicle.prorated_fee_applied
                          ? (currentlyEditingVehicle.sticker_code.fee +
                              vehicleLateFee) /
                              2
                          : currentlyEditingVehicle.late_fee_applied
                          ? currentlyEditingVehicle.sticker_code.fee +
                            vehicleLateFee
                          : currentlyEditingVehicle.prorated_fee_applied
                          ? currentlyEditingVehicle.sticker_code.fee / 2
                          : currentlyEditingVehicle.sticker_code.fee
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    label="Apply Late Fee"
                    control={
                      <Checkbox
                        checked={currentlyEditingVehicle.late_fee_applied}
                        disabled={
                          currentDocument.status === "ToBeCreatedApplication"
                            ? false
                            : currentlyEditingVehicle.sticker_code.fee === 0 &&
                              !currentlyEditingVehicle.late_fee_applied
                            ? true
                            : currentDocument.status === "Pending"
                            ? false
                            : currentDocument.transaction_info.payment_credit >=
                              totalFees
                        }
                        onChange={(e) => {
                          if (
                            current_period.late_period.active &&
                            e.target.checked === false
                          ) {
                            alert("Are you sure you want to remove Late Fee ?");
                            applyVehicleLateFee(e.target.checked);
                          } else if (
                            current_period.late_period.active === false &&
                            e.target.checked === true
                          ) {
                            alert("Are you sure you want to add Late Fee ?");
                            applyVehicleLateFee(e.target.checked);
                          } else {
                            applyVehicleLateFee(e.target.checked);
                          }
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    label="Apply Prorated Fee"
                    control={
                      <Checkbox
                        checked={currentlyEditingVehicle.prorated_fee_applied}
                        disabled={
                          currentDocument.status === "ToBeCreatedApplication"
                            ? false
                            : currentlyEditingVehicle.sticker_code.fee === 0 &&
                              !currentlyEditingVehicle.prorated_fee_applied
                            ? true
                            : currentDocument.status === "Pending"
                            ? false
                            : currentDocument.transaction_info.payment_credit >=
                              totalFees
                        }
                        onChange={(e) => {
                          applyVehicleProratedFee(e.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            {currentlyEditingVehicle.prorated_fee_applied === true ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "white" }}>
                  * Additional Prorated Fee will be applied
                </Typography>
              </Box>
            ) : currentlyEditingVehicle.late_fee_applied === true ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Typography sx={{ color: "white" }}>
                  * Additional Late Fee will be applied
                </Typography>
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  alignSelf: "flex-end",
                  textTransform: "none",
                  fontFamily: "poppins",
                  wordSpacing: 0,
                  backgroundColor: "#ddb00a",
                  color: "#162b42",
                  display: "flex",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  updateVehicleData();
                  // if (isAddingOrEditingVehicleRecordValid === true) {
                  //   this.props.closeSidebar(type, false);
                  // }
                  // else {
                  //   this.props.closeSidebar(type, true);
                  // }
                }}
              >
                Save Details
              </Button>
            </Box>
          </div>
        ) : type === "pet" ? (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: "poppins", color: "#fff" }}
              >
                Pet Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <PetsIcon sx={{ color: "#FFF", fontSize: 100 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Grid
                container
                spacing={3}
                sx={{
                  fontFamily: "poppins",
                  color: "#FFFFFF",
                  paddingLeft: 5,
                  paddingRight: 3,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    // autoFocus
                    id="outlined"
                    label="Name"
                    variant="standard"
                    required
                    error={currentlyEditingPetValidations.name.error}
                    helperText={currentlyEditingPetValidations.name.errorMsg}
                    fullWidth
                    value={currentlyEditingPet.name}
                    name="name"
                    InputProps={{
                      sx: {
                        color: "#FFFFFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingPetValidations.name.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations.name
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations.name
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations.name.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations.name.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => {
                      setCurrentlyEditingPetData(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    required
                    error={currentlyEditingPetValidations.sex.error}
                    sx={{
                      color: "#FFFFFF",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations.sex
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations.sex
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations.sex.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations.sex.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ color: "#FFFFFF" }}
                    >
                      Sex
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sex"
                      name="sex"
                      value={currentlyEditingPet.sex}
                      error={currentlyEditingPetValidations.sex.error}
                      helperText={currentlyEditingPetValidations.sex.errorMsg}
                      onChange={(e) => {
                        setCurrentlyEditingPetData(e);
                      }}
                      inputProps={{
                        sx: {
                          color: "#FFFFFF",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#FFFFFF",
                          fontSize: 16,
                        },
                      }}
                      input={
                        <Input
                          required
                          sx={{ color: "#FFFFFF" }}
                          name="sex"
                          onChange={(e) => {
                            setCurrentlyEditingPetData(e);
                          }}
                          helperText={
                            currentlyEditingPetValidations.sex.errorMsg
                          }
                        />
                      }
                    >
                      <MenuItem value="Male">MALE</MenuItem>
                      <MenuItem value="Female">FEMALE</MenuItem>
                    </Select>
                    {currentlyEditingPetValidations.sex.error && (
                      <FormHelperText
                        style={{
                          color: "#FFA07A",
                        }}
                      >
                        {currentlyEditingPetValidations.sex.errorMsg}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    id="outlined"
                    label="Breed"
                    variant="standard"
                    name="breed"
                    value={currentlyEditingPet.breed}
                    error={currentlyEditingPetValidations.breed.error}
                    helperText={currentlyEditingPetValidations.breed.errorMsg}
                    required
                    fullWidth
                    InputProps={{
                      sx: {
                        color: "#FFFFFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingPetValidations.breed.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations.breed
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations.breed
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations.breed.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations.breed.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => setCurrentlyEditingPetData(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={
                      helpers.upperCaseNullHandler(currentlyEditingPet.color) ||
                      null
                    }
                    inputValue={helpers.upperCaseNullHandler(
                      currentlyEditingPet.color
                    )}
                    name="color"
                    freeSolo
                    id="controllable-states-demo"
                    options={this.props.carColors}
                    renderInput={(params) => (
                      <TextField {...params} label="Color" />
                    )}
                    onChange={(e, newValue) => {
                      setCurrentlyEditingPetData(newValue, "color");
                    }}
                    onInputChange={(e, newInputValue) => {
                      setCurrentlyEditingPetData(newInputValue, "color");
                    }}
                    required
                    helperText={currentlyEditingPetValidations.color.errorMsg}
                    error={currentlyEditingPetValidations.color.error}
                    inputProps={{
                      sx: {
                        color: "#FFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      color: "#FFF",
                      "& .MuiInputBase-input": {
                        color: "#FFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations.color
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations.color
                          .error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations.color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations.color.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                  />
                  {currentlyEditingPetValidations.color.error && (
                    <FormHelperText
                      style={{
                        color: "#FFA07A",
                      }}
                    >
                      {currentlyEditingPetValidations.color.errorMsg}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="outlined"
                    label="Rabies Certificate No"
                    value={currentlyEditingPet.rabies_certificate_number}
                    error={
                      currentlyEditingPetValidations.rabies_certificate_number
                        .error
                    }
                    helperText={
                      currentlyEditingPetValidations.rabies_certificate_number
                        .errorMsg
                    }
                    required
                    name="rabies_certificate_number"
                    variant="standard"
                    InputProps={{
                      sx: {
                        color: "#FFFFFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: currentlyEditingPetValidations
                          .rabies_certificate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations
                          .rabies_certificate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations
                          .rabies_certificate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations
                          .rabies_certificate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations
                          .rabies_certificate_number.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                    FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                    onChange={(e) => setCurrentlyEditingPetData(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      required
                      value={currentlyEditingPet.rabies_certificate_expiry}
                      error={
                        currentlyEditingPetValidations.rabies_certificate_expiry
                          .error
                      }
                      helperText={
                        currentlyEditingPetValidations.rabies_certificate_expiry
                          .errorMsg
                      }
                      id="standard"
                      label="Rabies Certificate Expiry"
                      inputFormat="MM/DD/YYYY"
                      variant="outlined"
                      onChange={(val) =>
                        setCurrentlyEditingPetData(val, "rabiesExpiry")
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{
                            color: "#FFF",
                            svg: {
                              color: currentlyEditingPetValidations
                                .rabies_certificate_expiry.error
                                ? "#FFA07A"
                                : "#FFF",
                            },
                            label: {
                              color: currentlyEditingPetValidations
                                .rabies_certificate_expiry.error
                                ? "#FFA07A"
                                : "#FFF",
                            },
                            input: {
                              borderColor: currentlyEditingPetValidations
                                .rabies_certificate_expiry.error
                                ? "#FFA07A"
                                : "#FFF",
                            },

                            "& .MuiInputBase-root": {
                              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                borderColor: currentlyEditingPetValidations
                                  .rabies_certificate_expiry.error
                                  ? "#FFA07A"
                                  : "#FFF",
                              },
                            },
                            "& .Mui-required .MuiFormLabel-asterisk": {
                              color: currentlyEditingPetValidations
                                .rabies_certificate_expiry.error
                                ? "#FFA07A"
                                : "#FFFFFF",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: currentlyEditingPetValidations
                                .rabies_certificate_expiry.error
                                ? "#FFA07A"
                                : "#FFF",
                              fontSize: 16,
                            },
                          }}
                          error={
                            currentlyEditingPetValidations
                              .rabies_certificate_expiry.error
                          }
                          helperText={
                            currentlyEditingPetValidations
                              .rabies_certificate_expiry.errorMsg
                          }
                          required
                          FormHelperTextProps={{ style: { color: "#FFA07A" } }}
                        />
                      )}
                      InputProps={{
                        sx: { color: "white" },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={currentlyEditingPet.sticker_code.code_description}
                    inputValue={
                      currentlyEditingPet.sticker_code.code_description
                    }
                    name="feeCategory"
                    disabled={
                      currentDocument.status === "ToBeCreatedApplication"
                        ? false
                        : totalFees === 0 &&
                          currentDocument.status === "Pending"
                        ? false
                        : currentDocument.transaction_info.payment_credit >=
                          totalFees
                    }
                    // getOptionLabel={(option) => option.code_description}
                    id="controllable-states-demo"
                    options={petStickersConfig.map(
                      (option) => option.code_description
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Fee Category" />
                    )}
                    onChange={(e, newValue) => {
                      setCurrentlyEditingPetData(newValue, "feeCategory");
                    }}
                    onInputChange={(e, newInputValue) => {
                      setCurrentlyEditingPetData(newInputValue, "feeCategory");
                    }}
                    required
                    helperText={
                      currentlyEditingPetValidations.sticker_code_description
                        .error
                    }
                    error={
                      currentlyEditingPetValidations.sticker_code_description
                        .error
                    }
                    inputProps={{
                      sx: {
                        color: "#FFF",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#FFFFFF",
                        fontSize: 16,
                      },
                    }}
                    sx={{
                      color: "#FFFFFF",
                      "& .MuiInputBase-input": {
                        color: "#FFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: currentlyEditingPetValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: currentlyEditingPetValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: currentlyEditingPetValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                      "& .Mui-required .MuiFormLabel-asterisk": {
                        color: currentlyEditingPetValidations
                          .sticker_code_description.error
                          ? "#FFA07A"
                          : "#FFFFFF",
                      },
                    }}
                  />
                  {currentlyEditingPetValidations.sticker_code_description
                    .error && (
                    <FormHelperText
                      style={{
                        color: "#FFA07A",
                      }}
                    >
                      {
                        currentlyEditingPetValidations.sticker_code_description
                          .errorMsg
                      }
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 12,
                    }}
                  >
                    Estimated Fee (in $)
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                    }}
                  >
                    {"$ " +
                      helpers.formatFee(
                        currentlyEditingPet.late_fee_applied &&
                          currentlyEditingPet.prorated_fee_applied
                          ? (currentlyEditingPet.sticker_code.fee +
                              petLateFee) /
                              2
                          : currentlyEditingPet.late_fee_applied
                          ? currentlyEditingPet.sticker_code.fee + petLateFee
                          : currentlyEditingPet.prorated_fee_applied
                          ? currentlyEditingPet.sticker_code.fee / 2
                          : currentlyEditingPet.sticker_code.fee
                      )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    label="Apply Late Fee"
                    control={
                      <Checkbox
                        checked={currentlyEditingPet.late_fee_applied}
                        disabled={
                          currentDocument.status === "ToBeCreatedApplication"
                            ? false
                            : currentlyEditingPet.sticker_code.fee === 0 && !currentlyEditingPet.late_fee_applied
                            ? true
                            : currentDocument.status === "Pending"
                            ? false
                            : currentDocument.transaction_info.payment_credit >=
                              totalFees
                        }
                        onChange={(e) => {
                          if (
                            current_period.late_period.active &&
                            e.target.checked === false
                          ) {
                            alert("Are you sure you want to remove Late Fee ?");
                            applyPetLateFee(e.target.checked);
                          } else if (
                            current_period.late_period.active === false &&
                            e.target.checked === true
                          ) {
                            alert("Are you sure you want to add Late Fee ?");
                            applyPetLateFee(e.target.checked);
                          } else {
                            applyPetLateFee(e.target.checked);
                          }
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    label="Apply Prorated Fee"
                    control={
                      <Checkbox
                        checked={currentlyEditingPet.prorated_fee_applied}
                        disabled={
                          currentDocument.status === "ToBeCreatedApplication"
                            ? false
                            : currentlyEditingPet.sticker_code.fee === 0 && !currentlyEditingPet.prorated_fee_applied
                            ? true
                            : currentDocument.status === "Pending"
                            ? false
                            : currentDocument.transaction_info.payment_credit >=
                              totalFees
                        }
                        onChange={(e) => {
                          applyPetProratedFee(e.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            {currentlyEditingPet.prorated_fee_applied === true ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "white" }}>
                  * Additional Prorated Fee will be applied
                </Typography>
              </Box>
            ) : currentlyEditingPet.late_fee_applied === true ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "white" }}>
                  * Additional Late Fee will be applied
                </Typography>
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  alignSelf: "flex-end",
                  textTransform: "none",
                  fontFamily: "poppins",
                  wordSpacing: 0,
                  backgroundColor: "#ddb00a",
                  color: "#162b42",
                  display: "flex",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  updatePetData();
                  // if (isAddingOrEditingPetRecordValid === true) {
                  //   this.props.closeSidebar(type, false);
                  // } else {
                  //   this.props.closeSidebar(type, true);
                  // }
                }}
              >
                Save Details
              </Button>
            </Box>
          </div>
        ) : type === "cardPayment" ? (
          <div>
            {editDoc.isCitizenViewOnly === false ? (
              <>
                <Box
                  sx={{
                    marginTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "poppins", color: "#FFF" }}
                  >
                    Collect Payment Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "60%" }}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ width: "100%" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ color: "#FFFFFF" }}
                      >
                        Transaction Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.props.transactionDetails.type}
                        label="Transaction Type"
                        name="type"
                        onChange={(e) => updateTransactionDetails(e)}
                        sx={{ color: "#FFFFFF" }}
                        inputProps={{
                          sx: {
                            color: "#FFFFFF",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#FFFFFF",
                            fontSize: 16,
                          },
                        }}
                      >
                        <MenuItem value="cash">Cash In Person</MenuItem>
                        <MenuItem value="check">Check</MenuItem>
                        <MenuItem value="card_in_person">
                          Card In Person
                        </MenuItem>
                        <MenuItem value="card_online">Card Online</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    {this.props.transactionDetails.type === "card_online" ? (
                      <AdminCardOnline />
                    ) : this.props.transactionDetails.type === "check" ? (
                      <Cheque />
                    ) : null}

                    {this.props.transactionDetails.type === "card_online" ||
                    this.props.transactionDetails.type === "check" ? null : (
                      <TextField
                        size="small"
                        id="standard"
                        label="Transaction Number"
                        variant="standard"
                        value={this.props.transactionDetails.number}
                        name="number"
                        InputProps={{
                          sx: {
                            color: "#FFFFFF",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#FFFFFF",
                            fontSize: 16,
                          }, // sets the label color to blue
                        }}
                        sx={{ marginBottom: 1, width: "100%" }}
                        onChange={(e) => updateTransactionDetails(e)}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      {this.props.transactionDetails.type ===
                      "card_online" ? null : (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.recordPayment(
                              currentDocument,
                              currentDocument.application_id,
                              TransactionVariables.number,
                              TransactionVariables.type,
                              totalFees
                            );
                          }}
                        >
                          <Card
                            sx={{
                              width: 100,
                              height: 22,
                              minHeight: 20,
                              backgroundColor: "#ddb00a",
                              color: "#000",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 1,
                            }}
                          >
                            <CardContent sx={{ padding: 0 }}>
                              <Typography
                                gutterBottom
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  padding: 0,
                                  margin: 0,
                                  color: "#000",
                                }}
                              >
                                {this.props.transactionDetails.type ===
                                ("check" || "cash")
                                  ? "Save"
                                  : "Approve"}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Payments />
            )}
          </div>
        ) : type === "error" ? (
          <ErrorDrawer />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_period: state.appGlobals.current_period,
  transactionDetails: state.TransactionVariables,
  applicationIsNew: state.userIsAdmin,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
  sidebarOpen: state.sidebarOpen,
  type: state.type,
  vehicleIndex: state.vehicleIndex,
  petIndex: state.petIndex,
  cvc: state.PaymentPageVariables.cvc,
  expiry: state.PaymentPageVariables.expiry,
  focus: state.PaymentPageVariables.focus,
  fname: state.PaymentPageVariables.fname,
  lname: state.PaymentPageVariables.lname,
  number: state.PaymentPageVariables.number,
  username: state.userVariables.username,
  currentDocument: state.currentDocument,
  dateTo: state.searchSectionVariables.dateRange.to,
  stickersConfig: state.stickersConfig,
  petStickersConfig: state.petStickersConfig,
  vehicleStickersConfig: state.vehicleStickersConfig,
  editDoc: state.editDoc,
  TransactionVariables: state.TransactionVariables,
  currentlyEditingVehicle: state.currentlyEditingVehicle,
  currentlyEditingPet: state.currentlyEditingPet,
  carColors: state.car_colors,
  currentlyEditingPetValidations: state.currentlyEditingPetValidations,
  isAddingOrEditingPetRecordValid:
    state.appGlobals.isAddingOrEditingPetRecordValid,
  currentlyEditingVehicleValidations: state.currentlyEditingVehicleValidations,
  isAddingOrEditingVehicleRecordValid:
    state.appGlobals.isAddingOrEditingVehicleRecordValid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    applyVehicleLateFee: (val1) =>
      dispatch({
        type: "APPLY_VEHICLE_LATE_FEE",
        value: val1,
      }),
    applyPetLateFee: (val1) =>
      dispatch({
        type: "APPLY_PET_LATE_FEE",
        value: val1,
      }),
    applyVehicleProratedFee: (val1) =>
      dispatch({
        type: "APPLY_VEHICLE_PRORATED_FEE",
        value: val1,
      }),
    applyPetProratedFee: (val1) =>
      dispatch({
        type: "APPLY_PET_PRORATED_FEE",
        value: val1,
      }),
    setCurrentlyEditingPetData: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET_DATA",
        value: val,
        value2: val2,
      }),
    setCurrentlyEditingVehicleData: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE_DATA",
        value: val,
        value2: val2,
      }),
    recordPayment: (
      currentDocument,
      applicationId,
      transactionNumber,
      transactionType,
      fees
    ) =>
      api.recordPayment(
        currentDocument,
        applicationId,
        transactionNumber,
        transactionType,
        fees,
        dispatch
      ),
    updateTransactionDetails: (val) =>
      dispatch({ type: "UPDATE_TRANSACTION_DETAILS", value: val }),
    setBillingAddress: (val) =>
      dispatch({ type: "SET_BILLING_ADDRESS", value: val }),
    setCVC: (val) => dispatch({ type: "SET_CVC", value: val }),
    setExpiry: (val) => dispatch({ type: "SET_EXPIRY", value: val }),
    setCardNo: (val) => dispatch({ type: "SET_CARDNO", value: val }),
    setLastName: (val) => dispatch({ type: "SET_LASTNAME", value: val }),
    setFirstName: (val) => dispatch({ type: "SET_FIRSTNAME", value: val }),
    setCardDetails: (val) =>
      dispatch({ type: "SET_CARD_DETAILS", details: val }),
    handleInputFocus: (val) => dispatch({ type: "HANDLE_FOCUS", value: val }),
    updateVehicleData: () => dispatch({ type: "UPDATE_NEW_VEHICLE_DATA" }),
    updatePetData: (val) => dispatch({ type: "UPDATE_PET_DATA", value: val }),
    closeSidebar: (val, isOpen) =>
      dispatch({ type: "OPEN_SIDEBAR", value: isOpen, click: val }),
    setPetData: (val, name, petIndex) =>
      dispatch({
        type: "SET_PET_DATA",
        value: val,
        value2: name,
        value3: petIndex,
      }),
    setVehicleData: (val, vehicleIndex) =>
      dispatch({ type: "SET_VEHICLE_DATA", value: val, value2: vehicleIndex }),
    postConfirmation: (val) => api.postConfirmation(val, dispatch),
    updateApplication: (val, val2) =>
      api.updateApplication(val, val2, dispatch),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsForm);
