/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import SideDrawer from "../global/SideDrawer";
import FormHelperText from "@mui/material/FormHelperText";
import { helpers } from "../../statesandlogics/cnstshelpers";
import { TextField } from "@mui/material";

class UserCard extends Component {
  render() {
    const {
      applicationData,
      updateUserFname,
      updateUserLname,
      updateUserEmail,
      updateUserPhone,
      updateUserAddress,
      currentDocument,
      regionsConfig,
      applicationIsNew,
      isValidEmail,
      isEmailValid,
      currentUserValidations,
      editDoc,
    } = this.props;
    let selPages = editDoc.selectPages[editDoc.selectedIdx];

    let selDoc = editDoc.isAdmin
      ? selPages["AdminPage"]
      : editDoc.isViewOnly
      ? selPages["ViewPage"]
      : selPages["NewappPage"];

    // let totalFees = [...currentDocument.vehicles, ...currentDocument.pets]
    //   .filter((item) => item.sticker_code.fee !== null)
    //   .reduce((sum, item) => sum + item.sticker_code.fee, 0);

    let vehicleFee = 0;
    let petFee = 0;
    currentDocument.vehicles
      .filter((item) => item.sticker_code.fee !== null)
      .map((v) => {
        if (v.late_fee_applied && v.prorated_fee_applied) {
          vehicleFee += (v.sticker_code.fee + 20) / 2;
        } else if (v.late_fee_applied) {
          vehicleFee += v.sticker_code.fee + 20;
        } else if (v.prorated_fee_applied) {
          vehicleFee += v.sticker_code.fee / 2;
        } else {
          vehicleFee += v.sticker_code.fee;
        }
      });

    currentDocument.pets
      .filter((item) => item.sticker_code.fee !== null)
      .map((p) => {
        if (p.late_fee_applied && p.prorated_fee_applied) {
          petFee += (p.sticker_code.fee + 5) / 2;
        } else if (p.late_fee_applied) {
          petFee += p.sticker_code.fee + 5;
        } else if (p.prorated_fee_applied) {
          petFee += p.sticker_code.fee / 2;
        } else {
          petFee += p.sticker_code.fee;
        }
      });
    let totalFees = vehicleFee + petFee;

    return (
      <>
        <Card
          raised
          sx={{
            display: "flex",
            // margin:2,
            marginRight: "5vw",
            marginLeft: "5vw",
            backgroundColor: "#FFFFFF",
            "@media (max-width: 900px)": {
              maxWidth: "90%",
            },
            // maxWidth: "80%",
            maxWidth: "90vw",
            width: "90vw",
            minWidth: 80,
            borderRadius: 2,
            marginBottom: 2,
            borderBottomLeftRadius: 0,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 20,
              // borderRight: ,
              backgroundColor: "#162B42",
              borderRadius: 2,
              borderBottomRightRadius: 12,
              // objectFit: "contain",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              "@media (max-width: 450px)": {
                flexDirection: "column",
                flexWrap: "unset",
              },
              justifyContent: "space-between",
              flex: 1,
              // flexDirection: "row",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            {editDoc.isViewOnly === false &&
            editDoc.selectPages[editDoc.selectedIdx].ViewPage !==
              "PreviewApplication" ? (
              // && this.props.currentPage !== 'Preview application'
              <>
                <CardContent
                  sx={{
                    "@media (max-width: 768px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      "@media (max-width: 768px)": {
                        flexDirection: "column",

                        // width:"80%"
                      },
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <PersonOutlineOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: 400,
                          marginLeft: 2,
                        }}
                      >
                        Name:
                      </Typography>
                    </div>

                    <TextField
                      variant="standard"
                      placeholder="First Name"
                      size="small"
                      name="fname"
                      error={currentUserValidations.first_name.error}
                      helperText={currentUserValidations.first_name.errorMsg}
                      type="text"
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.first_name
                      )}
                      sx={{
                        marginLeft: "2%",
                        "@media (max-width: 450px)": {
                          width: "75%",
                          marginLeft: "5%",
                        },
                      }}
                      inputProps={{
                        maxLength: 30,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserFname(e.target.value)}
                    />
                    <TextField
                      variant="standard"
                      placeholder="Family Name"
                      size="small"
                      name="fname"
                      type="text"
                      error={currentUserValidations.family_name.error}
                      helperText={currentUserValidations.family_name.errorMsg}
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.family_name
                      )}
                      sx={{
                        marginLeft: "2%",
                        "@media (max-width: 450px)": {
                          width: "75%",
                          marginLeft: "5%",
                        },
                      }}
                      InputProps={{
                        maxLength: 30,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserLname(e.target.value)}
                    />
                  </Box>
                </CardContent>
                <CardContent
                  sx={{
                    "@media (max-width: 768px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 768px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <LocalPhoneOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: 400,
                          marginLeft: 2,
                        }}
                      >
                        Phone:
                      </Typography>
                    </div>

                    <TextField
                      variant="standard"
                      placeholder="Phone"
                      size="small"
                      name="phone"
                      pattern="    /(\d{3})(\d{3})(\d{4})/,
                        ($1) $2-$3"
                      value={currentDocument.applicant.phone_number}
                      error={currentUserValidations.phone_number.error}
                      helperText={currentUserValidations.phone_number.errorMsg}
                      sx={{
                        marginLeft: "2%",
                        "@media (max-width: 450px)": {
                          width: "75%",
                          marginLeft: "5%",
                        },
                      }}
                      inputProps={{
                        maxLength: 14,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserPhone(e.target.value)}
                    />
                  </Box>
                </CardContent>
                <CardContent
                  sx={{
                    width: "30%",
                    "@media (max-width: 768px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 768px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <EmailOutlinedIcon
                        justifyContent="center"
                        sx={{ color: "rgba(0, 0, 0, 0.35)", fontSize: 22 }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: 400,
                          marginLeft: 2,
                        }}
                      >
                        Email:
                      </Typography>
                    </div>
                    <TextField
                      variant="standard"
                      placeholder="Email"
                      size="small"
                      name="email"
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.email
                      )}
                      error={currentUserValidations.email.error}
                      helperText={currentUserValidations.email.errorMsg}
                      required
                      pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                      sx={{
                        width: "100%",
                        marginLeft: "2%",
                        "@media (max-width: 450px)": {
                          width: "75%",
                          marginLeft: "5%",
                        },
                      }}
                      InputProps={{
                        maxLength: 30,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserEmail(e.target.value)}
                      onBlur={(e) => {
                        isEmailValid(e.target.value);
                      }}
                    />
                  </Box>
                </CardContent>
                <CardContent
                  sx={{
                    "@media (max-width: 768px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 768px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <HomeWorkOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: 400,
                          marginLeft: 2,
                        }}
                      >
                        Address:
                      </Typography>
                    </div>
                    <TextField
                      variant="standard"
                      placeholder="Address Line 1"
                      size="small"
                      // fullWidth
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.address.address_line_1
                      )}
                      error={
                        currentUserValidations.address.address_line_1.error
                      }
                      helperText={
                        currentUserValidations.address.address_line_1.errorMsg
                      }
                      name="address_line_1"
                      sx={{
                        marginLeft: "4%",
                        width: 300,
                        minWidth: 100,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                      InputProps={{
                        maxLength: 30,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserAddress(e)}
                    />
                    <TextField
                      variant="standard"
                      placeholder="Address Line 2"
                      size="small"
                      // fullWidth
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.address.address_line_2
                      )}
                      name="address_line_2"
                      sx={{
                        marginLeft: "4%",
                        width: 300,
                        minWidth: 100,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                      InputProps={{
                        maxLength: 30,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserAddress(e)}
                    />
                    <TextField
                      variant="standard"
                      placeholder="City"
                      size="small"
                      // fullWidth
                      type="text"
                      value={helpers.upperCaseNullHandler(
                        currentDocument.applicant.address.city
                      )}
                      name="city"
                      error={currentUserValidations.address.city.error}
                      helperText={currentUserValidations.address.city.errorMsg}
                      sx={{
                        marginLeft: "4%",
                        width: 150,
                        minWidth: 100,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                      InputProps={{
                        maxLength: 10,
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserAddress(e)}
                    />
                    <TextField
                      variant="standard"
                      placeholder="Postal Code"
                      size="small"
                      // fullWidth
                      value={currentDocument.applicant.address.postal_code}
                      error={currentUserValidations.address.postal_code.error}
                      helperText={
                        currentUserValidations.address.postal_code.errorMsg
                      }
                      name="postal_code"
                      sx={{
                        marginLeft: "4%",
                        width: 80,
                        minWidth: 100,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                      InputProps={{
                        sx: {
                          color: "#000000",
                        },
                      }}
                      onChange={(e) => updateUserAddress(e)}
                    />

                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: "4%",
                        width: 50,
                        minWidth: 80,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                    >
                      State :
                    </Typography>

                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{
                        // marginLeft: "4%",
                        width: 150,
                        minWidth: 100,
                        "@media (max-width: 450px)": {
                          width: "70%",
                          marginLeft: "5%",
                          marginTop: "2%",
                        },
                      }}
                    >
                      {/* <InputLabel
                        id="demo-simple-select-label"
                        sx={{ color: "#000000" }}
                      >
                        State Id
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="State Id"
                        name="state_id"
                        error={currentUserValidations.address.state_id.error}
                        helperText={
                          currentUserValidations.address.state_id.errorMsg
                        }
                        value={currentDocument.applicant.address.state.state_id}
                        // value={vehicle.sticker_code.code_id}
                        // autoWidth

                        onChange={(e) => updateUserAddress(e)}
                      >
                        {regionsConfig.map((regions) => (
                          <MenuItem value={regions.state_id}>
                            {regions.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </CardContent>
              </>
            ) : (
              <>
                <CardContent>
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 450px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <PersonOutlineOutlinedIcon
                      sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: 2,
                      }}
                    >
                      Name:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 500,
                        marginLeft: 2,
                      }}
                    >
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.first_name
                      ) +
                        " " +
                        helpers.upperCaseNullHandler(
                          applicationData.applicant.family_name
                        )}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 450px)": {
                        flexDirection: "column",
                        // width:"80%"
                      },
                    }}
                  >
                    <EmailOutlinedIcon
                      justifyContent="center"
                      sx={{ color: "rgba(0, 0, 0, 0.35)", fontSize: 22 }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: 2,
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 500,
                        marginLeft: 2,
                      }}
                    >
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.email
                      )}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 450px)": {
                        flexDirection: "column",
                        // width:"80%"
                      },
                    }}
                  >
                    <LocalPhoneOutlinedIcon
                      sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: 2,
                      }}
                    >
                      Phone:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 500,
                        marginLeft: 2,
                      }}
                    >
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.phone_number
                      )}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent sx={{ width: "75%", flexDirection: "row" }}>
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 450px)": {
                        flexDirection: "column",
                        // width:"80%"
                      },
                    }}
                  >
                    <HomeWorkOutlinedIcon
                      sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: 2,
                      }}
                    >
                      Address:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 500,
                        marginLeft: 2,
                      }}
                    >
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.address.address_line_1
                      )}
                      {applicationData.applicant.address.address_line_2
                        ?.length === 0
                        ? null
                        : ", "}
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.address.address_line_2
                      )}
                      {applicationData.applicant.address.city.length === 0
                        ? null
                        : ", "}
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.address.city
                      )}
                      {applicationData.applicant.address.postal_code?.length ===
                      0
                        ? null
                        : ", "}
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.address.postal_code
                      )}
                      {applicationData.applicant.address.state.abbreviation
                        .length === 0
                        ? null
                        : ", "}
                      {helpers.upperCaseNullHandler(
                        applicationData.applicant.address.state.abbreviation
                      )}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box
                    display="flex"
                    sx={{
                      // flexDirection: "row",
                      "@media (max-width: 450px)": {
                        flexDirection: "column",
                        // width:"80%"
                      },
                    }}
                  >
                    <AttachMoneyOutlinedIcon
                      sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 400,
                        marginLeft: 2,
                      }}
                    >
                      Total Fees:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        color: "#000000",
                        fontSize: 18,
                        fontWeight: 500,
                        marginLeft: 2,
                      }}
                    >
                      {"$ " + helpers.formatFee(totalFees)}
                    </Typography>
                  </Box>
                </CardContent>
              </>
            )}
          </Box>
        </Card>
        <SideDrawer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationIsNew: state.applicationVariables.applicationIsNew,
  applicationData: state.currentDocument,
  verified: state.userVariables.profileVerified,
  currentDocument: state.currentDocument,
  username: state.userVariables.username,
  regionsConfig: state.regionsConfig,
  currentPage: state.applicationVariables.currentPage,
  editDoc: state.editDoc,
  isValidEmail: state.SelectionCurrentDocument.isValidEmail,
  currentUserValidations: state.currentUserValidations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    updateApplicationStatus: (text) =>
      dispatch({ type: "UPDATE_APPLICATION_STATUS", value: text }),
    updateUserFname: (text) =>
      dispatch({ type: "UPDATE_USER_FNAME", value: text }),
    updateUserLname: (text) =>
      dispatch({ type: "UPDATE_USER_LNAME", value: text }),
    updateUserEmail: (text) =>
      dispatch({ type: "UPDATE_USER_EMAIL", value: text }),
    updateUserPhone: (text) =>
      dispatch({ type: "UPDATE_USER_PHONE", value: text }),
    updateUserAddress: (text) =>
      dispatch({ type: "UPDATE_USER_ADDRESS", value: text }),
    verifiedForm: () => dispatch({ type: "VERIFIED_FORM" }),
    postConfirmation: (val) => api.postConfirmation(val, dispatch),
    updateApplication: (val, val2) =>
      api.updateApplication(val, val2, dispatch),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
    isEmailValid: (text) => dispatch({ type: "VALIDATE_EMAIL", value: text }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
