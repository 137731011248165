/* eslint-disable react/no-unknown-property */
import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"
import React, { Component } from "react";
import { connect } from "react-redux";
import * as s from "../../statesandlogics/sxs";
import get_footer_text from '../../statesandlogics/footerText'

class Footer extends Component {
  render() {
    return (
      <Box sx={s.SXs.Footer.SX1} elevation={3}>
        <Box sx={s.SXs.Footer.SX2}>
          <BottomNavigation sx={s.SXs.Footer.SX3}>
            <Typography
              variant="title"
              // sx={s.SXs.Footer.SX4}
              sx={{
                margin: "0% 0% 0% 0%",
                fontSize: "12px",
                color: "#FFFF",
              }}
            >
              {get_footer_text()}
            </Typography>
          </BottomNavigation>
        </Box>
      </Box>
    );
  }
}
const mapStateToProps = () => ({
  // currentPage: state.leftSidebarVariables.currentPage,
});
const mapDispatchToProps = () => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
