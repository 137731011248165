import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../global/Footer";
import NavBar from "../global/NavBar";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { allEvents } from "../../statesandlogics/cnstshelpers";

function mapStateToProps(state) {
  return {
    isLoggedIn: state.appGlobals.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: () => dispatch({ type: allEvents.ADMIN_LOGIN_CLICK }),
    setShowApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
  };
}

let ForwardCall = ({ setShowApplication }) => {
  const navigate = useNavigate();
  const currRoute = useLocation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (currRoute.pathname === "/villageStickerApplicationAdmin") {
        setShowApplication(false);
        navigate("/villageStickerApplicationAdmin/Applications");
      }
    }, 10);
    return () => clearTimeout(timerId);
  }, [navigate, currRoute, setShowApplication]);
};

let LoginButton = ({ forwardClick }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate("/villageStickerApplicationAdmin/Applications");
  return (
    <Button
      onClick={() => {
        forwardClick();
        handleClick();
      }}
    >
      NewAdminLogin
    </Button>
  );
};
class A0_0_0_0_A_L_P extends Component {
  // componentDidMount() {
  //   this.props.login()
  // }
  render() {
    const { rname, allInnerRoutes, login, isLoggedIn, setShowApplication } =
      this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <div>
          {isLoggedIn ? (
            <ForwardCall setShowApplication={setShowApplication} />
          ) : null}
          {isLoggedIn ? (
            <Outlet />
          ) : (
            <>
              <NavBar />
              <div>
                <LoginButton forwardClick={login} />
              </div>
            </>
          )}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(A0_0_0_0_A_L_P);
