/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import AWS from "aws-sdk";
import axios from "axios";
import LogRocket from "logrocket";
import qs from "qs";
import * as h from "../cnstshelpers";
import { saveAs } from "file-saver";
import { ApplicationOperations } from "../cnstshelpers";

// import { axios } from "./axios";
let uploadedFiles = [];
const test_flag = false;

// const customAxios = axios.create({
//   baseURL: localStorage.getItem("API_LOCATION"),
//   withCredentials: true,
// });

export const getFeeSchedule = (val, dispatch) => {
  const query = {
    date_context: val === "" ? null : val,
  };
  axios
    .get(localStorage.getItem("API_LOCATION") + `/schedule`, {
      params: query,
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: "GET_FEE_SCHEDULE",
        value: res.data,
      });
    });
};

export const downloadReceipt = (val, dispatch) => {
  let c = 0;
  let timeoutId = null;
  axios
    .get(localStorage.getItem("API_LOCATION") + `/application/${val}/receipt`, {
      withCredentials: true,
    })
    .then((res) => {
      let intervalId = setInterval(() => {
        axios
          .get(res.data)
          .then(() => {
            clearInterval(intervalId);
            saveAs(res.data, "file.pdf");
            clearTimeout(timeoutId);
          })
          .catch((err) => {
            c++;
            console.log("e:" + err);
          });
      }, 1000);

      timeoutId = setTimeout(() => {
        alert(
          "Receipt failed to download, please contact Quicket support for further details."
        );
        clearInterval(intervalId);
      }, 15000);

      //  saveAs(res.data, "file.pdf");
    })
    .catch(() => {
      alert(
        "Receipt failed to download, please contact Quicket support for further details."
      );
    });
};

export const generateTransactionReport = (val1, val2, dispatch) => {
  const query = {
    date: val1 === "" ? null : val1,
    end_date: val2 === "" ? null : val2,
  };
  axios
    .get(localStorage.getItem("API_LOCATION") + `/payments/report`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
      params: query,
    })
    .then((res) => {
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'",
        })
      );

      saveAs(
        url,
        "report_" + val1.toString() + "_to_" + val2?.toString() + ".xlsx"
      );
    })
    .catch(() => {
      alert(
        "Report failed to download, please contact Quicket support for further details."
      );
    });
};

export const downloadFile = (link) => {
  axios
    .head(link)
    .then((res) => {
      saveAs(link, "file.pdf");
    })
    .catch((err) => {});
};

export const recordPayment = (
  currentDocument,
  applicationId,
  Tnumber,
  type,
  fees,
  dispatch
) => {
  const query = {
    application_id: applicationId,
    payment_method_type: type,
    transaction_number: Tnumber === "" ? null : Tnumber,
    amount: isNaN(fees) === true ? null : fees,
  };

  axios
    .post(
      localStorage.getItem("API_LOCATION") + "/payments/record_payment",
      null,
      {
        withCredentials: true,
        params: query,
      }
    )
    .then((res) => {
      alert("Recorded Payment Successfully");
      ApplicationAPICRUDOperations(
        currentDocument,
        ApplicationOperations.Submit,
        dispatch
      );
      dispatch({ type: "OPEN_SIDEBAR", value: false, click: "cardPayment" });
    })
    .catch((err) => {
      alert("Payment Recording process failed, Please try again");
    });
};

export const loadApiSetting = (dispatch) => {
  axios
    .get("/config.json")
    .then((res) => {
      localStorage.setItem("API_LOCATION", res.data.backend.apiEndpoint);
      localStorage.setItem("aws_region", res.data.backend.aws_region);
      localStorage.setItem("client_id", res.data.backend.client_id);
      localStorage.setItem("stripe_pk", res.data.backend.stripe_pk);
      localStorage.setItem("logrocketApp", res.data.backend.logrocketApp);
      localStorage.setItem(
        "logrocketSdkServer",
        res.data.backend.logrocketSdkServer
      );
      localStorage.setItem(
        "logrocketIngestServer",
        res.data.backend.logrocketIngestServer
      );
      localStorage.setItem(
        "showFirstTimeUser",
        res.data.backend.showFirstTimeUser
      );
      if (typeof res.data.oneAppLink !== "undefined") {
        localStorage.setItem("oneAppLink", res.data.oneAppLink);
      }

      if (typeof res.data.siteLogoName !== "undefined") {
        localStorage.setItem("siteLogoName", res.data.siteLogoName);
      }

      dispatch({ type: "LOAD_API_SETTING" });
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      // h.helpers.UINotification(
      //   "Site configuration loading failed.",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};

export const loadAdminIdentity = (dispatch) => {
  axios
    .get(localStorage.getItem("API_LOCATION") + "/user/identity", {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: "LOAD_ADMIN_IDENTITY",
        value: res.data.identity,
      });
    });
};
export const loadRegionsData = (dispatch) => {
  axios
    .get(localStorage.getItem("API_LOCATION") + "/available_regions", {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: "LOAD_REGIONS_DATA",
        value: res.data,
      });
      // h.helpers.UINotification(
      //   "Loading available regions succeded ",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      // h.helpers.UINotification(
      //   "Loading available regions failed ",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const loadStickerData = (dispatch) => {
  axios
    .get(localStorage.getItem("API_LOCATION") + "/sticker_codes", {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: "LOAD_STICKER_DATA",
        value: res.data,
      });
      // h.helpers.UINotification(
      //   "Loading sticker codes succeded",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      // h.helpers.UINotification(
      //   "Loading sticker codes failed",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const getUserApplicationByID = (val, dispatch, changeToNew) => {
  axios
    .get(
      localStorage.getItem("API_LOCATION") +
        `/application/?application_id=${val}`,
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      LogRocket.identify(res.data.applicant.email);
      dispatch({
        type: "SET_CURRENT_PAGE",
        value: "Preview",
        apiData: res.data,
      });
      // h.helpers.UINotification(
      //   "User application search by id Succeded",
      //   h.NotificationTypes.Error,
      //   5000
      // );
      if (changeToNew) changeToNew();
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      // h.helpers.UINotification(
      //   "User application search by id failed",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const searchApplications = (text, limit, offset, dispatch) => {
  if (text === "GetAll") {
    axios
      .get(localStorage.getItem("API_LOCATION") + "/search/application", {
        withCredentials: true,
        params: {
          limit: limit,
        },
      })
      .then((res) => {
        dispatch({ type: "SEARCH_RESULTS", value: res.data, firstLoad: true });
        // h.helpers.UINotification(
        //   "Geting all application succeded",
        //   h.NotificationTypes.Success,
        //   5000
        // );
      })
      .catch((reason) => {
        // if (localStorage.getItem("oneAppLink") !== null) {
        //   window.location.href = localStorage.getItem("oneAppLink");
        // }
      });
  } else {
    const query = {
      first_name: text.fname === "" ? null : text.fname,
      last_name: text.lname === "" ? null : text.lname,
      address: text.address === "" ? null : text.address,
      license_plate: text.license_plate === "" ? null : text.license_plate,
      tag_number: text.tag_number === "" ? null : text.tag_number,
      sticker_code_ids:
        text.sticker_code_ids.length === 0 ? null : text.sticker_code_ids,
      status:
        text.application_status.length === 0 ? null : text.application_status,
      min_application_fee:
        text.min_fee && text.min_fee.match("^[0-9]*$") ? text.min_fee : null,
      max_application_fee:
        text.max_fee && text.max_fee.match("^[0-9]*$") ? text.max_fee : null,
      earliest_submission_date:
        text.dateRange.from === "" ? null : text.dateRange.from,
      latest_submission_date:
        text.dateRange.to === "" ? null : text.dateRange.to,
      application_number:
        text.application_number && text.application_number.match("^[0-9]*$")
          ? parseInt(text.application_number)
          : null,
      limit: text.limit,
      offset: text.offset,
    };

    axios
      .get(localStorage.getItem("API_LOCATION") + "/search/application", {
        withCredentials: true,
        params: query,
        paramsSerializer: {
          serialize: (params) =>
            qs.stringify(
              Object.fromEntries(
                Object.entries(params).filter(([_, value]) => value !== null)
              ),
              { arrayFormat: "repeat" }
            ),
        },
      })
      .then((res) => {
        dispatch({
          type: "SEARCH_RESULTS",
          value: res.data,
          firstLoad: false,
        });
      })
      .catch((reason) => {
        console.log("Could not search applications.");

        // h.helpers.UINotification(
        //   "Geting application errored",
        //   h.NotificationTypes.Error,
        //   5000
        // );
      });
  }
};
export const cognitoLogin = (username, dispatch) => {
  const client = new CognitoIdentityProviderClient({
    region: localStorage.getItem("aws_region"),
  });
  const params = {
    /** input parameters */
    AuthFlow: "CUSTOM_AUTH",
    ClientId: localStorage.getItem("client_id"),
    AuthParameters: {
      // AuthParametersType
      USERNAME: username,
    },
  };
  const command = new InitiateAuthCommand(params);
  client
    .send(command)
    .then((response) => {
      dispatch({
        type: "LOAD_APPLICATION_LOGIN",
        value: ["Application login", true],
        loginResponse: response,
      });
      // h.helpers.UINotification(
      //   "Login succeded",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      dispatch({
        type: "LOAD_APPLICATION_LOGIN",
        value: ["Application login", false],
        loginResponse: {},
      });

      h.helpers.UINotification("Login failed", h.NotificationTypes.Error);
    });
};
export const verifyUser = (_username, cognitoResponse, otp, dispatch) => {
  const client = new CognitoIdentityProviderClient({
    region: localStorage.getItem("aws_region"),
  });
  const params = {
    ClientId: localStorage.getItem("client_id"),
    ChallengeName: cognitoResponse.ChallengeName,
    ChallengeResponses: {
      USERNAME: cognitoResponse.ChallengeParameters.USERNAME,
      ANSWER: otp,
      // add user input OTP here
    },
    Session: cognitoResponse.Session,
  };
  const command = new RespondToAuthChallengeCommand(params);
  client
    .send(command)
    .then((response) => {
      axios
        .get(
          localStorage.getItem("API_LOCATION") + "/search/application/latest",
          {
            withCredentials: true,
            headers: {
              "x-token": `${response.AuthenticationResult.AccessToken}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "LOAD_CITIZEN_PAGE",
            value: "User Profile",
            apiData: res.data,
          });
          // h.helpers.UINotification(
          //   "Found latest application",
          //   h.NotificationTypes.Success,
          //   5000
          // );
        })
        .catch(() => {
          // h.helpers.UINotification(
          //   "Could not find latest application",
          //   h.NotificationTypes.Error,
          //   5000
          // );
        });
      dispatch({
        type: "SET_LOGIN_TOKEN",
        value: response.AuthenticationResult,
      });
      // h.helpers.UINotification(
      //   "User authorized",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      // h.helpers.UINotification(
      //   "Could not authorized",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const submitPaymentDetails = (
  e,
  currentDocument,
  application_id,
  totalFees,
  stripe,
  elements,
  dispatch
) => {
  e.preventDefault();
  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
  }

  stripe
    .confirmSetup({
      elements,
      redirect: "if_required",
    })
    .then((res) => {
      switch (res.setupIntent.status) {
        case "succeeded":
          // alert("Success! Your payment method has been saved.");
          recordPayment(
            currentDocument,
            application_id,
            "",
            "card_online",
            totalFees,
            dispatch
          );
          break;

        case "processing":
          alert(
            "Processing payment details. We'll update you when processing is complete."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          alert(
            "Failed to process payment details. Please try another payment method."
          );
          break;
      }
    })
    .catch((err) => {
      alert(err);
    });

  // setIsLoading(true);
};
export const getClientSecret = async (
  citizen_user_id,
  loginTokens,
  dispatch
) => {
  const query = {
    citizen_user_id: citizen_user_id,
  };

  await axios
    .post(
      localStorage.getItem("API_LOCATION") + "/payments/add_payment_info",
      null,
      {
        withCredentials: true,
        params: query,
      }
    )
    .then((res) => {
      dispatch({ type: "SET_CLIENT_SECRET_KEY", value: res.data });
      // h.helpers.UINotification(
      //   "Found ClientSecret",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      if (test_flag === true) {
        // dispatch({ type: "SET_CLIENT_SECRET_KEY", value: "" });
      }
      // h.helpers.UINotification(
      //   "Could not find getClientSecret",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const getUploadedDocs = (val, dispatch) => {
  axios
    .get(localStorage.getItem("API_LOCATION") + `/documents/${val}`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch({
        type: "SHOW_UPLOADED_DOCS",
        apiData: res.data,
      });
      // h.helpers.UINotification(
      //   "Found uploaded documents",
      //   h.NotificationTypes.Success,
      //   5000
      // );
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      // h.helpers.UINotification(
      //   "Could not find uploaded documents",
      //   h.NotificationTypes.Error,
      //   5000
      // );
    });
};
export const uploadDocuments = (val, val1, dispatch) => {
  let allFiles = [];

  uploadedFiles.map((files) => {
    files.map((file) => {
      allFiles.push(file);
    });
  });

  axios
    .post(
      localStorage.getItem("API_LOCATION") + "/documents/bulk_upload",
      {
        filenames: val,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      const s3 = new AWS.S3({
        region: "us-gov-west-1",
        credentials: new AWS.Credentials(
          res.data.Credentials.AccessKeyId,
          res.data.Credentials.SecretAccessKey,
          res.data.Credentials.SessionToken
        ),
      });

      allFiles.map((file, idx) => {
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;

          const uploadParams = {
            Bucket: res.data.FilePaths[idx].split("/")[0],
            Key: res.data.FilePaths[idx].split("/").slice(1).join("/"),
            Body: arrayBuffer,
            ...(typeof res.data.KmsKeyId !== "undefined" && {
              ServerSideEncryption: "aws:kms",
            }),
            ...(typeof res.data.KmsKeyId !== "undefined" && {
              SSEKMSKeyId: res.data.KmsKeyId,
            }),
            ContentType: file.type,
          };
          s3.upload(uploadParams, function (err) {
            if (err) {
              const progressElement = document.getElementById(file.path);
              progressElement.innerHTML = `
              <div style="background-color: #F55F4B; border-radius: 10px; padding: 4px; display: inline-block;">
   <p style="margin: 0; color: white;font-size:8px  font-weight: bold;">failed</p>
</div>
           `;
              alert("Error Uploading , Try to re-upload the file");
            } else {
              // val = val.filter(
              //   (previewFile) => previewFile.filename !== file.filename
              // );
              // dispatch({ type: "UPDATE_UPLOADED_FILES", value: val });
            }
          }).on("httpUploadProgress", function (progress) {
            const progressSpanId = file.path;

            const progressElement = document.getElementById(progressSpanId);
            const currentFileProgress = parseInt(
              (progress.loaded * 100) / progress.total
            );
            // let progressElementClass = currentFileProgress < 100 ? 'in-progress' : 'complete';
            const styleElement = `--value:${currentFileProgress}`;
            if (currentFileProgress === 100) {
              allFiles = allFiles.filter(
                (allFile) => allFile.filename !== file.filename
              );
            }
            progressElement.innerHTML = `<div role="progressbar" aria-valuenow=${currentFileProgress} aria-valuemin="0" aria-valuemax="100" style=${styleElement}></div>`;
          });
        };
        reader.readAsArrayBuffer(file);
      });
      dispatch({
        type: "UPDATE_DOC_SESSION_ID",
        value: res.data.DocumentSessionId,
      });
      // dispatch({ type: "UPLOAD_TO_S3", value: val });
      h.helpers.UINotification(
        "Uploaded file - " + val,
        h.NotificationTypes.Success
      );
    })
    .catch(() => {
      h.helpers.UINotification(
        "Could not upload file - " + val,
        h.NotificationTypes.Error
      );
    });
};
export const updateApplication = (val, val2, status, dispatch) => {
  const JPJS = (t) => JSON.parse(JSON.stringify(t));
  const data = JPJS(val);

  let totalFees = [...val.vehicles, ...val.pets].reduce(
    (sum, item) => sum + item.sticker_code.fee,
    0
  );

  if (status === "") {
    status = data.status;
  }

  delete data.applicant.address_id;
  delete data.applicant.address.address_id;
  delete data.submission_date;
  data.applicant.address.state_id = data.applicant.address.state.state_id;
  delete data.applicant.address.state;
  const new_vehicles = [];
  const new_pets = [];
  const existing_vehicles = [];
  const existing_pets = [];

  data.pets.map((pet) => {
    if (pet.pet_id < 0) {
      delete pet.pet_id;
      new_pets.push(pet);
    } else {
      existing_pets.push(pet);
    }
    pet.sticker_code_id = pet.sticker_code.code_id;
    delete pet.sticker_code;
  });

  data.vehicles.map((vehicle) => {
    if (vehicle.vehicle_id < 0) {
      delete vehicle.vehicle_id;
      new_vehicles.push(vehicle);
    } else {
      existing_vehicles.push(vehicle);
    }
    vehicle.sticker_code_id = vehicle.sticker_code.code_id;
    delete vehicle.sticker_code;
  });
  data.applicant.address.state_id = 14;
  const formdata = {
    application_id: data.application_id,
    status: status,
    applicant: data.applicant,
    pets: existing_pets,
    vehicles: existing_vehicles,
    new_vehicles: new_vehicles,
    new_pets: new_pets,
  };
  axios
    .patch(
      localStorage.getItem("API_LOCATION") +
        `/application/${formdata.application_id}`,
      formdata,
      { withCredentials: true }
    )
    .then(() => {
      h.helpers.UINotification(
        "Updated application file.",
        h.NotificationTypes.Success
      );

      dispatch({
        type: h.allEvents.application.UPDATE_APPLICATION_STATUS,
        value: status,
      });
      if (status === "Approved" && totalFees === 0) {
        axios.post(
          localStorage.getItem("API_LOCATION") + "/payments/record_payment",
          null,
          {
            withCredentials: true,
            params: {
              application_id: formdata.application_id,
              payment_method_type: "nc",
              transaction_number: null,
              amount: totalFees,
            },
          }
        );
      }
      getUserApplicationByID(formdata.application_id, dispatch);
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      h.helpers.UINotification(
        "Could not update application file.",
        h.NotificationTypes.Error
      );
    });
};
export const postConfirmation = (val, dispatch) => {
  const JPJS = (t) => JSON.parse(JSON.stringify(t));
  const data = JPJS(val);

  delete data.application_id;
  delete data.applicant.applicant_id;
  delete data.applicant.address_id;
  delete data.applicant.address.address_id;
  data.applicant.address.state_id = data.applicant.address.state.state_id;
  delete data.applicant.address.state;

  data.pets.map((pet) => {
    delete pet.pet_id;
    pet.sticker_code_id = pet.sticker_code.code_id;
    delete pet.sticker_code;
  });

  data.vehicles.map((vehicle) => {
    delete vehicle.vehicle_id;
    vehicle.sticker_code_id = vehicle.sticker_code.code_id;
    delete vehicle.sticker_code;
  });

  data.applicant.address.state_id = 14;

  const formdata = {
    status: "Pending",
    document_status: "Pending",
    applicant: data.applicant,
    pets: data.pets,
    vehicles: data.vehicles,
    document_session_id: data.document_session_id,
    citizen_user_id: data.citizen_user_id,
  };

  axios
    .post(localStorage.getItem("API_LOCATION") + "/application/", formdata, {
      withCredentials: true,
    })
    .then((res) => {
      let d = res.data;
      dispatch({
        type: h.allEvents.application.UPDATE_APPLICATION_ID,
        value: parseInt(d.app_id),
      });
      h.helpers.UINotification(
        "Created application.",
        h.NotificationTypes.Success
      );
      getUserApplicationByID(parseInt(d.app_id), dispatch);
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      h.helpers.UINotification(
        "Could not create application.",
        h.NotificationTypes.Error
      );
    });
};
export const deleteSticker = (applicationId, stickerId, stickerType) => {
  const formdata = {
    sticker_id: isNaN(stickerId) === true ? null : stickerId,
    sticker_type: stickerType === "" ? null : stickerType,
  };
  axios
    .delete(
      localStorage.getItem("API_LOCATION") +
        `/application/?application_id=${applicationId}/stickers`,
      {
        data: formdata,
      },
      { withCredentials: true }
    )
    .then(() => {
      // dispatch({ type: "REMOVE_VEHICLE_STICKER", value: "Add Vehicle or Pet" })
    });
};
export const loggedIn = (username, dispatch) => {
  axios
    .get(
      localStorage.getItem("API_LOCATION") +
        `/application/?application_id=${username}`,
      { withCredentials: true }
    )
    .then((res) => {
      dispatch({
        type: "LOAD_CITIZEN_PAGE",
        value: "User Profile",
        apiData: res.data,
      });
      LogRocket.identify(res.data.application_id, {
        email: res.data.applicant.email,
      });
      h.helpers.UINotification("Deleted sticker", h.NotificationTypes.Success);
    })
    .catch(() => {
      if (test_flag === true) {
        /* empty */
      }
      h.helpers.UINotification(
        "Could not delete sticker",
        h.NotificationTypes.Error
      );
    });
};
export const removeDocument = (val, files, dispatch) => {
  let arr = [...files];
  arr.splice(val, 1);
  uploadedFiles = uploadedFiles.filter((_file, idx) => idx === val);

  dispatch({ type: "REMOVE_DOC", value: arr });
};
export const dropCall = (val) => {
  uploadedFiles.push(val);
};

export const ApplicationAPICRUDOperations = (
  currentDocument,
  reqOp,
  dispatch
) => {
  switch (reqOp) {
    case h.ApplicationOperations.Approve:
      updateApplication(
        currentDocument,
        currentDocument.application_id,
        "Approved",
        dispatch
      );
      break;
    case h.ApplicationOperations.Create:
      postConfirmation(currentDocument, dispatch);
      break;
    case h.ApplicationOperations.Reject:
      updateApplication(
        currentDocument,
        currentDocument.application_id,
        "Rejected",
        dispatch
      );
      break;
    case h.ApplicationOperations.Submit:
      updateApplication(
        currentDocument,
        currentDocument.application_id,
        "Pending",
        dispatch
      );
      break;
    case h.ApplicationOperations.Update:
      updateApplication(
        currentDocument,
        currentDocument.application_id,
        "",
        dispatch
      );
      break;
    case h.ApplicationOperations.IssueTag:
      updateApplication(
        currentDocument,
        currentDocument.application_id,
        "Fulfilled",
        dispatch
      );
      break;
    default:
      break;
  }
};
