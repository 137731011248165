export const data = () => ({
  // adminSearch: [
  //   {
  //     application_id:,
  //     first_name:,
  //     address_line_1
  //     address_line_2
  //     date_of_purchase
  //     fee_categories:[],
  //     total_fee
  //   }
  // ],
  existingApps: [
    {
      userID: '9999999999',
      userDetails: {
        fname: 'John',
        midName: '',
        lname: 'Doe',
        email: 'jDoe@gmail.com',
        phone: '9999999999',
        birthday: '01/01/1990',
        address: 'abc street, xyz'
      },
      currentStickerApplications: {
        Vehicles: [
          {
            applicationVehicleRecordStatus: 'existingVehicle',
            vehicleMake: 'Ford',
            dateOfPurchase: '20/12/2022',
            tagNumber: 'SN01',
            licensePlate: 'DL1223',
            vehicleType: 'Car',
            year: '2021',
            model: 'Pick-up',
            color: 'Black',
            feeCategory: '2',
            fee: '0.00'
          },
          {
            applicationVehicleRecordStatus: 'existingVehicle',
            vehicleMake: 'Chev',
            dateOfPurchase: '19/12/2022',
            tagNumber: 'SN02',
            licensePlate: 'N 524959',
            vehicleType: 'Jeep',
            year: '2020',
            model: 'Blazer',
            color: 'Gray',
            feeCategory: '2',
            fee: '0.00'
          },
          {
            applicationVehicleRecordStatus: 'addVehicle',
            vehicleMake: '',
            dateOfPurchase: '',
            tagNumber: '',
            licensePlate: '',
            vehicleType: '',
            year: '',
            model: '',

            color: '',
            feeCategory: 'Auto',
            fee: ''
          }
        ],
        Pets: [
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'existingPet',
            petNumber: '0123',
            petName: 'Charlie',
            sex: 'female',
            breed: 'Chihuah',
            dateOfPurchase: '20/05/2021',
            age: '4',
            color: 'Brown',
            feeCategory: '2',
            fee: '0.00'
          },
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'addPet',
            petNumber: '',
            petName: '',
            sex: '',
            breed: '',
            dateOfBirth: '',
            age: '',
            color: '',
            feeCategory: '',
            fee: ''
          }
        ],
        ApplicationStatus: 'ReadyForRenewal',
        ApplicationStatusText: 'Ready For Renewal',
        TotalApplicationDue: 40.0,
        DocumentStatus: 'DocumentsComplete',
        DocumentStatusText: 'Documents Complete',
        PaymentStatus: 'PaymentComplete',
        PaymentStatusText: 'Payment Complete',
        collectiveStickerValidityDate: '07/02/2023',
        HistoryOfStatusChanges: [
          {
            DateOfChange: '',
            ApplicationStatus: { PrevState: '', CurrState: '' },
            DocumentStatus: { PrevState: ' ', CurrState: '' },
            PaymentStatus: { PrevState: '', CurrState: '' }
          }
        ],
        HistoryOfCommunication: [
          {
            DateOfCommunication: '',
            Sms: {
              sentDate: '',
              sentText: ''
            },
            email: {
              sentDate: '',
              sentText: ''
            }
          }
        ]
      }
    },
    {
      userID: '8888888888',
      userDetails: {
        fname: 'Alice',
        midName: '',
        lname: 'Poe',
        email: 'aPoe@gmail.com',
        phone: '8888888888',
        birthday: '01/02/1990',
        address: 'new street, nyc'
      },
      currentStickerApplications: {
        Vehicles: [
          {
            applicationVehicleRecordStatus: 'existingVehicle',
            vehicleMake: 'Ferrari',
            dateOfPurchase: '20/1/2008',
            tagNumber: 'F223',
            licensePlate: 'NYC223',
            vehicleType: 'Car',
            year: '2022',
            model: 'F6',
            color: 'red',
            feeCategory: '2',
            fee: '0.00'
          },
          {
            applicationVehicleRecordStatus: 'addVehicle',
            vehicleMake: '',
            dateOfPurchase: '',
            tagNumber: '',
            licensePlate: '',
            vehicleType: '',
            year: '',
            model: '',
            color: '',
            feeCategory: 'Auto',
            fee: ''
          }
        ],
        Pets: [
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'existingPet',
            petNumber: '003',
            petName: 'Coco',
            sex: 'male',
            breed: 'German Shepherd',
            dateOfPurchase: '20/06/2021',
            age: '4',
            color: 'Brown',
            feeCategory: 'Auto',
            fee: '0.00'
          },
          {
            applicationPetRecordStatus: 'addPet',
            petNumber: '',
            petName: '',
            sex: '',
            breed: '',
            dateOfPurchase: '',
            age: '',
            color: '',
            feeCategory: '',
            fee: ''
          }
        ],
        ApplicationStatus: 'ReadyForRenewal',
        ApplicationStatusText: 'Ready for renewal',
        TotalApplicationDue: 40.0,
        DocumentStatus: 'DocumentsComplete',
        DocumentStatusText: 'Documents Complete',
        PaymentStatus: 'PaymentComplete',
        PaymentStatusText: 'Payment Complete',
        collectiveStickerValidityDate: '01/06/2023',
        HistoryOfStatusChanges: [
          {
            DateOfChange: '',
            ApplicationStatus: { PrevState: '', CurrState: '' },
            DocumentStatus: { PrevState: ' ', CurrState: '' },
            PaymentStatus: { PrevState: '', CurrState: '' }
          }
        ],
        HistoryOfCommunication: [
          {
            DateOfCommunication: '',
            Sms: {
              sentDate: '',
              sentText: ''
            },
            email: {
              sentDate: '',
              sentText: ''
            }
          }
        ]
      }
    },
    {
      userID: '33333333333',
      userDetails: {
        fname: 'Justin',
        midName: '',
        lname: 'Row',
        email: 'jRow@gmail.com',
        phone: '333333333338',
        birthday: '01/03/1990',
        address: 'old street, illinois'
      },
      currentStickerApplications: {
        Vehicles: [
          {
            applicationVehicleRecordStatus: 'existingVehicle',
            vehicleMake: 'Volvo',
            dateOfPurchase: '20/5/2008',
            tagNumber: 'V823',
            licensePlate: 'ILL823',
            vehicleType: 'Bus',
            year: '2023',
            model: 'V7',

            feeCategory: '2',
            fee: '0.00'
          },
          {
            applicationVehicleRecordStatus: 'addVehicle',
            vehicleMake: '',
            dateOfPurchase: '',
            tagNumber: '',
            licensePlate: '',
            vehicleType: '',
            year: '',
            model: '',
            color: '',
            feeCategory: 'Auto',
            fee: ''
          }
        ],
        Pets: [
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'existingPet',
            petNumber: '08',
            petName: 'Butter',
            sex: 'female',
            breed: 'Bull Dog',
            dateOfPurchase: '25/06/2021',
            age: '8',
            color: 'Brown',
            feeCategory: '14',
            fee: '5.00'
          },
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'existingPet',
            petNumber: '09',
            petName: 'Cato',
            sex: 'female',
            breed: 'Rag Doll',
            dateOfPurchase: '06/06/2021',
            age: '4',

            feeCategory: '14',
            fee: '5.00'
          },
          {
            tagNumber: 'N/A',
            applicationPetRecordStatus: 'addPet',
            petNumber: '',
            petName: '',
            sex: '',
            breed: '',
            dateOfPurchase: '',
            age: '',
            color: '',
            feeCategory: '',
            fee: ''
          }
        ],
        ApplicationStatus: 'ReadyForRenewal',
        ApplicationStatusText: 'Ready for renewal',
        TotalApplicationDue: 40.0,
        DocumentStatus: 'DocumentsComplete',
        DocumentStatusText: 'Documents Complete',
        PaymentStatus: 'PaymentComplete',
        PaymentStatusText: 'Payment Complete',
        collectiveStickerValidityDate: '01/06/2023',
        HistoryOfStatusChanges: [
          {
            DateOfChange: '',
            ApplicationStatus: { PrevState: '', CurrState: '' },
            DocumentStatus: { PrevState: ' ', CurrState: '' },
            PaymentStatus: { PrevState: '', CurrState: '' }
          }
        ],
        HistoryOfCommunication: [
          {
            DateOfCommunication: '',
            Sms: {
              sentDate: '',
              sentText: ''
            },
            email: {
              sentDate: '',
              sentText: ''
            }
          }
        ]
        // "Documents": [
        //     {
        //         'name':,
        //         'size':,
        //         'content':,

        //     }
        // ]
      }
    }
  ],

  StickerPricing: [
    {
      id: '1',
      description: 'Auto',
      price: 20.0
    },
    {
      id: '2',
      description: 'Senior Citizen',
      price: 0.0
    },
    {
      id: '3',
      description: 'Truck B',
      price: 40.0
    },
    {
      id: '4',
      description: 'Truck D-F',
      price: 65.0
    },
    {
      id: '5',
      description: 'Truck H-L',
      price: 80.0
    },
    {
      id: '6',
      description: 'Truck N-S',
      price: 110.0
    },
    {
      id: '7',
      description: 'Truck T-Z',
      price: 155.0
    },
    {
      id: '8',
      description: 'Motorc cycle',
      price: 10.0
    },
    {
      id: '9',
      description: 'Antique Vehicle',
      price: 10.0
    },
    {
      id: '10',
      description: 'Recreation Vehicle',
      price: 40.0
    },
    {
      id: '11',
      description: 'Commercial Vehicle',
      price: 75.0
    },
    {
      id: '12',
      description: 'Handicapped',
      price: 0.0
    },
    {
      id: '13',
      description: 'Fertile Animal',
      price: 10.0
    },
    {
      id: '14',
      description: 'Sterile Animal',
      price: 5.0
    },
    {
      id: '15',
      description: 'Official Use',
      price: 0.0
    },
    {
      id: '16',
      description: 'Transfer',
      price: 5.0
    },
    {
      id: '17',
      description: 'REPLACE',
      price: 0.0
    },
    {
      id: '18',
      description: 'Military',
      price: 0.0
    },
    {
      id: '19',
      description: 'Service Dog',
      price: 0.0
    }
  ]
})
