import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BadgeIcon from "@mui/icons-material/Badge";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import { Link } from "react-router-dom";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PaginationTool from "./PaginationTool";
import { helpers } from "../../statesandlogics/cnstshelpers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const toRoute = "ReviewingApplication";
class LatestTable extends Component {
  render() {
    const {
      getUserApplicationByID,
      getUploadedDocs,
      showApplication,
      searchResults,
      setAppIdAndStatusToNew,
      downloadReceipt,
    } = this.props;

    return (
      <>
        <div class="centered-filter">
          <PaginationTool />
        </div>
        <Box>
          {searchResults.length > 0 ? (
            <>
              {searchResults.map((row) => (
                <Card className="card_item_wrapper">
                  <div>
                    <div className="first_section">
                      <Box className="first_div application_id_div">
                        <Typography
                          sx={{ display: { sm: "display", md: "none" } }}
                          className="lable_text"
                        >
                          Application id:{" "}
                        </Typography>
                        <Typography className="id_text">
                          {" "}
                          #{row.application_id}{" "}
                        </Typography>
                      </Box>
                      <Box className="first_div name_div">
                        <Typography
                          sx={{ display: { sm: "display", md: "none" } }}
                          className="lable_text"
                        >
                          Name:{" "}
                        </Typography>
                        <BadgeIcon fontSize="13px" />
                        <Typography className="name_text">
                          {" "}
                          {row.first_name + " " + row.family_name}{" "}
                        </Typography>
                      </Box>
                      <Box className="first_div address_div">
                        <Typography
                          sx={{ display: { sm: "display", md: "none" } }}
                          className="lable_text"
                        >
                          Address:{" "}
                        </Typography>
                        <LocationOnIcon fontSize="13px" />
                        <Typography className="address_text">
                          {" "}
                          {row.address_line_1 + " "}{" "}
                          {row.address_line_2 === null ||
                          row.address_line_2 === "null"
                            ? ""
                            : row.address_line_2}
                        </Typography>
                      </Box>
                      <Box className="first_div total_fee_div">
                        <Typography
                          sx={{ display: { sm: "display", md: "none" } }}
                          className="lable_text"
                        >
                          Total Fee:{" "}
                        </Typography>

                        <Typography>
                          {" "}
                          {"$ " + helpers.formatFee(row.total_fee)}
                        </Typography>
                      </Box>
                      <Box className="first_div">
                        <Typography
                          sx={{ display: { sm: "display", md: "none" } }}
                          className="lable_text"
                        >
                          Status:{" "}
                        </Typography>
                        <Typography className="progress_btn">
                          {" "}
                          {row.status === "Fulfilled" ? (
                            <div class="fulfilled-label">Fulfilled</div>
                          ) : row.status === "Rejected" ? (
                            <div class="rejected-label">Rejected</div>
                          ) : row.status === "In Progress" ? (
                            <div class="progress-label">In Progress</div>
                          ) : row.status === "Approved" ? (
                            <div class="approved-label">Approved</div>
                          ) : (
                            <div class="pending-label">{row.status}</div>
                          )}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                  <div className="button_wrapper">
                    {row.status !== "Fulfilled" ? (
                      <>
                        <Button
                          variant="contained"
                          startIcon={<VisibilityIcon />}
                          component={Link}
                          onClick={() => {
                            showApplication(true);
                            getUserApplicationByID(row.application_id);
                            getUploadedDocs(row.application_id);
                          }}
                          to={toRoute}
                          sx={{
                            marginRight: "2px",
                            backgroundColor: "#162B42",
                            "&:hover": { backgroundColor: "#106CE7" },
                          }}
                        >
                          View
                        </Button>
                        <IconButton
                          sx={{
                            "&:hover": { backgroundColor: "#106CE7" },
                          }}
                          onClick={() => {
                            if (row.status !== "Approved") {
                              alert(
                                "This receipt is not final until the application is approved."
                              );
                            }
                            downloadReceipt(row.application_id);
                          }}
                        >
                          <DownloadIcon
                            sx={{
                              marginRight: "2px",
                              color: "#162B42",
                              "&:hover": { backgroundColor: "#106CE7" },
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          startIcon={<AutorenewIcon />}
                          component={Link}
                          onClick={() => {
                            showApplication(true);
                            getUserApplicationByID(
                              row.application_id,
                              setAppIdAndStatusToNew
                            );
                          }}
                          to={toRoute}
                          sx={{
                            marginRight: "5px",
                            backgroundColor: "#162B42",
                            "&:hover": { backgroundColor: "#106CE7" },
                          }}
                        >
                          Renew
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<VisibilityIcon />}
                          component={Link}
                          onClick={() => {
                            showApplication(true);
                            getUserApplicationByID(row.application_id);
                            getUploadedDocs(row.application_id);
                          }}
                          to={toRoute}
                          sx={{
                            marginRight: "2px",
                            backgroundColor: "#162B42",
                            "&:hover": { backgroundColor: "#106CE7" },
                          }}
                        >
                          View
                        </Button>
                        <IconButton
                          onClick={() => {
                            downloadReceipt(row.application_id);
                          }}
                          sx={{
                            "&:hover": { backgroundColor: "#106CE7" },
                          }}
                        >
                          <DownloadIcon
                            sx={{
                              marginRight: "2px",
                              color: "#162B42",
                            }}
                          />
                        </IconButton>
                      </>
                    )}
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <>No Search results</>
          )}
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationData: state.applicationData.allVillageUsersApplications,
  searchResults: state.searchSectionVariables.searchResults,
  currentPage: state.applicationDataTableVariables.currentPage,
  currentDocument: state.currentDocument,
});
const mapDispatchToProps = (dispatch) => {
  return {
    downloadReceipt: (val) => api.downloadReceipt(val, dispatch),
    changePage: (val) => dispatch({ type: "CHANGE_TABLE_PAGE", value: val }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    updateUsername: (text) =>
      dispatch({ type: "UPDATE_USERNAME", value: text }),
    setCurrentUser: () => dispatch({ type: "SET_CURRENT_USER" }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    setAppIdAndStatusToNew: () =>
      dispatch({ type: allEvents.SET_CURRENT_DOCUMENT_STATUS_TO_NEW }),
    getUserApplicationByID: (val, changeNew) =>
      api.getUserApplicationByID(val, dispatch, changeNew),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
    // setSubmitButtonText: (text) => dispatch({ type: 'SET_SUBMIT_BUTTON_TEXT', value: text })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LatestTable);
