import React, { Component } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import A00DocEdit from "../global/A00DocEdit";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class A0_1_1_0_A_V_S_A extends Component {
  render() {
    const { rname, allInnerRoutes } = this.props;
    return (
      <div>
        <A00DocEdit />
        <Outlet />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(A0_1_1_0_A_V_S_A);
