import React, { Component } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import TransactionReport from "../global/TransactionReport";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class A0_1_1_2_A_T_R_A extends Component {
  render() {
    const { rname, allInnerRoutes } = this.props;
    return (
      <div>
        <TransactionReport/>
        <Outlet />
      </div>
    );
  }
}

export default connect(mapStateToProps)(A0_1_1_2_A_T_R_A);