/* eslint-disable react/prop-types */
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { InputAdornment } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { connect } from "react-redux";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PetsIcon from "@mui/icons-material/Pets";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { helpers } from "../../statesandlogics/cnstshelpers";
import { validateAdditionalItems } from "ajv/dist/vocabularies/applicator/additionalItems";
import { vehicleLateFee, petLateFee } from "./AdminApplicationPreview";
class VisitOfficeScreen extends React.Component {
  componentWillUnmount() {
    this.props.resetValidations();
  }
  render() {
    const {
      vehicles,

      pets,
      updateVehicleStickerInfo,
      updatePetStickerInfo,
    } = this.props;
    return (
      <div>
        <div>
          <Grid
            container
            flex
            sx={{
              // margin: "15vh 0",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <Grid item>
              <div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 1,
                      maxWidth: "90vw",
                      width: "90vw",
                      paddingLeft: 1,
                      minWidth: 80,
                      // marginLeft: 10,
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      "@media (width <= 725px)": {
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    {vehicles.map((vehicle, idx) =>
                      vehicle.sticker_code.fee !== null ? (
                        <Button
                          disableRipple
                          sx={{
                            textTransform: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              width: 280,
                              borderRadius: 1,
                              padding: 1,
                              // paddingTop: 0.5,
                              height: 220,
                              // marginRight: 5,
                              marginRight: 3,
                              "@media (max-width: 400px)": {
                                marginRight: 0,
                              },
                              backgroundColor: "#E2F5FB",
                              marginBottom: 1,
                              border: 3,
                              borderRadius: 5,
                              borderColor: "#046E9E",
                            }}
                          >
                            <CardContent sx={{ padding: 0 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <DirectionsCarIcon />
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 20,
                                    fontFamily: "poppins",
                                    fontWeight: 600,
                                    marginBottom: 1,
                                    marginLeft: 2,
                                  }}
                                >
                                  {`${helpers.upperCaseNullHandler(
                                    vehicle.make
                                  )} ${helpers.upperCaseNullHandler(
                                    vehicle.model
                                  )}`}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(
                                    vehicle.plate_number
                                  )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {vehicle.model_year}
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(
                                    vehicle.sticker_code.code_description
                                  )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {"$ " +
                                    helpers.formatFee(
                                      vehicle.late_fee_applied &&
                                        vehicle.prorated_fee_applied
                                        ? (vehicle.sticker_code.fee +
                                            vehicleLateFee) /
                                            2
                                        : vehicle.late_fee_applied
                                        ? vehicle.sticker_code.fee +
                                          vehicleLateFee
                                        : vehicle.prorated_fee_applied
                                        ? vehicle.sticker_code.fee / 2
                                        : vehicle.sticker_code.fee
                                    )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(vehicle.color)}
                                </Typography>
                              </Box>{" "}
                              <Box sx={{ width: "100%", marginTop: "5px" }}>
                                <TextField
                                  size="small"
                                  id="outlined"
                                  // label="Tag Number"
                                  name="sticker_tag_number"
                                  value={vehicle.sticker_tag_number}
                                  variant="outlined"
                                  sx={{ marginBottom: 1, width: "90%" }}
                                  onChange={(e) => {
                                    // setCurrentlyEditingVehicle(idx);
                                    // setCurrentlyEditingVehicleData(e);
                                    updateVehicleStickerInfo(
                                      e,
                                      vehicle.vehicle_id
                                    );
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Tag Number :
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    size="small"
                                    value={vehicle.sticker_tag_expiry}
                                    id="outlined"
                                    label="Tag Expiry"
                                    inputFormat="MM/DD/YYYY"
                                    variant="outlined"
                                    onChange={(val) => {
                                      updateVehicleStickerInfo(
                                        val,
                                        vehicle.vehicle_id,
                                        "tagExpiry"
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField size="small" {...params} />
                                    )}
                                    sx={{
                                      width: "90%",
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        fontSize: 16,
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                {/* <TextField
                                  size="small"
                                  id="standard"
                                  label="Tag Expiry"
                                  name="sticker_tag_expiry"
                                  value={vehicle.sticker_tag_expiry}
                                  variant="standard"
                                  onChange={(e) => {
                                    updateVehicleStickerInfo(
                                      e,
                                      vehicle.vehicle_id,
                                      "tagExpiry"
                                    );
                                  }}
                                /> */}
                              </Box>
                            </CardContent>
                          </Card>
                        </Button>
                      ) : null
                    )}
                    {pets.map((pet, idx) =>
                      pet.sticker_code.fee !== null ? (
                        <Button
                          disableRipple
                          sx={{
                            textTransform: "none",
                            margin: 0,
                            padding: 0,
                          }}
                          // onClick={() => this.props.openSidebar()}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              width: 280,
                              borderRadius: 1,
                              paddingTop: 0.5,
                              height: 220,
                              padding: 1,
                              margin: 0,
                              // marginRight: 5,
                              marginRight: 3,
                              "@media (max-width: 400px)": {
                                marginRight: 0,
                              },
                              backgroundColor: "#F1FFF1",
                              border: 3,
                              borderRadius: 5,
                              borderColor: "#367974",
                              marginBottom: 1,
                            }}
                          >
                            <CardContent sx={{ padding: 0 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <PetsIcon />
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 20,
                                    fontFamily: "poppins",
                                    fontWeight: 600,
                                    marginBottom: 1,
                                    marginLeft: 2,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(pet.name)}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(pet.breed)}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(pet.sex)}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(pet.color)}
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {pet.rabies_certificate_expiry}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    textAlign: "left",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {pet.rabies_certificate_number}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {helpers.upperCaseNullHandler(
                                    pet.sticker_code.code_description
                                  )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3C385E",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: 400,
                                    marginRight: 1,
                                  }}
                                >
                                  {"$ " +
                                    helpers.formatFee(
                                      pet.late_fee_applied &&
                                        pet.prorated_fee_applied
                                        ? (pet.sticker_code.fee + petLateFee) /
                                            2
                                        : pet.late_fee_applied
                                        ? pet.sticker_code.fee + petLateFee
                                        : pet.prorated_fee_applied
                                        ? pet.sticker_code.fee / 2
                                        : pet.sticker_code.fee
                                    )}
                                </Typography>
                              </Box>
                              <Box sx={{ width: "100%", marginTop: "5px" }}>
                                <TextField
                                  size="small"
                                  id="outlined"
                                  value={
                                    pet.sticker_tag_number !== null
                                      ? pet.sticker_tag_number.toUpperCase()
                                      : pet.sticker_tag_number
                                  }
                                  name="sticker_tag_number"
                                  // label="Tag Number"
                                  variant="outlined"
                                  sx={{ marginBottom: 1, width: "90%" }}
                                  onChange={(e) => {
                                    // setCurrentlyEditingPet(idx);
                                    // setCurrentlyEditingPetData(e);
                                    updatePetStickerInfo(e, pet.pet_id);
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Tag Number :
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    size="small"
                                    value={pet.sticker_tag_expiry}
                                    id="standard"
                                    label="Tag Expiry"
                                    inputFormat="MM/DD/YYYY"
                                    variant="standard"
                                    onChange={(val) => {
                                      updatePetStickerInfo(
                                        val,
                                        pet.pet_id,
                                        "tagExpiry"
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField size="small" {...params} />
                                    )}
                                    sx={{
                                      width: "90%",
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        fontSize: 16,
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                {/* <TextField
                                  size="small"
                                  id="standard"
                                  label="Tag Expiry"
                                  name="sticker_tag_expiry"
                                  value={pet.sticker_tag_expiry}
                                  variant="standard"
                                  onChange={(e) => {
                                    updatePetStickerInfo(
                                      e,
                                      pet.pet_id,
                                      "tagExpiry"
                                    );
                                  }}
                                /> */}
                              </Box>
                            </CardContent>
                          </Card>
                        </Button>
                      ) : null
                    )}
                  </Box>
                </Box>

                {/* <Typography variant="body1">
                  Thank you for making changes in your application. <br />
                  Please visit the office to complete the further steps of
                  document verification.
                  <br />
                  Contact Stickney Village Hall during business hours at: <br />
                  6533 W Pershing Rd, Stickney, IL 60402 <br />
                  Phone: (708) 749-4400
                  <br />
                  <br />
                  You can now exit this page
                </Typography> */}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  application_id: state.userVariables.application_id,
  currentDocument: state.currentDocument,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetValidations: () => dispatch({ type: "RESET_VALIDATIONS" }),
    updatePetStickerInfo: (val, id, label) =>
      dispatch({
        type: "UPDATE_PET_STICKER_INFO",
        val: val,
        id: id,
        label: label,
      }),
    updateVehicleStickerInfo: (val, id, label) =>
      dispatch({
        type: "UPDATE_VEHICLE_STICKER_INFO",
        val: val,
        id: id,
        label: label,
      }),
    loadLoginCurrentPage: (text) =>
      dispatch({ type: "LOAD_APPLICATION_LOGIN", value: text }),
    setUserLogin: (val) => dispatch({ type: "SET_USER_LOGIN", value: val }),
    updatePetData: (val) => dispatch({ type: "UPDATE_PET_DATA", value: val }),
    setUser: () => dispatch({ type: "SET_USER" }),
    loadCitizenPage: (val) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: val }),
    setCurrentlyEditingPetData: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET_DATA",
        value: val,
        value2: val2,
      }),
    setCurrentlyEditingVehicleData: (val) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE_DATA",
        value: val,
      }),
    setCurrentlyEditingVehicle: (val, val2, idx) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE",
        value: val,
        value2: val2,
      }),
    setCurrentlyEditingPet: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET",
        value: val,
        value2: val2,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitOfficeScreen);
