import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import ApplicationDataTable from "../global/ApplicationDataTable";
import { Button } from "@mui/material";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import TableCardResponsive from "../global/TableCardResponsive";
import CompiledComponent from '../global/UI/CompiledComponent'
function mapStateToProps(state) {
  return {
    showCitizenApplication: state.appGlobals.showCitizenApplication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showCitizenStickers: (val) => dispatch({type: allEvents.SHOW_CITIZEN_STICKERS , value: val}),
    setApplication: (val) => dispatch({type: allEvents.SHOW_CITIZEN_APPLICATION , value: val})
  };
}

let ForwardCall = () => {
  const currRoute = useLocation();

  useEffect(({showCitizenApplication, setApplication}) => {
    const timerId = setTimeout(() => {
      if (showCitizenApplication && currRoute.pathname === "/villageStickerApplication/Stickers") {
        setApplication(false)
      }
    }, 10);
    return () => clearTimeout(timerId);
  }, [currRoute]);
};

class C1_1_0_0_C_S_A_A extends Component {
  render() {
    const { allInnerRoutes, showCitizenApplication, showCitizenStickers, setApplication } = this.props;
    return (
      <div>
        {/* <ForwardCall showCitizenApplication={showCitizenApplication} setApplication={setApplication} />  */}
        {showCitizenApplication ? (
          <>
          <Outlet />
          </>
        ) : (
          <>
             <CompiledComponent />  
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(C1_1_0_0_C_S_A_A);
