import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import NavBar from "../global/NavBar";
import Footer from "../global/Footer";
import ApplicationLogin from "../global/ApplicationLogin";
import * as h from "../../statesandlogics/cnstshelpers";
import { useNavigate } from "react-router-dom";

let ForwardCall = () => {
  const navigate = useNavigate();
  const currRoute = useLocation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (currRoute.pathname === "/villageStickerApplication") {
        navigate("/villageStickerApplication/Stickers");
      }
    }, 10);
    return () => clearTimeout(timerId);
  }, [navigate, currRoute]);
};

class C1_0_0_0_C_L_P extends Component {
  render() {
    const { allInnerRoutes, isCitizenLoggedIn } = this.props;
    
    return (
      <div>
        <NavBar />
        <Outlet />
        {isCitizenLoggedIn ? <ForwardCall /> : null}
        {isCitizenLoggedIn ? (
          <>
            {/* <NavBar />
            <Outlet /> */}
            User logged in
          </>
        ) : (
          <>
            <ApplicationLogin />
          </>
        )}
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isCitizenLoggedIn: state.appGlobals.isCitizenLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateStateCurrentUserApplicationWithTempValues: () =>
      dispatch({ type: h.allEvents.citizen.LOAD_TEMP_CITIZEN_APPLICATION }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(C1_0_0_0_C_L_P);
