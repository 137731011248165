import React, { Component } from "react";
import { Card, CardContent, Checkbox, Grid, IconButton, Typography, Box } from "@mui/material";
import { Button,  FormControlLabel, TextField} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

class PaymentCards extends Component {
  render() {
    return (
        <Box className="box-sd">
          <Typography
          sx={{
            color: "black",
            fontSize: 28,
           
            fontWeight: 600,
            marginBottom: 1,
            marginLeft: 2,
          }}
        >
          Payment
        </Typography>
        
      
          <Grid container spacing={2}>
            {[...Array(1
            )].map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>

                <div className="checkbox-card-container-sd">
                  <div className="checkbox-container-sd">
                    <Checkbox />
                  </div>
                  <Card className="payment_card_box">
                
                <div className='payment_input_card'>
                    <label>First Name</label>
                    <TextField id="outlined-basic" variant="outlined" size="small" type='email'/>
                </div>
                <div className='payment_input_card'>
                    <label>Last Name</label>
                    <TextField id="outlined-basic" variant="outlined" size="small" type='email'/>
                </div>
                <div className='payment_input_div'>
                    <div  className='payment_inner_div'>
                        <label>Expiry Date</label>
                        <TextField id="outlined-basic" variant="outlined" size="small" type="month"/>
                    </div>
                    <div > 
                        <label>Credit Card Number</label>
                        <TextField id="outlined-basic" variant="outlined" size="small" placeholder='xxxx' type='number'/>
                    </div>
                </div>
            </Card>
                  
                </div>
              </Grid>
              
            ))}
       
      </Grid>
     
      </Box>
    );
  }
}

export default PaymentCards;
