/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable multiline-ternary */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DownloadIcon from "@mui/icons-material/Download";
import PetsIcon from "@mui/icons-material/Pets";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { saveAs } from "file-saver";
import React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import "../../index.css";
import UserCard from "../global/UserCard";
import { helpers } from "../../statesandlogics/cnstshelpers";
import RecordPaymentButton from "./RecordPaymentButton";

export const vehicleLateFee = 20;
export const petLateFee = 5;

class AdminApplicationPreview extends React.Component {
  // componentDidUpdate(prevProps) {
  //   const { currentDocument } = this.props;
  //   console.log(prevProps);
  //   if (prevProps.uploaded_files.length !== this.props.uploaded_files.length) {
  //     this.props.getUploadedDocs(currentDocument.application_id);
  //   }
  // }
  componentDidMount() {
    this.props.closeSidebar(null);
    if (this.props.currentDocument.application_id !== null) {
      this.props.getUploadedDocs(this.props.currentDocument.application_id);
    }
  }
  componentWillUnmount() {
    //this.props.closeSidebar("cardPayment");
    this.props.resetValidations();
  }
  render() {
    const {
      vehicles,
      psApplicationTab,
      vsApplicationTab,
      setCurrentlyEditingPet,
      setCurrentlyEditingVehicle,
      setCurrentlyEditingPetData,
      setCurrentlyEditingVehicleData,
      updateVehicleData,
      updatePetData,
      updateApplication,
      sidebarOpen,
      application_id,
      pets,
      files,
      preview_uploaded_files,
      uploaded_files,
      setPetData,
      showFiles,
      setVehicleData,
      currentDocument,
      removeDocument,
      editDoc,
      postConfirmation,
    } = this.props;

    let vehicleFee = 0;
    let petFee = 0;
    vehicles.map((v) => {
      if (v.late_fee_applied && v.prorated_fee_applied) {
        vehicleFee += (v.sticker_code.fee + 20) / 2;
      } else if (v.late_fee_applied) {
        vehicleFee += v.sticker_code.fee + 20;
      } else if (v.prorated_fee_applied) {
        vehicleFee += v.sticker_code.fee / 2;
      } else {
        vehicleFee += v.sticker_code.fee;
      }
    });

    pets.map((p) => {
      if (p.late_fee_applied && p.prorated_fee_applied) {
        petFee += (p.sticker_code.fee + 5) / 2;
      } else if (p.late_fee_applied) {
        petFee += p.sticker_code.fee + 5;
      } else if (p.prorated_fee_applied) {
        petFee += p.sticker_code.fee / 2;
      } else {
        petFee += p.sticker_code.fee;
      }
    });
    let totalFees = vehicleFee + petFee;
    // let totalFees = [...vehicles, ...pets].reduce(
    //   (sum, item) => sum + item.sticker_code.fee,
    //   0
    // );
    return (
      <div>
        <Dropzone
          onDrop={(e) => this.props.fileDrop(e)}
          onDropAccepted={(e) => showFiles(e)}
          noClick
          noDrag={editDoc.isDocumentViewOnly}
        >
          {({ getRootProps, isDragAccept }) => (
            <div
              style={
                isDragAccept
                  ? {
                      border: "2px dashed #2957A3",
                      backgroundColor: "#2957A312",
                      minHeight: "75vh",
                    }
                  : {}
              }
              {...getRootProps()}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 1,
                }}
              >
                <UserCard />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: 3,
                  // marginRight: "4vw",

                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 1,
                    maxWidth: "90vw",
                    width: "90vw",
                    // paddingLeft: 4,
                    minWidth: 80,
                    marginBottom: "16px",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    "@media (width <= 725px)": {
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <RecordPaymentButton
                      amount={
                        currentDocument.transaction_info.payment_types
                          .length === 0
                          ? totalFees
                          : currentDocument.transaction_info.payment_credit
                      }
                      method={currentDocument.transaction_info.payment_types}
                    />
                  </Box>

                  {vehicles.map((vehicle, idx) =>
                    vehicle.sticker_code.fee !== null ? (
                      <Button
                        disableRipple
                        sx={{
                          textTransform: "none",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            width: 280,
                            borderRadius: 1,
                            padding: 1,
                            paddingTop: 0.5,
                            height: 120,
                            margin: 0,
                            marginRight: 3,
                            "@media (max-width: 400px)": {
                              marginRight: 0,
                            },
                            backgroundColor: "#E2F5FB",
                            marginBottom: 1,
                            border: 3,
                            borderRadius: 5,
                            borderColor: "#046E9E",
                          }}
                        >
                          <CardContent sx={{ padding: 0 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <DirectionsCarIcon />
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 20,
                                  fontFamily: "poppins",
                                  fontWeight: 600,
                                  marginBottom: 1,
                                  marginLeft: 2,
                                }}
                              >
                                {helpers.upperCaseNullHandler(
                                  vehicle.make + " " + vehicle.model
                                )}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(
                                  vehicle.plate_number
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {vehicle.model_year}
                              </Typography>
                              <Typography
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(
                                  vehicle.sticker_code.code_description
                                )}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {"$ " +
                                  helpers.formatFee(
                                    vehicle.late_fee_applied &&
                                      vehicle.prorated_fee_applied
                                      ? (vehicle.sticker_code.fee +
                                          vehicleLateFee) /
                                          2
                                      : vehicle.late_fee_applied
                                      ? vehicle.sticker_code.fee +
                                        vehicleLateFee
                                      : vehicle.prorated_fee_applied
                                      ? vehicle.sticker_code.fee / 2
                                      : vehicle.sticker_code.fee
                                  )}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(vehicle.color)}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Button>
                    ) : null
                  )}
                  {pets.map((pet, idx) =>
                    pet.sticker_code.fee !== null ? (
                      <Button
                        disableRipple
                        sx={{
                          textTransform: "none",
                          margin: 0,
                          padding: 0,
                        }}
                        // onClick={() => this.props.openSidebar()}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            width: 280,
                            borderRadius: 1,
                            height: 120,
                            padding: 1,
                            paddingTop: 0.5,
                            margin: 0,

                            marginRight: 3,
                            "@media (max-width: 400px)": {
                              marginRight: 0,
                            },
                            backgroundColor: "#F1FFF1",
                            marginBottom: 1,
                            border: 3,
                            borderRadius: 5,
                            borderColor: "#367974",
                          }}
                        >
                          <CardContent sx={{ padding: 0 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <PetsIcon />
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 20,
                                  fontFamily: "poppins",
                                  fontWeight: 600,
                                  marginBottom: 1,
                                  marginLeft: 2,
                                }}
                              >
                                {helpers.upperCaseNullHandler(pet.name)}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(pet.breed)}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(pet.sex)}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(pet.color)}
                              </Typography>
                              <Typography
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {pet.rabies_certificate_expiry}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(
                                  pet.rabies_certificate_number
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {helpers.upperCaseNullHandler(
                                  pet.sticker_code.code_description
                                )}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "#3C385E",
                                  fontSize: 16,
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  marginRight: 1,
                                }}
                              >
                                {"$ " +
                                  helpers.formatFee(
                                    pet.late_fee_applied &&
                                      pet.prorated_fee_applied
                                      ? (pet.sticker_code.fee + petLateFee) / 2
                                      : pet.late_fee_applied
                                      ? pet.sticker_code.fee + petLateFee
                                      : pet.prorated_fee_applied
                                      ? pet.sticker_code.fee / 2
                                      : pet.sticker_code.fee
                                  )}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Button>
                    ) : null
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    margin: "0vh 0",
                    display: "flex",
                    // height: "18vh",
                    width: "90vw",
                    flexWrap: "wrap",
                    // padding:1,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    // overflowY: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      margin: 0.5,
                      marginTop: -2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: "inline",
                        fontSize: 18,
                        textAlign: "left",
                      }}
                      color="text.primary"
                    >
                      Files
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {preview_uploaded_files.length === 0
                      ? null
                      : preview_uploaded_files.map((file) => (
                          <>
                            <Box
                              sx={{
                                margin: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                padding: 0,
                              }}
                            >
                              <Card raised sx={{ borderRadius: 2 }}>
                                <CardContent
                                  sx={{
                                    padding: 1,
                                    "&:last-child": {
                                      paddingBottom: 1,
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      flexDirection: "row",
                                      padding: 0,
                                    }}
                                  >
                                    <Tooltip
                                      title={file.filename}
                                      placement="bottom"
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          display: "inline",
                                          fontSize: 13,
                                          textAlign: "left",
                                          width: "80%",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        color="text.secondary"
                                      >
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {file.filename}
                                        </p>
                                      </Typography>
                                    </Tooltip>

                                    <Box>
                                      <IconButton
                                        onClick={
                                          () =>
                                            saveAs(
                                              file.presigned_url,
                                              "filename.pdf"
                                            )
                                          // this.props.downloadFile(
                                          //   file.presigned_url
                                          // )
                                        }
                                      >
                                        <DownloadIcon
                                          fontSize="small"
                                          sx={{ color: "#162B42" }}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </>
                        ))}
                  </Box>
                  {/* <DocumentUpload /> */}
                </Box>
              </Box>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  psApplicationTab: state.appGlobals.stickercardstyles.psApplicationTab,
  vsApplicationTab: state.appGlobals.stickercardstyles.vsApplicationTab,
  currentDocument: state.currentDocument,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
  isUploadSuccessful: state.UploadPageVariables.isUploadSuccessful,
  files: state.UploadPageVariables.uploaded_files,
  preview_uploaded_files: state.UploadPageVariables.preview_uploaded_files,
  uploaded_files: state.UploadPageVariables.uploaded_files,
  editDoc: state.editDoc,
  application_id: state.userVariables.application_id,
  sidebarOpen: state.sidebarOpen,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetValidations: () => dispatch({ type: "RESET_VALIDATIONS" }),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
    showFiles: (val) => dispatch({ type: "SHOW_FILES", value: val }),
    openSidebar: (val) =>
      dispatch({ type: "OPEN_SIDEBAR", value: true, click: val }),
    closeSidebar: (val) =>
      dispatch({ type: "CLOSE_SIDEBAR", value: false, click: val }),
    setPetData: (val, name, petIndex) =>
      dispatch({
        type: "SET_PET_DATA",
        value: val,
        value2: name,
        value3: petIndex,
      }),
    setCurrentlyEditingPetData: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET_DATA",
        value: val,
        value2: val2,
      }),
    setCurrentlyEditingVehicleData: (val) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE_DATA",
        value: val,
      }),
    setCurrentlyEditingVehicle: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE",
        value: val,
        value2: val2,
      }),
    setCurrentlyEditingPet: (val, val2) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET",
        value: val,
        value2: val2,
      }),
    updateVehicleData: () => dispatch({ type: "UPDATE_NEW_VEHICLE_DATA" }),
    updatePetData: (val) => dispatch({ type: "UPDATE_PET_DATA", value: val }),
    postConfirmation: (val) => api.postConfirmation(val, dispatch),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    setVehicleData: (val, vehicleIndex) =>
      dispatch({ type: "SET_VEHICLE_DATA", value: val, value2: vehicleIndex }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    fileDrop: (val) => api.dropCall(val, dispatch),
    uploadDocuments: (val, val1) => api.uploadDocuments(val, val1, dispatch),
    removeDocument: (val, files) => api.removeDocument(val, files, dispatch),
    updateApplication: (val, val2, status) =>
      api.updateApplication(val, val2, status, dispatch),
    downloadFile: (val) => api.downloadFile(val, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminApplicationPreview);
