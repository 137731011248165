import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import NavBar from "../global/NavBar";
import Footer from "../global/Footer";
import CitizenHome from "../global/CitizenHome";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class C0_0_0_0_C_F_P extends Component {
  render() {
    const { rname, allInnerRoutes } = this.props;
    return (
      <div>
        <NavBar />
        <CitizenHome />
        <Outlet />
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(C0_0_0_0_C_F_P);
