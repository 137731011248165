/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import SideDrawer from "../global/SideDrawer";

class NewUserCard extends Component {
  render() {
    const {
      applicationData,
      updateUserFname,
      updateUserLname,
      updateUserEmail,
      updateUserPhone,
      updateUserAddress,
      currentDocument,
      regionsConfig,
      applicationIsNew,
      editDoc,
    } = this.props;
    return (
      <>
        <Card
          raised
          sx={{
            display: "flex",
            // margin:2,
            backgroundColor: "#FFFFFF",
            "@media (max-width: 900px)": {
              maxWidth: "90%",
            },
            // maxWidth: "80%",
            maxWidth: "90vw",
            width: "90vw",
            minWidth: 80,
            borderRadius: 2,
            marginBottom: 2,
            borderBottomLeftRadius: 0,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 20,
              // borderRight: ,
              backgroundColor: "#162B42",
              borderRadius: 2,
              borderBottomRightRadius: 12,
              // objectFit: "contain",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              "@media (max-width: 450px)": {
                flexDirection: "column",
                flexWrap: "unset",
              },
              justifyContent: "space-between",
              flex: 1,
              // flexDirection: "row",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            <CardContent
              sx={{
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  "@media (max-width: 768px)": {
                    flexDirection: "column",

                    // width:"80%"
                  },
                }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{ color: "rgba(0, 0, 0, 0.35)" }}
                />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    color: "#000000",
                    fontSize: 18,
                    fontWeight: 400,
                    marginLeft: 2,
                  }}
                >
                  Name:
                </Typography>
                <Input
                  placeholder="First Name"
                  size="small"
                  name="fname"
                  sx={{
                    marginLeft: "2%",
                    "@media (max-width: 450px)": {
                      width: "75%",
                      marginLeft: "5%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserFname(e.target.value)}
                />
                <Input
                  placeholder="Family Name"
                  size="small"
                  name="fname"
                  sx={{
                    marginLeft: "2%",
                    "@media (max-width: 450px)": {
                      width: "75%",
                      marginLeft: "5%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserLname(e.target.value)}
                />
              </Box>
            </CardContent>
            <CardContent
              sx={{
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  // flexDirection: "row",
                  "@media (max-width: 768px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{ color: "rgba(0, 0, 0, 0.35)", marginLeft: "4%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    color: "#000000",
                    fontSize: 18,
                    fontWeight: 400,
                    marginLeft: 2,
                  }}
                >
                  Phone:
                </Typography>
                <Input
                  placeholder="Phone"
                  size="small"
                  name="phone"
                  pattern="    /(\d{3})(\d{3})(\d{4})/,
                        ($1) $2-$3"
                  value={currentDocument.applicant.phone_number}
                  sx={{
                    marginLeft: "2%",
                    "@media (max-width: 450px)": {
                      width: "75%",
                      marginLeft: "5%",
                    },
                  }}
                  inputProps={{
                    maxLength: 14,
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserPhone(e.target.value)}
                />
              </Box>
            </CardContent>
            <CardContent
              sx={{
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  // flexDirection: "row",
                  "@media (max-width: 768px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <EmailOutlinedIcon
                  justifyContent="center"
                  sx={{ color: "rgba(0, 0, 0, 0.35)", fontSize: 22 }}
                />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    color: "#000000",
                    fontSize: 18,
                    fontWeight: 400,
                    marginLeft: 2,
                  }}
                >
                  Email:
                </Typography>
                <Input
                  placeholder="Email"
                  size="small"
                  name="email"
                  sx={{
                    marginLeft: "2%",
                    "@media (max-width: 450px)": {
                      width: "75%",
                      marginLeft: "5%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserEmail(e.target.value)}
                />
              </Box>
            </CardContent>
            <CardContent
              sx={{
                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  // flexDirection: "row",
                  "@media (max-width: 768px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <HomeWorkOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.35)" }} />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    color: "#000000",
                    fontSize: 18,
                    fontWeight: 400,
                    marginLeft: 2,
                  }}
                >
                  Address:
                </Typography>
                <Input
                  placeholder="Address Line 1"
                  size="small"
                  // fullWidth
                  name="address_line_1"
                  sx={{
                    marginLeft: "4%",
                    width: 300,
                    minWidth: 100,
                    "@media (max-width: 450px)": {
                      width: "70%",
                      marginLeft: "5%",
                      marginTop: "2%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserAddress(e)}
                />
                <Input
                  placeholder="Address Line 2"
                  size="small"
                  // fullWidth
                  name="address_line_2"
                  sx={{
                    marginLeft: "4%",
                    width: 300,
                    minWidth: 100,
                    "@media (max-width: 450px)": {
                      width: "70%",
                      marginLeft: "5%",
                      marginTop: "2%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserAddress(e)}
                />
                {/* </Box> */}
                {/* </CardContent>
                  <CardContent
                    sx={{
                      width: "30%",
                      "@media (max-width: 768px)": {
                        width: "100%",
                      },
                    }}
                  > */}
                {/* <Box
                      display="flex"
                      sx={{
                        "@media (max-width: 768px)": {
                          flexDirection: "column",
                        },
                      }}
                    > */}
                <Input
                  placeholder="City"
                  size="small"
                  // fullWidth
                  name="city"
                  sx={{
                    marginLeft: "4%",
                    width: 150,
                    minWidth: 100,
                    "@media (max-width: 450px)": {
                      width: "70%",
                      marginLeft: "5%",
                      marginTop: "2%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserAddress(e)}
                />
                <Input
                  placeholder="Postal Code"
                  size="small"
                  // fullWidth
                  name="postal_code"
                  sx={{
                    marginLeft: "4%",
                    width: 80,
                    minWidth: 100,
                    "@media (max-width: 450px)": {
                      width: "70%",
                      marginLeft: "5%",
                      marginTop: "2%",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUserAddress(e)}
                />
                {/* <Autocomplete
                        onChange={(e) => updateUserAddress(e)}
                        disablePortal
                        id="combo-box-demo"
                        options={regionsConfig}
                        value={regionsConfig.state_id}
                        sx={{ width: 300 }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.state_id} : {option.full_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                      /> */}
                <FormControl
                  variant="standard"
                  sx={{
                    m: 0,
                    marginLeft: "4%",
                    width: 80,
                    minWidth: 100,
                    "@media (max-width: 450px)": {
                      width: "70%",
                      marginLeft: "5%",
                      marginTop: "2%",
                    },
                    display: "flex",
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    sx={{ color: "#000000", marginTop: -1 }}
                  >
                    State Id
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    // value={vehicle.sticker_code.code_id}
                    // autoWidth
                    label="State Id"
                    name="state_id"
                    onChange={(e) => updateUserAddress(e)}
                    InputProps={{
                      sx: {
                        color: "#000000",
                      },
                    }}
                    inputlabelprops={{
                      style: {
                        color: "#FFFFFF",
                        fontSize: 16,
                      }, // sets the label color to blue
                    }}
                  >
                    {regionsConfig.map((regions) => (
                      <MenuItem value={regions.state_id}>
                        {regions.state_id}: {regions.full_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Box>
        </Card>
        <SideDrawer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationIsNew: state.applicationVariables.applicationIsNew,
  applicationData: state.currentDocument,
  verified: state.userVariables.profileVerified,
  currentDocument: state.currentDocument,
  username: state.userVariables.username,
  regionsConfig: state.regionsConfig,
  currentPage: state.applicationVariables.currentPage,
  editDoc: state.editDoc,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    updateApplicationStatus: (text) =>
      dispatch({ type: "UPDATE_APPLICATION_STATUS", value: text }),
    updateUserFname: (text) =>
      dispatch({ type: "UPDATE_USER_FNAME", value: text }),
    updateUserLname: (text) =>
      dispatch({ type: "UPDATE_USER_LNAME", value: text }),
    updateUserEmail: (text) =>
      dispatch({ type: "UPDATE_USER_EMAIL", value: text }),
    updateUserPhone: (text) =>
      dispatch({ type: "UPDATE_USER_PHONE", value: text }),
    updateUserAddress: (text) =>
      dispatch({ type: "UPDATE_USER_ADDRESS", value: text }),
    verifiedForm: () => dispatch({ type: "VERIFIED_FORM" }),
    postConfirmation: (val) => api.postConfirmation(val, dispatch),
    updateApplication: (val, val2) =>
      api.updateApplication(val, val2, dispatch),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserCard);
