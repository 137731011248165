/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import CloseIcon from '@mui/icons-material/Close'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import PetsIcon from '@mui/icons-material/Pets'
import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LogRocket from 'logrocket'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as api from '../../statesandlogics/ApiCalls/Api'
import addCar from '../../images/addCar.png'
import addPet from '../../images/addPet.svg'
import SideDrawer from './SideDrawer'
import NewUserCard from './NewUserCard'

class NewEditDetails extends Component {
  componentDidMount () {
    const { currentDocument } = this.props
    LogRocket.identify(currentDocument.applicant.email)
  }

  render () {
    const {
      removePetSticker,
      showAlert,
      removeVehicleSticker,
      vehicles,
      showStickerAlert,
      pets,
      updateNewVehicleData,
      updatePetData,
      updateApplication,
      loadApplicationPage,
      updateStep,
      currentDocument,
      application_id,
      userIsAdmin,
      editDoc
    } = this.props


    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media (max-width: 600px)': {
              justifyContent: 'flex-start'
            }
          }}
        >
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <NewUserCard/>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginLeft:"5vw",
            padding:0,
            alignItems: 'flex-start'
            // "@media (max-width: 600px)": {
            //   justifyContent: "flex-start",
            // },
          }}
        >
          <Button
            sx={{
              backgroundColor: '#162B42',
              color:"#FFF",
              padding: '0.7% 3%',
              // margin: '1% 0',
              // marginTop: '2%',
              // marginRight: '5vw',
              '@media (min-width: 1700px)': {
                marginRight: '1%'
              },
              '@media (max-width: 600px)': {
                fontSize: 8,
                marginLeftt: '5vw'
              },
              width: '20%',
              '&:hover': { backgroundColor: '#0C325B' }
            }}
            onClick={(e) => {
              updateStep()
              e.preventDefault()
              updateNewVehicleData('DataAdd')
              updatePetData('dataAdd')
              if (userIsAdmin === true) {
                loadApplicationPage('Upload Documents')
              } else {
                updateApplication(currentDocument, application_id)
                loadApplicationPage('Preview application')
              }
            }}
          >
            Proceed4
          </Button>
        </Box>
        <Box>
          <Box
            sx={
              {
                // display:"flex", flexDirection:"'row",justifyContent:"center",alignItems:"center",flexWrap:"wrap", margin:1
              }
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 3,
                marginLeft: 10,
                '@media (max-width: 400px)': {
                  marginLeft: 1,
                  justifyContent: 'center'
                },
                flexWrap: 'wrap',
                justifyContent: 'flex-start'
              }}
            >
              {vehicles.map((vehicle, idx) =>
            vehicle.sticker_code.fee === null ? null : (
                  <Button
                    disableRipple
                    sx={{
                      textTransform: 'none',
                      margin: 0,
                      padding: 0
                    }}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        width: 280,
                        borderRadius: 1,
                        padding: 2,
                        paddingTop: 0.5,
                        height: 120,
                        // marginRight: 5,
                        margin: 0,
                        marginRight: 5,
                        '@media (max-width: 400px)': {
                          marginRight: 0
                        },
                        backgroundColor: '#E0F6FF',
                        marginBottom: 5
                      }}
                    >
                      <CardContent sx={{ padding: 0, margin: 0, width: '100%' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          {/* <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 3,
                            }}
                          > */}
                          {/* <img
                              src={vehicle}
                              alt="vehicle"
                              style={{ width: 50 }}
                            /> */}
                          <DirectionsCarIcon />
                          {/* </Box> */}
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 20,
                              fontFamily: 'poppins',
                              fontWeight: 600
                            }}
                          >
                            {`${vehicle.make} ${vehicle.model}`}
                          </Typography>
                          <IconButton
                            // sx={{
                            //   position: "absolute",
                            //   "@media (max-width: 400px)": {
                            //     right: 5,
                            //   },
                            //   top: 2,
                            //   right: 40,
                            // }}
                            onClick={(e) => {
                              e.preventDefault()
                              removeVehicleSticker(idx)
                            }}
                          >
                            <CloseIcon />
                            {/* <DeleteIcon/> */}
                          </IconButton>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400

                            }}
                          >
                            {vehicle.plate_number}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400

                            }}
                          >
                            {vehicle.model_year}
                          </Typography>

                          <Typography
                            sx={{
                              width: '100%',
                              // alignItems:"left",
                              // justifyContent:"left",
                              textAlign: 'left',
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400

                            }}
                          >
                            {vehicle.sticker_code.code_description}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400

                            }}
                          >
                            {vehicle.sticker_code.fee}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400

                            }}
                          >
                            {vehicle.color}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Button>
                )
              )}
              {showAlert === true
                ? (
                <Alert
                  severity="warning"
                  variant="filled"
                  onClose={() => {
                    showStickerAlert(false)
                  }}
                  sx={{
                    position: 'absolute',
                    top: 70,
                    right: 0
                  }}
                >
                  Sticker Limit reached!
                </Alert>
                  )
                : null}
              <Button
                disableRipple
                sx={{
                  textTransform: 'none',
                  margin: 0,
                  padding: 0
                }}
                onClick={() => {
                  updateNewVehicleData('initialAdd')
                  this.props.openSidebar('vehicle')
                }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 280,
                    padding: 2,
                    paddingTop: 0.5,
                    height: 120,
                    borderRadius: 1,
                    margin: 0,
                    // marginRight: 5,
                    backgroundColor: '#E4E4E4',
                    marginBottom: 5
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img src={addCar} style={{ color: 'rgba(0,0,0,0.7)' }}alt="Add Vehicle" />
                    {/* <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                        fontSize: 100,
                        marginLeft: 13,
                      }}
                    >
                      +
                    </Typography> */}
                  </Box>
                </Card>
              </Button>
            </Box>
          </Box>
          <Box
          // sx={{display:"flex", flexDirection:"'row",justifyContent:"center",alignItems:"center",flexWrap:"wrap", margin:1}}
          >
            {/* <Box > */}
            {/* </Box> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                // marginTop: 1,
                marginLeft: 10,
                '@media (max-width: 400px)': {
                  marginLeft: 1,
                  justifyContent: 'center'
                },
                flexWrap: 'wrap',
                marginBottom: 1,
                justifyContent: 'flex-start'
              }}
            >
              {pets.map((pet, idx) =>
                pet.sticker_code.fee === null ? null : (
                  <Button
                    disableRipple
                    sx={{
                      textTransform: 'none',
                      margin: 0,
                      padding: 0
                    }}
                    // onClick={() => this.props.openSidebar()}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        width: 280,
                        borderRadius: 1,
                        height: 120,
                        padding: 2,
                        paddingTop: 0.5,
                        margin: 0,
                        // marginRight: 5,
                        marginRight: 5,
                        '@media (max-width: 400px)': {
                          marginRight: 0
                        },
                        backgroundColor: '#edfceb',
                        marginBottom: 5
                      }}
                    >
                      <CardContent sx={{ padding: 0 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <PetsIcon />
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 20,
                              fontFamily: 'poppins',
                              fontWeight: 600
                            }}
                          >
                            {pet.name}
                          </Typography>
                          <IconButton
                            // sx={{
                            //   position: "absolute",
                            //   "@media (max-width: 400px)": {
                            //     right: 5,
                            //   },
                            //   top: 2,
                            //   right: 40,
                            // }}
                            onClick={() => {
                              removePetSticker(pet.petNumber)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.breed}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.sex}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.color}
                          </Typography>
                          <Typography
                            sx={{
                              width: '100%',
                              textAlign: 'left',
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.rabies_certificate_expiry}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: 'left',
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.rabies_certificate_number}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {pet.sticker_code.code_description}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#3C385E',
                              fontSize: 16,
                              fontFamily: 'poppins',
                              fontWeight: 400
                              // marginRight: 1,
                            }}
                          >
                            {"$ "+ Number(pet.sticker_code.fee).toFixed(2)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Button>
                )
              )}
              <Button
                disableRipple
                sx={{
                  textTransform: 'none',
                  margin: 0,
                  padding: 0
                }}
                onClick={(e) => {
                  // setPetIndex(idx);
                  e.preventDefault()
                  updatePetData('initialAdd')
                  this.props.openSidebar('pet')
                }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    width: 280,
                    padding: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 120,
                    paddingTop: 0.5,
                    borderRadius: 1,
                    margin: 0,
                    backgroundColor: '#E4E4E4',
                    marginBottom: 5
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img src={addPet} style={{ width: '30%', height: '30%', color: 'rgba(0,0,0,0.7)' }} alt="Add Pet"/>
                  </Box>
                </Card>
              </Button>
            </Box>
          </Box>
        </Box>

        <SideDrawer />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
  currentDocument: state.currentDocument,
  username: state.username,
  userIsAdmin: state.userIsAdmin,
  showAlert: state.showAlert,
  application_id: state.userVariables.application_id,
  editDoc: state.editDoc,
})

const mapDispatchToProps = (dispatch) => {
  return {
    removePetSticker: (text, val) =>
      dispatch({ type: 'REMOVE_PET_STICKER', value: text, value2: val }),
    removeVehicleSticker: (text, val) =>
      dispatch({ type: 'REMOVE_VEHICLE_STICKER', value: text, value2: val }),
    setVehicleIndex: (val) =>
      dispatch({ type: 'SET_VEHICLE_INDEX', value: val }),
    setPetIndex: (val) => dispatch({ type: 'SET_PET_INDEX', value: val }),
    updateNewVehicleData: (val, val2) =>
      dispatch({ type: 'UPDATE_NEW_VEHICLE_DATA', value: val, value2: val2 }),
    updatePetData: (val) => dispatch({ type: 'UPDATE_PET_DATA', value: val }),
    openSidebar: (val) =>
      dispatch({ type: 'OPEN_SIDEBAR', value: true, click: val }),
    updateStep: () => dispatch({ type: 'UPDATE_STEP' }),
    updateApplication: (val, val2) =>
      api.updateApplication(val, val2, dispatch),
    loadApplicationPage: (page) =>
      dispatch({ type: 'LOAD_APPLICATION_PAGE', value: page }),
    showStickerAlert: (val) =>
      dispatch({ type: 'SHOW_STICKER_ALERT', value: val })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEditDetails)
