/* eslint-disable react/no-unknown-property */
/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
// import Button from '@mui/material/Button'
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
// import { styled } from '@mui/material/styles';
import newAppIcon from "../../images/newAppIcon.png";
import renewAppIcon from "../../images/renewAppIcon.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import buildBusinessAddress from "../../statesandlogics/contactAddress"

const NavigationCard = ({ loadCurrentPage, setApplicationType }) => {
  const businessAddress = buildBusinessAddress();

  const listItems = [
    {
      title: "Click here to apply for a new village sticker",
      desc: "First Time User",
      iconPath: newAppIcon,
      iconAltText: "new application icon",
    },

    {
      title: "Click here to renew your village stickers",
      // desc: "Click here to renew your existing stickers",
      desc: "Renewal applications",
      iconPath: renewAppIcon,
      iconAltText: "renew application icon",
    },
  ];
  return (
    <div sx={{ height: "100vh" }}>
      <Grid
        container
        flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          overflow: "hidden",
          // height:"100vh"
        }}
      >
        <Grid item md={0}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            margin: "10vh 0%",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 900px)": {
              margin: "5vh 0vh",
            },
          }}
        >
          <Card
            sx={{
              borderRadius: "5px",
              boxShadow: "8px 8px 8px 8px rgba(43, 42, 42, 0.1)",
              width: "75%",
              maxWidth: "400px",
              maxHeight: "250px",
              margin: "0% 20%",
              textTransform: "capitalize",
              "@media (max-width: 900px)": {
                margin: "0 25%",
              },
              "@media (max-width: 768px)": {
                width: "60%",
              },
              "@media (min-width: 1700px)": {
                margin: "0 15%",
              },
              // marginLeft: "8%",
              "&:hover": {
                overlay: {
                  transform: "scale(4) translateZ(0)",
                },
                transform: "translateY(-5px) scale(1.005) translateZ(0)",
                cursor: "pointer",
                border: "5px #162B42",
                color: "black",
                // backgroundColor: '#DCE9FF'
              },
            }}
            id={listItems[1].title}
          >
            <Button component={Link} to="/villageStickerApplication">
              <Grid container>
                <Grid item xs={12}>
                  <img
                    src={listItems[1].iconPath}
                    alt={listItems[1].iconAltText}
                    className="newAppIcon"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    sx={{ marginBottom: "10%", fontWeight: "bold" }}
                  >
                    {listItems[1].title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "left",
                    margin: "4%",
                  }}
                >
                  <Typography variant="body4" sx={{ fontWeight: "light" }}>
                    {listItems[1].desc}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Card>
        </Grid>
        <Grid item>
          <div style={{ fontWeight: "bold" }}>Notice</div>
          <Typography
            variant="h7"
            sx={{
              margin: "3% 0%",
              width: "100%",
              fontSize: "12px",
              align: "center",
            }}
          >
            This site is for citizens who already have an existing vehicle
            sticker or pet registration. If you need to acquire a sticker or pet
            registration for the first time, your municipality requires you to
            visit {businessAddress.locationName} to apply.
            <br />
            Please bring these supporting documents.
            <br />
            <br />
            For Vehicles:
            <br />
            - Vehicle Registration
            <br />
            - Driver's License
            <br />
            <br />
            For Pets:
            <br />
            - Rabies Certificate Number
            <br />
            <br />
            {businessAddress.locationName} is located at:
            <br />
            {businessAddress.line1}
            <br />
            {businessAddress.line2}
            <br />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

class CitizenHome extends React.Component {
  render() {
    const { loadCurrentPage, setApplicationType } = this.props;
    return (
      <NavigationCard
        loadCurrentPage={loadCurrentPage}
        setApplicationType={setApplicationType}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  applicationIsNew: state.applicationVariables.applicationIsNew,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadCurrentPage: (text) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: text, apiData: "Nothing" }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CitizenHome);
