import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardContent, Checkbox, Grid, IconButton, Typography, Box } from "@mui/material";
import PetsIcon from "@mui/icons-material/Pets";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

class StickersDoc extends Component {
  render() {
    const {citizenApp} = this.props;
    return (
        <Box className="box-sd">
            <Typography
          sx={{
            color: "black",
            fontSize: 28,
          
            fontWeight: 600,
            marginBottom: 1,
            marginLeft: 2,
          }}
        >
          Stickers
        </Typography>
        <Card className="parent-card-ec">
  <Grid container spacing={2}>
    {citizenApp ? citizenApp.pets.map((pets) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={pets.pet_id}>
        <div className="checkbox-card-container-sd">
          <div className="checkbox-container-sd">
            <Checkbox />
          </div>
          <Card className="card-sd-pets">
            <CardContent sx={{ padding: 0 }}>
              <Box className="stickernameicon-box">
                <PetsIcon />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 20,
                    fontFamily: "poppins",
                    fontWeight: 600,
                    marginBottom: 1,
                    marginLeft: 2,
                  }}
                >
                  {pets.name}
                </Typography>
              </Box>
              <div className="sticker-data-container">
                <div className="sticker-data-column">
                  <div>
                    <Typography>{pets.breed}</Typography>
                  </div>
                  <div>
                    <Typography>{pets.color}</Typography>
                  </div>
                  <div>
                    <Typography>{pets.sex}</Typography>
                  </div>
                </div>
                <div className="sticker-data-column">
                  <div>
                    <Typography>{pets.rabies_certificate_expiry}</Typography>
                  </div>
                  <div>
                    <Typography>{pets.rabies_certificate_number}</Typography>
                  </div>
                  <div>
                    <Typography>{pets.sticker_code.fee}</Typography>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Grid>
    )): null}
        {citizenApp ? citizenApp.vehicles.map((vehicles) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={vehicles.vehicle_id}>
        <div className="checkbox-card-container-sd">
          <div className="checkbox-container-sd">
            <Checkbox />
          </div>
          <Card className="card-sd-vehicles">
            <CardContent sx={{ padding: 0 }}>
              <Box className="stickernameicon-box">
                <DirectionsCarIcon/>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 20,
                    fontFamily: "poppins",
                    fontWeight: 600,
                    marginBottom: 1,
                    marginLeft: 2,
                  }}
                >
                  {vehicles.make}
                </Typography>
              </Box>
              <div className="sticker-data-container">
                <div className="sticker-data-column">
                  <div>
                    <Typography>{vehicles.plate_number}</Typography>
                  </div>
                  <div>
                    <Typography>{vehicles.color}</Typography>
                  </div>
                  <div>
                    <Typography>{vehicles.model}</Typography>
                  </div>
                </div>
                <div className="sticker-data-column">
                  <div>
                    <Typography>{vehicles.model_year}</Typography>
                  </div>
                  <div>
                    <Typography>{vehicles.sticker_code.code_description}</Typography>
                  </div>
                  <div>
                    <Typography>{vehicles.sticker_code.fee}</Typography>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Grid>
    )): null}
  </Grid>
</Card>

        {/* <Card className="parent-card-ec">
      
          <Grid container spacing={2}>
          
            
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} >
                <div className="checkbox-card-container-sd">
                  <div className="checkbox-container-sd">
                    <Checkbox />
                  </div>
                  <Card className="card-sd">
                  <CardContent sx={{ padding: 0 }}>
                    <Box
                      className="stickernameicon-box"
                    >
                      <PetsIcon />
                      <Typography
                        sx={{
                         color: "white",
                          fontSize: 20,
                          fontFamily: "poppins",
                          fontWeight: 600,
                          marginBottom: 1,
                          marginLeft: 2,
                        }}
                      >
                       {CurrentGlobalUIData.pets.name}
                      </Typography>
                    </Box>
                    <div className="sticker-data-container">
        <div className="sticker-data-column">
          <div>
            <Typography>{CurrentGlobalUIData.pets.breed}</Typography>
          </div>
          <div>
            <Typography>{CurrentGlobalUIData.pets.color}</Typography>
          </div>
          <div>
            <Typography>{CurrentGlobalUIData.pets.sex}</Typography>
          </div>
        </div>
        <div className="sticker-data-column">
          <div>
            <Typography>{CurrentGlobalUIData.pets.rabies_certificate_expiry}</Typography>
          </div>
          <div>
            <Typography>{CurrentGlobalUIData.pets.rabies_certificate_number}</Typography>
          </div>
          
          <div>
            <Typography>{CurrentGlobalUIData.pets.sticker_code.fee}</Typography>
          </div>
        </div>
      </div>
                    
                    </CardContent>
                  </Card>
                </div>
              </Grid>
        
       
      </Grid>
      </Card> */}
    
      </Box>
    );
  }
}

const mapStateToProps = (state) =>({
  
});

const mapDispatchToProps = (dispatch) => {
  return{

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StickersDoc);
