/* eslint-disable react/prop-types */
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { connect } from "react-redux";
import { allEvents } from "../../statesandlogics/cnstshelpers";

class AppFlowBreadcrumbStatic extends React.Component {
  render() {
    const { editDoc, setSelectedIdx } = this.props;
    const color = "#162B42";
    const stepIcons = (selIdx) => {
      let lIcns = [
        <ArticleIcon
          sx={
            selIdx === 0
              ? { color: color, transform: "scale(3)" }
              : { color: color, transform: "scale(1.5)" }
          }
        />,
        <AssignmentTurnedInIcon
          sx={
            selIdx === 1
              ? { color: color, transform: "scale(3)" }
              : { color: color, transform: "scale(1.5)" }
          }
        />,
        <FactCheckIcon
          sx={
            selIdx === 2
              ? { color: color, transform: "scale(3)" }
              : { color: color, transform: "scale(1.5)" }
          }
        />,
      ];
      return lIcns;
    };
    let icnspush = {}
    stepIcons(editDoc.selectedIdx).map((c, i) => {icnspush[i+1] = c})
    let v = (prps) => (<div>{icnspush[String(prps.icon)]}</div>)

    return (
      <div>
        <Grid
          flex
          container
          direction={{
            sm: "row",
          }}
          fluid
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Stepper
              activeStep={editDoc.selectedIdx}
              sx={{
                "@media (max-width: 425px)": { transform: "scale(0.7)" },
              }}
            >
              {stepIcons(editDoc.selectedIdx).map((ic, idx) => (
                <Step>
                  <StepLabel
                    StepIconComponent={v}
                    onClick={() => {
                      setSelectedIdx(idx);
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  editDoc: state.editDoc,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedIdx: (idx) => dispatch({ type: allEvents.SET_AFB_SEL_IDX, value: idx }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppFlowBreadcrumbStatic);
