/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import * as api from "./statesandlogics/ApiCalls/Api";
import "./App.css";
import { data } from "./TestData/TestData";
// import LogRocket from "logrocket";
import { Route, Routes } from "react-router-dom";
import * as h from "./statesandlogics/cnstshelpers";

import "./style.css";
import NoDefaultBasePage from "./components/global/NoDefaultBasePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

class App extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      getFeeSchedule,
      loadTestData,
      ApiLocationLoaded,
      loadStickerData,
      loadRegionsData,
      loadAdminIdentity,
      email,
      adminIdentity,
      isLoggedIn,
    } = this.props;

    if (this.props.adminIdentity !== prevProps.adminIdentity) {
      const LogRocket = require("logrocket/setup")({
        sdkServer: localStorage.getItem("logrocketSdkServer"),
        ingestServer: localStorage.getItem("logrocketIngestServer"),
      });
      LogRocket.init(localStorage.getItem("logrocketApp"));
      if (isLoggedIn === true) LogRocket.identify(adminIdentity);
    }

    if (ApiLocationLoaded !== prevProps.ApiLocationLoaded) {
      let date = moment();
      let currentDate = date.format("YYYY-MM-DD");
      getFeeSchedule(currentDate);
      loadTestData(data());
      loadStickerData();
      loadRegionsData();
      loadAdminIdentity();
      this.props.searchApplications(
        "GetAll",
        this.props.searchSectionVariables.limit
      );
    }
  }

  componentDidMount() {
    const {
      getFeeSchedule,
      loadTestData,
      loadApi,
      ApiLocationLoaded,
      loadStickerData,
      loadRegionsData,
      loadAdminIdentity,
      adminIdentity,
      isLoggedIn,
    } = this.props;

    if (ApiLocationLoaded) {
      let date = moment();
      let currentDate = date.format("YYYY-MM-DD");
      getFeeSchedule(currentDate);
      loadTestData(data());
      loadStickerData();
      loadRegionsData();
      loadAdminIdentity();
      this.props.searchApplications(
        "GetAll",
        this.props.searchSectionVariables.limit
      );

      const LogRocket = require("logrocket/setup")({
        sdkServer: localStorage.getItem("logrocketSdkServer"),
        ingestServer: localStorage.getItem("logrocketIngestServer"),
      });
      LogRocket.init(localStorage.getItem("logrocketApp"));
      if (isLoggedIn === true) LogRocket.identify(adminIdentity);
    } else {
      loadApi();
    }
  }
  // componentWillMount() {
  //   localStorage.clear()
  // }
  render() {
    return (
      <>
        <ToastContainer autoClose={3000} />
        <Routes>
          <Route index element={<NoDefaultBasePage />} />
          {h.allInnerRoutes().length > 0
            ? h.allInnerRoutes().map((h1) =>
                h1.allInnerRoutes.length > 0 ? (
                  <Route
                    path={h1.rname}
                    element={h1.cmp(h1.key, h1.rname, h1.allInnerRoutes)}
                  >
                    {h1.allInnerRoutes.map((h2) =>
                      h2.allInnerRoutes.length > 0 ? (
                        <Route
                          path={h2.rname}
                          element={h2.cmp(h2.key, h2.rname, h2.allInnerRoutes)}
                        >
                          {h2.allInnerRoutes.map((h3) =>
                            h3.allInnerRoutes.length > 0 ? (
                              <Route
                                path={h3.rname}
                                element={h3.cmp(
                                  h3.key,
                                  h3.rname,
                                  h3.allInnerRoutes
                                )}
                              >
                                {h3.allInnerRoutes.map((h4) => null)}
                              </Route>
                            ) : (
                              <Route
                                path={h3.rname}
                                element={h3.cmp(
                                  h3.key,
                                  h3.rname,
                                  h3.allInnerRoutes
                                )}
                              />
                            )
                          )}
                        </Route>
                      ) : (
                        <Route
                          path={h2.rname}
                          element={h2.cmp(h2.key, h2.rname, h2.allInnerRoutes)}
                        />
                      )
                    )}
                  </Route>
                ) : (
                  <Route
                    path={h1.rname}
                    element={h1.cmp(h1.key, h1.rname, h1.allInnerRoutes)}
                  />
                )
              )
            : null}
        </Routes>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ApiLocationLoaded: state.ApiLocationLoaded,
  vehicles: state.Vehicles,
  pets: state.Pets,
  email: state.currentDocument.applicant.email,
  adminIdentity: state.appGlobals.adminIdentity,
  isLoggedIn: state.appGlobals.isLoggedIn,
  searchSectionVariables: state.searchSectionVariables,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeSchedule: (val) => api.getFeeSchedule(val, dispatch),
    // setUser: (value) => dispatch({ type: 'SET_USER', value: value }),
    loadAdminIdentity: () => api.loadAdminIdentity(dispatch),
    loadTestData: (val) => dispatch({ type: "LOAD_TESTDATA", value: val }),
    loadApi: () => api.loadApiSetting(dispatch),
    loadStickerData: () => api.loadStickerData(dispatch),
    loadRegionsData: () => api.loadRegionsData(dispatch),
    searchApplications: (text, value, offset) =>
      api.searchApplications(text, value, offset, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
