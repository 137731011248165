/* eslint-disable react/prop-types */
/* eslint-disable multiline-ternary */
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import LogRocket from "logrocket";

let userInput = "";

const LoginButton = ({
  loadApplicationPage,
  applicationIsNew,
  loadCurrentPage,
  setUserLogin,
  cognitoLogin,
  cognitoResponse,
  verifyUser,
  otp,
  forwardLogin,
  isNotValidEmailAddress,
  enterCode,
  usernameError,
  usernameValidityCheck,
  username,
  lvShowCode,
  userInput,
}) => {
  return (
    <Button
      sx={{
        backgroundColor: "#162B42",
        padding: "1% 3%",
        margin: "1% 10%",
        width: "80%",
        "&:hover": { backgroundColor: "#0C325B" },
      }}
      // disabled={isNotValidEmailAddress}
      onClick={(e) => {
        e.preventDefault();
        if (lvShowCode === true && usernameError === false) {
          verifyUser(username, cognitoResponse, otp);
          forwardLogin();
        } else if (lvShowCode === false) {
          usernameValidityCheck(userInput);
          // console.log(usernameError)
          // if (usernameError === false) {
          //   cognitoLogin(userInput);
          // }
        }
      }}
    >
      {lvShowCode ? (
        <Typography sx={{ color: "white" }}>Login</Typography>
      ) : (
        <Typography sx={{ color: "white" }}>Get one time password</Typography>
      )}
    </Button>
  );
};

class ApplicationLogin extends React.Component {
 
  componentDidUpdate(prevProps) {
    if (prevProps.usernameError !== this.props.usernameError) {
      if (this.props.usernameError === false) {
        this.props.cognitoLogin(userInput);
      }
    }
  }
  render() {
    const {
      loadApplicationPage,
      applicationIsNew,
      loadCurrentPage,
      enterCode,
      updateUsername,
      username,
      usernameHelperText,
      usernameValidityCheck,
      usernameError,
      setUserLogin,
      cognitoLogin,
      cognitoResponse,
      verifyUser,
      otp,
      isCitizenLoggedIn,
      history,
      forwardLogin,
      forwardClick,
      email,
      phone_number,
      usernameIsEmail,
      emailError,
      emailHelperText,
      phoneNumberError,
      phoneNumberHelperText,
      usernameType,
    } = this.props;

    if (usernameType === "email") {
      userInput = email;
      LogRocket.identify(email);
    } else {
      userInput = phone_number;
      LogRocket.identify(phone_number);
    }
    let lvShowCode = enterCode;
    // const isNotValidEmailAddress = false;
    return (
      <>
        <Grid
          container
          flex
          sx={{
            margin: "20vh 0",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{
              borderRadius: "5px",
              boxShadow: "0px 0px 8px 8px rgba(43, 42, 42, 0.1)",
              padding: "2% 4%",
              width: "50%",
              "@media (max-width: 900px)": {
                width: "65%",
              },
              "@media (max-width: 625px)": {
                width: "80%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <FormControl>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Typography sx={{ marginRight: 2 }}>
                        {" "}
                        Choose a login method:{" "}
                      </Typography>
                    </FormLabel>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="email"
                      name="usernameType"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                          onChange={(e) => updateUsername(e.target, "setType")}
                        />
                        <FormControlLabel
                          value="phone_number"
                          control={<Radio />}
                          label="Phone Number"
                          onChange={(e) => updateUsername(e.target, "setType")}
                        />
                      </Box>
                    </RadioGroup>
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <div>
              {usernameIsEmail ? (
                <TextField
                  size="small"
                  id="outlined"
                  sx={{ margin: "2% 0", width: "100%" }}
                  name="email"
                  label=" Enter your e-mail address"
                  variant="outlined"
                  onChange={(e) => updateUsername(e.target, "email")}
                  value={email}
                  error={emailError}
                  helperText={emailHelperText}
                  inputProps={{
                    maxLength: 255,
                    pattern: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
                    sx: {
                      color: "#000000",
                    },
                  }}
                />
              ) : (
                <TextField
                  size="small"
                  id="outlined"
                  sx={{ margin: "2% 0", width: "100%" }}
                  name="phone_number"
                  pattern="/(\d{3})(\d{3})(\d{4})/, ($1) $2-$3"
                  label=" Enter your phone number"
                  variant="outlined"
                  value={phone_number}
                  error={phoneNumberError}
                  helperText={phoneNumberHelperText}
                  inputProps={{
                    maxLength: 14,
                    sx: {
                      color: "#000000",
                    },
                  }}
                  onChange={(e) => updateUsername(e.target, "phone")}
                />
              )}
            </div>
            {lvShowCode ? (
              <div>
                <TextField
                  size="small"
                  id="outlined"
                  name="enteredOTP"
                  sx={{ margin: "2% 0", width: "100%" }}
                  label={`Enter the code you received via ${
                    usernameIsEmail ? "email" : "text message"
                  }`}
                  value={otp}
                  variant="outlined"
                  onChange={(e) => updateUsername(e.target, "otp")}
                />
              </div>
            ) : (
              <div>
                <Typography
                  variant="h7"
                  sx={{
                    margin: "3% 0%",
                    width: "100%",
                    fontSize: "12px",
                    align: "center",
                  }}
                >
                  Enter your e-mail address or mobile phone number to sign-in.
                  <br />
                  By providing your phone number, you agree to receive
                  notifications to your mobile phone. Text messaging rates may
                  apply. <br />
                </Typography>
              </div>
            )}
            <Grid
              item
              flex
              sx={{
                margin: "2%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoginButton
                loadApplicationPage={loadApplicationPage}
                applicationIsNew={applicationIsNew}
                loadCurrentPage={loadCurrentPage}
                setUserLogin={setUserLogin}
                cognitoLogin={cognitoLogin}
                cognitoResponse={cognitoResponse}
                verifyUser={verifyUser}
                otp={otp}
                forwardClick={forwardClick}
                isCitizenLoggedIn={isCitizenLoggedIn}
                history={history}
                forwardLogin={forwardLogin}
                isNotValidEmailAddress={usernameError}
                enterCode={enterCode}
                usernameError={usernameError}
                username={userInput}
                lvShowCode={lvShowCode}
                userInput={userInput}
                usernameValidityCheck={usernameValidityCheck}
              ></LoginButton>
              {lvShowCode ? (
                <a href="/villageStickerApplication">
                  Click here to re-enter your email id/phone number
                </a>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  enterCode: state.applicationVariables.enterCode,
  applicationIsNew: state.applicationVariables.applicationIsNew,
  username: state.userVariables.username,
  usernameHelperText: state.userVariables.usernameHelperText,
  usernameError: state.userVariables.usernameError,
  currentDocument: state.currentDocument,
  otp: state.userVariables.enteredOTP,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
  cognitoResponse: state.userVariables.cognitoResponse,
  isUsernameValid: state.userVariables.isUsernameValid,
  isCitizenLoggedIn: state.appGlobals.isCitizenLoggedIn,
  email: state.userVariables.email,
  phone_number: state.userVariables.phone_number,
  usernameIsEmail: state.userVariables.usernameIsEmail,
  emailError: state.userVariables.emailError,
  emailHelperText: state.userVariables.emailHelperText,
  phoneNumberError: state.userVariables.phoneNumberError,
  phoneNumberHelperText: state.userVariables.phoneNumberHelperText,
  usernameType: state.userVariables.usernameType,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationPage: (page, idx) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page, index: idx }),
    setUserLogin: (val) => dispatch({ type: "SET_USER_LOGIN", value: val }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadLoginCurrentPage: (text) =>
      dispatch({ type: "LOAD_APPLICATION_LOGIN", value: text }),
    loadCurrentPage: (text) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: text, apiData: "Nothing" }),
    updateUsername: (text, val) =>
      dispatch({ type: "UPDATE_USERNAME", value: text, value2: val }),
    usernameValidityCheck: (text, val) =>
      dispatch({ type: "USERNAME_VALIDITY_CHECK", value: text }),
    loggedIn: (username) => api.loggedIn(username, dispatch),
    cognitoLogin: (username) => api.cognitoLogin(username, dispatch),
    verifyUser: (username, cognitoResponse, otp) =>
      api.verifyUser(username, cognitoResponse, otp, dispatch),
    forwardLogin: () => dispatch({ type: "FORWARD_LOGIN" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogin);
