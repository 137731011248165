/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import { Box, Button, Typography } from "@mui/material";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import PaymentForm from "./PaymentForm";
import AdminPaymentForm from './AdminPaymentForm'

let stripePromise = null

setTimeout(async ()=>{
  const stripe_pk = localStorage.getItem("stripe_pk");
  stripePromise = await loadStripe(stripe_pk);
},1000)

class AdminCardOnline extends Component {
  componentDidMount() {
    const { getClientSecret, application_id, loginTokens, currentDocument } =
      this.props;
      
    getClientSecret(currentDocument.citizen_user_id, loginTokens);
  }

  render() {
    const {
      clientSecret,
      loadCurrentPage,
      loadApplicationPage,
      setPaymentMode,
      paymentIsOnline,
      updatePaymentStatus,
      updateStep,
      userIsAdmin,
      currentDocument,
    } = this.props;

    const appearance = {
      theme: "night",
      labels: "floating",
      variables: {
        // fontFamily: "poppins",
        spacingUnit: "3px",
        borderRadius: "4px",
        // See all possible variables below
      },
    };

    const options = {
      clientSecret,
      appearance,
    };

    return (
      <div>
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "poppins", color: "#FFF" }}
                  >
                    Card Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2,
                      marginBottom: 4,
                    }}
                  >
                    <PaymentOutlinedIcon
                      sx={{ color: "#FFF", fontSize: 100 }}
                    />
                  </Box>
                  <AdminPaymentForm stripe={stripe} elements={elements} />
                </Box>
              )}
            </ElementsConsumer>
          </Elements>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cvc: state.PaymentPageVariables.cvc,
  expiry: state.PaymentPageVariables.expiry,
  focus: state.PaymentPageVariables.focus,
  fname: state.PaymentPageVariables.fname,
  lname: state.PaymentPageVariables.lname,
  number: state.PaymentPageVariables.number,
  username: state.userVariables.username,
  currentDocument: state.currentDocument,
  clientSecret: state.PaymentPageVariables.clientSecret,
  application_id: state.userVariables.application_id,
  loginTokens: state.userVariables.loginTokens,
  userIsAdmin: state.userIsAdmin,
  paymentIsOnline: state.applicationVariables.paymentIsOnline,
  ApiLocationLoaded: state.ApiLocationLoaded,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getClientSecret: (application_id, loginTokens) =>
      api.getClientSecret(application_id, loginTokens, dispatch),
    setPaymentMode: (text) =>
      dispatch({ type: "SET_PAYMENT_MODE", value: text }),
    updateApplicationStatus: (text) =>
      dispatch({ type: "UPDATE_APPLICATION_STATUS", value: text }),
    updatePaymentStatus: (text) =>
      dispatch({ type: "UPDATE_PAYMENT_STATUS", value: text }),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCardOnline);
