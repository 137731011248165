import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import * as api from "../../statesandlogics/ApiCalls/Api";
import Dropzone from "react-dropzone";

export class TransactionReport extends Component {
  componentWillUnmount() {
    this.props.setTransactionReportDate("");
  }
  render() {
    const {
      transactionReportDate,
      generateTransactionReport,
      setTransactionReportDate,
      setTransactionReportEndDate,
      setGenerateReportMode,
      transactionReportMode,
    } = this.props;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "#fff",
              // border: "2px solid #000",
              boxShadow: 4,
              p: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              How would you like to Generate Transaction Report?
            </Typography>
            {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              > */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 2,
              }}
            >
              <Chip
                label="Date Range"
                variant="outlined"
                onClick={() => {
                  setGenerateReportMode("range");
                }}
                sx={{
                  backgroundColor:
                    transactionReportMode === "range" ? "#1a4ea3 " : "#fff",
                  color: transactionReportMode === "range" ? "#FFF " : "#000",
                  "&:hover": {
                    backgroundColor:
                      transactionReportMode === "range"
                        ? "#106ce7 !important"
                        : "#EFEFEF !important",
                  },
                  marginRight: 2,
                }}
              />
              <Chip
                label="Single Date"
                variant="outlined"
                onClick={() => {
                  setGenerateReportMode("single");
                }}
                sx={{
                  backgroundColor:
                    transactionReportMode === "single" ? "#1a4ea3" : "#fff",
                  color: transactionReportMode === "single" ? "#FFF " : "#000",
                  "&:hover": {
                    backgroundColor:
                      transactionReportMode === "single"
                        ? "#106ce7 !important"
                        : "#EFEFEF !important",
                  },
                  marginLeft: 2,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {transactionReportMode === "single" ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 1,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="large"
                        id="standard"
                        label="Choose Date"
                        value={transactionReportDate.date}
                        inputFormat="MM/DD/YYYY"
                        variant="standard"
                        onChange={(val) => setTransactionReportDate(val)}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                generateTransactionReport(
                                  transactionReportDate.date,
                                  null
                                );
                              }
                            }}
                            {...params}
                            style={{
                              width: "150px",
                              color: "#2f67a3",
                              "@media (max-width: 1000px)": {
                                width: "100px",
                                marginLeft: "10px",
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="generate-report-button"
                      onClick={(e) => {
                        generateTransactionReport(
                          transactionReportDate.date,
                          null
                        );
                      }}
                    >
                      Generate
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="large"
                          id="standard"
                          label="From"
                          value={transactionReportDate.date}
                          inputFormat="MM/DD/YYYY"
                          variant="standard"
                          onChange={(val) => setTransactionReportDate(val)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  generateTransactionReport(
                                    transactionReportDate.date,
                                    transactionReportDate.endDate
                                  );
                                }
                              }}
                              {...params}
                              style={{
                                width: "150px",
                                color: "#2f67a3",
                                paddingRight: 2,
                                "@media (max-width: 1000px)": {
                                  width: "100px",
                                  marginLeft: "10px",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="large"
                          id="standard"
                          label="To"
                          value={transactionReportDate.endDate}
                          inputFormat="MM/DD/YYYY"
                          variant="standard"
                          onChange={(val) => setTransactionReportEndDate(val)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  generateTransactionReport(
                                    transactionReportDate.date,
                                    transactionReportDate.endDate
                                  );
                                }
                              }}
                              {...params}
                              style={{
                                width: "150px",
                                color: "#2f67a3",
                                paddingLeft: 2,
                                "@media (max-width: 1000px)": {
                                  width: "100px",
                                  marginLeft: "10px",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 1,
                    }}
                  >
                    <Button
                      className="generate-report-button"
                      onClick={(e) => {
                        generateTransactionReport(
                          transactionReportDate.date,
                          transactionReportDate.endDate
                        );
                      }}
                    >
                      Generate
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {/* </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      > */}

            {/* </Box> */}
          </Box>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionReportDate: state.transactionReportDate,
  transactionReportMode: state.transactionReportMode,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setGenerateReportMode: (val) =>
      dispatch({ type: "SET_GENERATE_REPORT_MODE", val: val }),
    setTransactionReportDate: (val) =>
      dispatch({ type: "SET_TRANSACTION_REPORT_DATE", val: val }),
    setTransactionReportEndDate: (val) =>
      dispatch({ type: "SET_TRANSACTION_REPORT_END_DATE", val: val }),
    generateTransactionReport: (val1, val2) =>
      api.generateTransactionReport(val1, val2, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReport);
