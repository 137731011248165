import React, { Component } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import NavBar2 from "../global/NavBar2";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class A0_2_0_0_A_M_U extends Component {
  render() {
    const { rname, allInnerRoutes } = this.props;
    return (
      <div>
        <NavBar2 />
        <Outlet />
      </div>
    );
  }
}

export default connect(mapStateToProps)(A0_2_0_0_A_M_U);
