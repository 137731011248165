/* eslint-disable react/prop-types */
/* eslint-disable multiline-ternary */
import Grid from '@mui/material/Grid'
import React from 'react'
import { connect } from 'react-redux'

class ExitScreen extends React.Component {
  render () {
    const { paymentIsOnline } = this.props
    return (
      <div>
        {paymentIsOnline ? (
          <div>
            <Grid
              container
              flex
              sx={{
                margin: '15vh 0',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                overflow: 'hidden'
              }}>
              <Grid item>
                <div style={{ fontWeight: 'bold' }}>Payment Processed</div>
                <div>
                  <br />
                  Thank you for renewing this year. <br />
                  The payment will show up on your card as “QUICKET-GOV*” <br />
                  You will receive the documents to the address on-file. <br />
                  Please contact Stickney Village Hall during business hours at:
                  <br />
                  6533 W Pershing Rd, Stickney, IL 60402 <br />
                  Phone: (708) 749-4400
                  <br />
                  <br />
                  You can now exit this page
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  sx={{
                    marginTop: "2%",
                    backgroundColor: "#162B42",
                    padding: "1% 3%",

                    margin: "1% 25%",
                    width: "30%",
                    "&:hover": { backgroundColor: "#0C325B" },
                  }}>

                  Print receipt
                </Button>
              </Grid> */}
            </Grid>
          </div>
        ) : (
          <div>
            <Grid
              container
              flex
              sx={{
                margin: '15vh 0',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}>
              <Grid item>
                <div style={{ fontWeight: 'bold' }}>
                  Submit in person-payment
                </div>
                <div style={{ fontWeight: 'bold', color: 'red', fontSize: 60 }}>
                  REFERENCE NUMBER NEEDS TO BE ADDED HERE
                </div>
                <div>
                  <br />
                  Thank you for renewing this year. <br />
                  The payment will need to submitted in-person or over phone.
                  <br />
                  You will receive the documents to the address on-file. <br />
                  Please contact Stickney Village Hall during business hours at:
                  <br />
                  6533 W Pershing Rd, Stickney, IL 60402 <br />
                  Phone: (708) 749-4400
                  <br />
                  <br />
                  You can now exit this page
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  sx={{
                    marginTop: "2%",
                    backgroundColor: "#162B42",
                    padding: "1% 3%",

                    margin: "1% 25%",
                    width: "30%",
                    "&:hover": { backgroundColor: "#0C325B" },
                  }}>

                  Print receipt
                </Button>
              </Grid> */}
            </Grid>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  paymentIsOnline: state.applicationVariables.paymentIsOnline
})

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentMode: (text) =>
      dispatch({ type: 'SET_PAYMENT_MODE', value: text })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitScreen)
