let AppStyles = {
  AppBackGrounds: {
    Apg1: "#162B42",
  },
  AppForeGrounds: {
    AfgWhite: "#000000",
  },
  AppFontFamilies: {
    Fmly1: "Arimo",
  },
};
export const SXs = {
  Footer: {
    SX1: {
      // position: "absolute",
      marginTop: 10,
      bottom: 0,
      left: 0,
      right: 0,
      width: "auto",
      height: "auto",
      display: "block",
    },
    SX2: { flexGrow: 1, overflow: "hidden" },
    SX3: {
      background: AppStyles.AppBackGrounds.Apg1,
      padding: "0.5%",
      height: "auto",
      width: "100vw",
      flexGrow: 1,
      overflow: "hidden",
      fontSize: "15px",
      "@media (max-width: 900px)": {
        fontSize: "10px",
      },
    },
    SX4: {
      fontFamily: AppStyles.AppFontFamilies.Fmly1,
      margin: "0% 0% 0% 0%",
      fontSize: "12px",
      color: "#FFF !important"
    },
  },
  NavBar: {
    SX1: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      flexWrap: "flex-wrap",
    },
    SX2: { background: "#162B42" },
    SX3: {
      width: "10%",
      "@media (max-width: 900px)": {
        width: "20%",
      },
      height: "auto",
      margin: "1%",
    },
    SX4: {
      display: "flex",
      textAlign: "center",
      m:2,
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
    SX5: { color: "white" },
    SX6: {},
    SX7: {},
    SX8: {},
    SX9: {},
  },
  DrawerComponent: {
    SX1: {
      background: "#162B42",

      margin: "0",
      height: "100vh",
    },
    SX2: {
      marginTop: "7%",
    },
    SX3: {
      marginTop: "7%",
    },
    SX4: {
      "@media (min-width: 900px)": {
        display: "none",
      },
      background: "#162B42",
    },
    SX5: { marginLeft: "auto", color:"#fff" },
  },
  AdminLogin: {
    SX1: {
      margin: "20vh 0",
      justifyContent: "center",
      alignItems: "center",
    },
    SX2: {
      borderRadius: "5px",
      boxShadow: "8px 8px 8px 8px rgba(43, 42, 42, 0.1)",
      padding: "2% 4%",
      width: "50%",
      "@media (max-width: 900px)": {
        width: "75%",
      },
    },
    SX3: { margin: "2% 0", width: "100%" },
    SX4: {
      margin: "2%",
      justifyContent: "center",
      alignItems: "center",
    },
    SX5: {
      backgroundColor: "#162B42",
      padding: "1% 3%",

      margin: "1% 10%",
      width: "80%",
      "&:hover": { backgroundColor: "#0C325B" },
    },
  },
};
