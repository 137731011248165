import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { Card, Pagination } from "@mui/material";
import { Button, Typography } from "@mui/material";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import { Link } from "react-router-dom";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";


const toRoute = "ReviewingApplication";
class TableCardResponsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1, // current page number
      rowsPerPage: 5, // number of rows to show per page
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  render() {
    const {
      getUserApplicationByID,
      getUploadedDocs,
      showApplication,
      searchResults,
      setAppIdAndStatusToNew,
    } = this.props;
    const { page, rowsPerPage } = this.state;
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return (
      <>
        <Box className="fiter_items">
          <Button>
            <FilterAltOffIcon /> Filter by Status
          </Button>
          <Button>
            <FilterAltOffIcon /> Filter by Renewal
          </Button>
        </Box>
        <Box>
          {searchResults.length > 0 ? (
            <>
              {searchResults.map((row) => (
                <Card className="stickerstablecard">
                  {/* <div className="contentstickerdata">
                    <label className="labelstickerdata">
                      {" "}
                      Application Number{" "}
                    </label>
                    <label> : </label>
                    <label className="valuestickerdata">
                      {row.application_id}
                    </label>
                  </div> */}
                  <div className="contentstickerdata">
                    <label className="labelstickerdata"> First Name </label>
                    <label> : </label>
                    <label className="valuestickerdata">{row.first_name}</label>
                  </div>
                  <div className="contentstickerdata">
                    <label className="labelstickerdata"> Family Name </label>
                    <label> : </label>
                    <label className="valuestickerdata">
                      {row.family_name}
                    </label>
                  </div>
                  <div className="contentstickerdata street_address">
                    <label className="labelstickerdata"> Street Address </label>
                    <label> : </label>
                    <label
                      className="valuestickerdata street_para"
                      style={{ textOverflow: "ellipsis" }}
                      // title={row.address_line_1 + " " + row.address_line_2}
                    >
                      {row.address_line_1 + " "}{" "}
                      {row.address_line_2 === null ||
                      row.address_line_2 === "null"
                        ? ""
                        : row.address_line_2}
                    </label>
                  </div>
                  {/* <div className="contentstickerdata">
                    <label className="labelstickerdata"> Fee Category </label>
                    <label> : </label>
                    <label className="valuestickerdata">
                      {row.fee_categories[0]}{" "}
                    </label>
                  </div> */}
                  <div className="contentstickerdata">
                    <label className="labelstickerdata"> Total Fee </label>
                    <label> : </label>
                    <label className="valuestickerdata">
                      {" "}
                      {"$ " + Number(row.total_fee).toFixed(2)}{" "}
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {row.status === "Fulfilled" ? (
                        <div class="fulfilled-label">Fulfilled</div>
                      ) : row.status === "Rejected" ? (
                        <div class="rejected-label">Rejected</div>
                      ) : (
                        <div class="pending-label">{row.status}</div>
                      )}
                    </div>

                    <div className="contentstickerdata">
                      {row.status !== "Fulfilled" ? (
                        <Button
                          className="labelstickerdatabutton"
                          component={Link}
                          onClick={() => {
                            showApplication(true);
                            getUserApplicationByID(row.application_id);
                            getUploadedDocs(row.application_id);
                          }}
                          to={toRoute}
                        >
                          View Application
                        </Button>
                      ) : (
                        <>
                          {" "}
                          <Button
                            className="labelstickerdatabutton"
                            component={Link}
                            onClick={() => {
                              showApplication(true);
                              getUserApplicationByID(row.application_id);
                              getUploadedDocs(row.application_id);
                            }}
                            to={toRoute}
                          >
                            View Application
                          </Button>
                          <Button
                            className="labelstickerdatabuttonr"
                            component={Link}
                            onClick={() => {
                              showApplication(true);
                              getUserApplicationByID(
                                row.application_id,
                                setAppIdAndStatusToNew
                              );
                            }}
                            to={toRoute}
                          >
                            Renewal or new
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <>No Search results</>
          )}
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  applicationData: state.applicationData.allVillageUsersApplications,
  searchResults: state.searchSectionVariables.searchResults,
  currentPage: state.applicationDataTableVariables.currentPage,
  currentDocument: state.currentDocument,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (val) => dispatch({ type: "CHANGE_TABLE_PAGE", value: val }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    updateUsername: (text) =>
      dispatch({ type: "UPDATE_USERNAME", value: text }),
    setCurrentUser: () => dispatch({ type: "SET_CURRENT_USER" }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    setAppIdAndStatusToNew: () =>
      dispatch({ type: allEvents.SET_CURRENT_DOCUMENT_STATUS_TO_NEW }),
    getUserApplicationByID: (val, changeNew) =>
      api.getUserApplicationByID(val, dispatch, changeNew),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
    // setSubmitButtonText: (text) => dispatch({ type: 'SET_SUBMIT_BUTTON_TEXT', value: text })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCardResponsive);
