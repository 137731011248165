import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar1 from "../global/NavBar1";
import NavBar2 from "../global/NavBar2";
import SearchSection from "../global/SearchSectionNew";
import PaginationTool from "../global/PaginationTool";
import TableCardResponsive from "../global/TableCardResponsiveNew";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import LatestTable from "../global/LatestTable"
function mapStateToProps(state) {
  return {
    showApplication: state.appGlobals.showApplication,
    isLoggedIn: state.appGlobals.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShowApplication : (val) => dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val })
  };
}

let ForwardCall = ({setShowApplication}) => {
  const navigate = useNavigate();
  const currRoute = useLocation();

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (currRoute.pathname === "/villageStickerApplicationAdmin/Applications") {
        setShowApplication(false)
      }
    }, 10);
    return () => clearTimeout(timerId);
  }, [navigate, currRoute, setShowApplication]);
};
class A0_1_0_0_A_S_A_A extends Component {
  render() {
    const { rname, allInnerRoutes, isLoggedIn, setShowApplication, showApplication } = this.props;
    return (
      <div>
        {isLoggedIn ? <ForwardCall setShowApplication={setShowApplication} /> : null}
        {showApplication ? (
          <>
            <NavBar2 />
            <Outlet />
          </>
        ) : (
          <div>
            <NavBar1 />
            <SearchSection />
            {/* <PaginationTool /> */}
            {/* <ApplicationDataTable /> */}
            {/* <TableCardResponsive /> */}
            <LatestTable/>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(A0_1_0_0_A_S_A_A);
