import { helpers } from "./cnstshelpers";

/* eslint-disable array-callback-return */
export const setClientSecret = (newstate, action) => {
  newstate.PaymentPageVariables.clientSecret = action.value;
};

export const updateTransactionDetails = (newstate, val) => {
  const { name, value } = val.target;
  newstate.TransactionVariables[name] = value;
};

export const updateStickerAppStatus = (newstate, val) => {
  newstate.currentDocument.status = val;
};

export const setUser = (newstate) => {
  newstate.userIsAdmin = !newstate.userIsAdmin;
  if (newstate.userIsAdmin === true) {
    newstate.citizenViewVariables.currentPage = "First screen";
  } else {
    newstate.leftSidebarVariables.currentPage = "Home";
  }
  return newstate;
};

export const showFiles = (newstate, value) => {
  const files = [...newstate.UploadPageVariables.uploaded_files];
  value.map((v) => files.push(helpers.JPJS(v.name)));
  newstate.UploadPageVariables.showFiles = true;
  newstate.UploadPageVariables.uploaded_files = files;
};

export const setUserLogin = (newstate, value) => {
  newstate.userVariables.isLoggedIn = value;
};

export const setProfileMenu = (newstate, idx, value) => {
  if (idx === "click") {
    newstate.userVariables.profileMenu.anchorEl = value.currentTarget;
  } else {
    newstate.userVariables.profileMenu.anchorEl = null;
  }
};

export const updateApplicationStatus = (newstate, value) => {
  newstate.applicationData.allVillageUsersApplications.map((application) => {
    if (application.userID === value) {
      application.currentStickerApplications.PaymentStatus = "PaymentComplete";
      application.currentStickerApplications.PaymentStatusText =
        "Payment Complete";
    }
  });
};

export const updateDocumentStatus = (newstate, value) => {
  newstate.applicationData.allVillageUsersApplications.map((application) => {
    if (application.userID === value) {
      application.currentStickerApplications.DocumentStatus =
        "DocumentsReadyForReview";
      application.currentStickerApplications.DocumentStatusText =
        "Documents Ready For Review";
    }
  });
};

export const updatePaymentStatus = (newstate, value) => {
  newstate.currentDocument.PaymentStatus = value;
};

export const setPetData = (newstate, val, value2) => {
  const index = newstate.petIndex;
  newstate.applicationVariables.isDataChanged = true;
  const petData = newstate.currentDocument.pets;
  if (value2 === "rabiesExpiry") {
    petData[index].rabies_certificate_expiry = val.format("YYYY-MM-DD");
  } else {
    const { name, value } = val.target;
    if (name === "feeCategory") {
      petData[index].sticker_code.code_id = parseInt(value);
      newstate.stickersConfig.map((sticker) => {
        if (sticker.code_id === parseInt(value)) {
          petData[index].sticker_code.fee = parseInt(sticker.fee);
          // petData[index].sticker_code.formatted_fee =
          //   "$" +
          //   parseInt(sticker.fee)
          //     .toFixed(2)
          //     .replace(/\d(?=(\d{3})+\.)/g, "$&,");
          petData[index].sticker_code.code_description =
            sticker.code_description;
          petData[index].sticker_code.code_type = sticker.code_type;
          // if (name === "fee")
          // {
          //   petData[index].sticker_code.fee ="$" + petData[index].sticker_code.fee.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
          // }
        }
      });
    } else {
      if (name === "name") {
        petData[index][name] = "";
        petData[index][name] = value;
      } else {
        petData[index][name] = value;
      }
    }
  }
  newstate.currentDocument.pets = petData;
  return newstate;
};

export const updatePetFeeCategory = (newstate, value) => {
  const petData = newstate.currentDocument.pets;
  petData.map((vehicle) => {
    if (vehicle.pet_id === null) {
      vehicle.sticker_code.code_id = value;
    }
  });
};

export const updatePetData = (newstate, value) => {
  if (value === "initiaAdd") {
    newstate.applicationVariables.isDataChanged = true;
  }
  const petData = [...newstate.currentDocument.pets];
  if (petData.length > 25) {
    newstate.showAlert = true;
  }
  if ((value === "initialAdd" || petData.length === 0) && petData.length < 25) {
    petData.push(
      helpers.JPJS({
        pet_id: null,
        name: "Name",
        breed: "breed",
        color: "color",
        sex: "sex",
        rabies_certificate_number: "rabies cert no",
        rabies_certificate_expiry: null,
        sticker_code: {
          code_id: null,
          code_description: "",
          fee: 0,
          code_type: "",
        },
      })
    );
    newstate.petIndex = petData.length - 1;
  }
  newstate.currentDocument.pets = petData;
  return newstate;
};

// export const addPet = (newstate, value) => {
//   let newstate = { ...oldstate };
//   let a = newstate.applicationData.allVillageUsersApplications;
//   a.map((app) => {
//     if (app.userID === newstate.userVariables.username) {
//       let arr = [...newstate.currentDocument.currentStickerApplications.Pets];
//       arr.push(value);
//       newstate.currentDocument.currentStickerApplications.Pets = arr;
//     }
//   });
//   return newstate;
// };

export const removeVehicleSticker = (newstate, value) => {
  // if (value >= 0 && value < newstate.currentDocument.vehicles.length) {
  newstate.currentDocument.vehicles = newstate.currentDocument.vehicles.filter(
    (i) => i.vehicle_id !== value
  );
  // }
  return newstate;
};

export const removePetSticker = (newstate, value, value2) => {
  // if (value >= 0 && value < newstate.currentDocument.pets.length) {
  newstate.currentDocument.pets = newstate.currentDocument.pets.filter(
    (i) => i.pet_id !== value
  );
  // }
  return newstate;
};

// export const addVehicle = (newstate, value) => {
//   let newstate = { ...oldstate };
//   let a = newstate.applicationData.allVillageUsersApplications;
//   a.map((app) => {
//     if (app.userID === newstate.userVariables.username) {
//       let arr = [
//         ...newstate.currentDocument.currentStickerApplications.Vehicles,
//       ];
//       arr.push(value);
//       newstate.currentDocument.currentStickerApplications.Vehicles = arr;
//     }
//   });
//   return newstate;
// };

export const updateUserFname = (newstate, value) => {
  // newstate.applicationVariables.isDataChanged = true;
  // const ALPHA_REGEX = /^[a-zA-Z ]+$/;
  // if (value !== "" && !ALPHA_REGEX.test(value)) {
  //   return;
  // }
  newstate.currentDocument.applicant.first_name =
    helpers.upperCaseNullHandler(value);
};

export const updateUserLname = (newstate, value) => {
  newstate.applicationVariables.isDataChanged = true;
  // const ALPHA_REGEX = /^[a-zA-Z ]+$/;
  // if (value !== "" && !ALPHA_REGEX.test(value)) {
  //   return;
  // }
  newstate.currentDocument.applicant.family_name =
    helpers.upperCaseNullHandler(value);
};

export const updateUserEmail = (newstate, value) => {
  // newstate.applicationVariables.isDataChanged = true;
  newstate.currentDocument.applicant.email =
    helpers.upperCaseNullHandler(value);
};
export const updateUserPhone = (newstate, value) => {
  // oldstate.applicationVariables.isDataChanged = true;
  const digitsOnly = value.replace(/\D/g, "");
  const formattedNumber = digitsOnly.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  newstate.currentDocument.applicant.phone_number = formattedNumber;
};
export const updateUserAddress = (newstate, val) => {
  newstate.applicationVariables.isDataChanged = true;
  const { name, value } = val.target;
  if (name === "postal_code") {
    // const postalRegex = /^\d{0,6}$/;
    // if (value !== "" && !postalRegex.test(value)) {
    //   return;
    // }
  }
  if (name === "state_id") {
    newstate.currentDocument.applicant.address.state.state_id = value;
  } else {
    newstate.currentDocument.applicant.address[name] =
      helpers.upperCaseNullHandler(value);
  }
};

export const updateUsername = (newstate, action) => {
  if (action.value2 === "setType") {
    newstate.userVariables.usernameIsEmail =
      action.value.value === "email" ? true : false;
    newstate.userVariables.usernameType =
      action.value.value === "email" ? "email" : "phone_number";
  }
  if (action.value.name === "email") {
    newstate.userVariables.email = action.value.value;
    newstate.userVariables.phone_number = "";
    newstate.userVariables.phoneNumberError = false;
    newstate.userVariables.phoneNumberHelperText = "";
  } else if (action.value.name === "phone_number") {
    const digitsOnly = action.value.value.replace(/\D/g, "");
    const formattedNumber = digitsOnly.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    newstate.userVariables.phone_number = formattedNumber;
    newstate.userVariables.email = "";
    newstate.userVariables.emailError = false;
    newstate.userVariables.emailHelperText = "";
  } else {
    newstate.userVariables[action.value.name] = action.value.value;
  }
  return newstate;
};

export const loadAdminHome = (newstate) => {
  newstate.adminViewVariables.isLoggedIn = true;
  newstate.userIsAdmin = true;
  newstate.citizenViewVariables.currentPage = "First screen";
};

export const updateAdminUsername = (newstate, value) => {
  newstate.adminViewVariables.username = value;
};

export const updateAdminPassword = (newstate, value) => {
  newstate.adminViewVariables.password = value;
};

export const setOfficerName = (newstate, value) => {
  newstate.leftSidebarVariables.username = value;
};

export const setCurrentPage = (newstate, action) => {
  const flow = ["Home", "Preview", "Upload Document", "Confirmation"];

  if (action.index !== null && action.index < 4) {
    newstate.adminViewVariables.currentPageIndex = action.index;
    newstate.leftSidebarVariables.currentPage = flow[action.index];
  } else {
    const idx = flow.indexOf(action.value);
    newstate.adminViewVariables.currentPageIndex = idx;
    newstate.leftSidebarVariables.currentPage = action.value;
  }
  if (action.value === "Preview") {
    newstate.currentDocument = action.apiData;
    const username = newstate.userVariables.username;
    const a = newstate.applicationData.allVillageUsersApplications;
    a.map((app, index) => {
      if (app.userID === username) {
        newstate.currentDocument = a[index];
      }
    });
  }
  // newstate.leftSidebarVariables.currentPage = action.value;
  return newstate;
};

// export const setSearchText = (newstate, val => {
//   const { name, value } = val.target;
//   newstate.searchSectionVariables[name] = value
// };
export const clearSearchVariables = (newstate) => {
  newstate.searchSectionVariables = {
    sticker_code_ids: [],
    sticker_code: [],
    application_status: [],
    fname: "",
    lname: "",
    tag_number: "",
    application_number: "",
    address: "",
    license_plate: "",
    min_fee: "",
    max_fee: "",
    dateRange: {
      from: null,
      to: null,
    },
    searchText: "",
    searchResults: [],
    limit: 50,

    offset: 0,
    totalCount: 0,
    page: 1,
  };
};
export const setGenerateReportMode = (newstate, value) => {
  if (value === "range") {
    newstate.transactionReportMode = "range";
  } else {
    newstate.transactionReportMode = "single";
  }

};

export const setTransactionReportDate = (newstate, value) => {
  newstate.transactionReportDate.endDate = "";
  if (value === null) {
    newstate.transactionReportDate.date = "";
  } else {
    newstate.transactionReportDate.date =
      value === "" ? value : value.format("YYYY-MM-DD");
  }
};

export const setTransactionReportEndDate = (newstate, value) => {

  if (value === null) {
    newstate.transactionReportDate.endDate = "";
  } else {
    newstate.transactionReportDate.endDate =
      value === "" ? value : value.format("YYYY-MM-DD");
  }

};

export const updateApplicationNumber = (newstate, value) => {
  if (value.name === "fname" || value.name === "lname") {
    // const ALPHA_REGEX = /^[a-zA-Z ]+$/;
    // if (value.value !== "" && !ALPHA_REGEX.test(value.value)) {
    //   return;
    // }
    newstate.searchSectionVariables[value.name] = value.value;
  } else if (value.name === "application_number") {
    // const numberRegex = /^\d+(\.\d+)?$/gm;
    // if (value.value !== "" && !numberRegex.test(value.value.toString())) {
    //   return;
    // }
    newstate.searchSectionVariables[value.name] = value.value;
  } else if (value.name === "min_fee" || value.name === "max_fee") {
    if (value.value.match("^[0-9]*$") && value.value.length < 5)
      newstate.searchSectionVariables[value.name] = value.value;
  } else {
    newstate.searchSectionVariables[value.name] = value.value;
  }
};

export const updateTagNumber = (newstate, value) => {
  newstate.searchSectionVariables.tagNumber = value;
};

export const updateDateFrom = (newstate, value) => {
  if (value === null) {
    newstate.searchSectionVariables.dateRange.from = "";
  } else {
    newstate.searchSectionVariables.dateRange.from = value.format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }
};
export const updateDateTo = (newstate, value) => {
  if (value === null) {
    newstate.searchSectionVariables.dateRange.to = "";
  } else {
    newstate.searchSectionVariables.dateRange.to = value.format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }
};
export const updateSearchText = (newstate, value) => {
  newstate.searchSectionVariables.searchText = value;
};

export const updateSearchOffset = (newstate, value) => {
  newstate.searchSectionVariables.offset = value;
};

export const updateSearchResultLimit = (newstate, value) => {
  newstate.searchSectionVariables.limit = value;
};

export const updateVehicleFeeCategory = (newstate, value) => {
  const vehicleData = newstate.currentDocument.vehicles;
  vehicleData.map((vehicle) => {
    if (vehicle.vehicle_id === null) {
      vehicle.sticker_code.code_id = value;
    }
  });
};
export const updateVehicleTagNumber = (newstate, value, index) => {
  newstate.currentDocument.currentStickerApplications.Vehicles[index] = value;
};

export const updatePetTagNumber = (newstate, value, index) => {
  newstate.currentDocument.currentStickerApplications.Pets[index] = value;
};

export const updateFees = (newstate, action) => {
  const vehicleData = newstate.currentDocument.vehicles;
  vehicleData.map((vehicle) => {
    if (vehicle.vehicle_id === null) {
      vehicle.sticker_code.fee = action.value;
    }
  });
};

export const applyVehicleLateFee = (newstate, val1) => {
  newstate.currentlyEditingVehicle.late_fee_applied = val1;
};
export const applyPetLateFee = (newstate, val1) => {

  newstate.currentlyEditingPet.late_fee_applied = val1;
};
export const applyVehicleProratedFee = (newstate, val1) => {

  newstate.currentlyEditingVehicle.prorated_fee_applied = val1;
};
export const applyPetProratedFee = (newstate, val1) => {
 
  newstate.currentlyEditingPet.prorated_fee_applied = val1;
};
export const resetValidations = (newstate) => {
  newstate.UploadPageVariables.uploaded_files.length = 0;
  newstate.UploadPageVariables.preview_uploaded_files.length = 0;
  newstate.appGlobals.isCurrentUserDataValid = null;
  newstate.appGlobals.isAddingOrEditingPetRecordValid = null;
  newstate.appGlobals.isAddingOrEditingVehicleRecordValid = null;
  newstate.currentUserValidations = {
    family_name: {
      error: false,
      errorMsg: "",
    },
    first_name: {
      error: false,
      errorMsg: "",
    },
    phone_number: {
      error: false,
      errorMsg: "",
    },
    email: {
      error: false,
      errorMsg: "",
    },
    address: {
      address_line_1: {
        error: false,
        errorMsg: "",
      },
      city: {
        error: false,
        errorMsg: "",
      },
      postal_code: {
        error: false,
        errorMsg: "",
      },
      state_id: {
        error: false,
        errorMsg: "",
      },
    },
  };
  newstate.currentlyEditingVehicleValidations = {
    plate_number: {
      error: false,
      errorMsg: "",
    },
    make: {
      error: false,
      errorMsg: "",
    },
    model: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    model_year: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  };
  newstate.currentlyEditingPetValidations = {
    name: {
      error: false,
      errorMsg: "",
    },
    breed: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    sex: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_number: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_expiry: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  };
};

export const setCurrentlyEditingPet = (newstate, val) => {
  newstate.currentlyEditingPetValidations = {
    name: {
      error: false,
      errorMsg: "",
    },
    breed: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    sex: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_number: {
      error: false,
      errorMsg: "",
    },
    rabies_certificate_expiry: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  };
  newstate.appGlobals.isAddingOrEditingPetRecordValid = null;

  if (val === "initialAdd") {
    newstate.currentlyEditingPet = helpers.JPJS(newstate.SelectedPetTemplate);
    newstate.currentlyEditingPet.pet_id = newstate.appGlobals.newPetId;
    newstate.appGlobals.newPetId = newstate.appGlobals.newPetId - 1;
  } else {
    let petData = newstate.currentDocument.pets.filter((v) => v.pet_id === val);
    newstate.currentlyEditingPet = helpers.JPJS(petData[0]);
  }
};

export const setCurrentlyEditingPetData = (newstate, val, value2) => {
  if (value2 === "color") {
    newstate.currentlyEditingPet.color = val === null ? "" : val;
  } else if (value2 === "feeCategory") {
    newstate.currentlyEditingPet.sticker_code.code_description =
      val === null ? "" : val;
    newstate.stickersConfig.map((sticker) => {
      if (sticker.code_description === val) {
        
        newstate.currentlyEditingPet.sticker_code.fee = parseInt(sticker.fee);
        newstate.currentlyEditingPet.sticker_code.code_id = parseInt(
          sticker.code_id
        );
        newstate.currentlyEditingPet.sticker_code.code_type =
          helpers.upperCaseNullHandler(sticker.code_type);
      }
    });
  } else if (value2 === "rabiesExpiry") {
    newstate.currentlyEditingPet.rabies_certificate_expiry = val
      ? val.format("YYYY-MM-DD")
      : "";
  } else {
    const { name, value } = val.target;
    if (name === "name" || name === "breed" || name === "color") {
      // const ALPHA_REGEX = /^[a-zA-Z ]+$/;
      // if (value !== "" && !ALPHA_REGEX.test(value)) {
      //   return;
      // }
      // newstate.currentlyEditingPet[name] = "";
      newstate.currentlyEditingPet[name] = helpers.upperCaseNullHandler(value);
    } else if (name === "sex") {
      newstate.currentlyEditingPet[name] =
        helpers.upperCaseNullHandler(value) === "MALE"
          ? "Male"
          : helpers.upperCaseNullHandler(value) === "FEMALE"
          ? "Female"
          : helpers.upperCaseNullHandler(value);
    } else {
      newstate.currentlyEditingPet[name] = helpers.upperCaseNullHandler(value);
    }
  }
};

export const validatePetData = (newstate) => {
  const values = [
    "tag_number",
    "sticker_tag_expiry",
    "sticker_tag_number",
    "pet_id",
    "late_fee_applied",
    "prorated_fee_applied",
  ];
  let x = Object.fromEntries(
    Object.entries(newstate.currentlyEditingPet).filter(
      ([key]) => !values.includes(key)
    )
  );

  Object.keys(x).forEach((val, idx) => {
    if (val === "sticker_code") {
      let a = newstate.stickersConfig.map((x) => x.code_description);
      if (!a.includes(newstate.currentlyEditingPet[val]["code_description"])) {
        newstate.currentlyEditingPet[val]["code_description"] = "";
      }
      if (newstate.currentlyEditingPet[val]["code_description"] === "") {
        newstate.currentlyEditingPetValidations.sticker_code_description.errorMsg =
          "This is a required field";
        newstate.currentlyEditingPetValidations.sticker_code_description.error = true;
      } else {
        newstate.currentlyEditingPetValidations.sticker_code_description.errorMsg =
          "";
        newstate.currentlyEditingPetValidations.sticker_code_description.error = false;
      }
    } else {
      if (
        newstate.currentlyEditingPet[val] === "" ||
        newstate.currentlyEditingPet[val] === null
      ) {
        newstate.currentlyEditingPetValidations[val].errorMsg =
          "This is a required field";
        newstate.currentlyEditingPetValidations[val].error = true;
      } else {
        newstate.currentlyEditingPetValidations[val].errorMsg = "";
        newstate.currentlyEditingPetValidations[val].error = false;
      }
    }
  });

  if (
    newstate.currentlyEditingPet.name === "" ||
    newstate.currentlyEditingPet.breed === "" ||
    newstate.currentlyEditingPet.color === "" ||
    newstate.currentlyEditingPet.sex === "" ||
    newstate.currentlyEditingPet.rabies_certificate_number === "" ||
    newstate.currentlyEditingPet.rabies_certificate_expiry === null ||
    newstate.currentlyEditingPet.sticker_code.code_description === ""
  ) {
    newstate.appGlobals.isAddingOrEditingPetRecordValid = false;
    newstate.sidebarOpen = true;
  } else {
    newstate.appGlobals.isAddingOrEditingPetRecordValid = true;
    newstate.sidebarOpen = false;
    newstate.currentlyEditingPetValidations = {
      name: {
        error: false,
        errorMsg: "",
      },
      breed: {
        error: false,
        errorMsg: "",
      },
      color: {
        error: false,
        errorMsg: "",
      },
      sex: {
        error: false,
        errorMsg: "",
      },
      rabies_certificate_number: {
        error: false,
        errorMsg: "",
      },
      rabies_certificate_expiry: {
        error: false,
        errorMsg: "",
      },
      sticker_code_description: {
        error: false,
        errorMsg: "",
      },
    };
  }
};

export const addPetIntoCurrentDocument = (newstate) => {
  validatePetData(newstate);
  if (newstate.appGlobals.isAddingOrEditingPetRecordValid) {
    newstate.currentDocument.pets.push(
      helpers.JPJS(newstate.currentlyEditingPet)
    );
    newstate.currentlyEditingPet = helpers.JPJS(newstate.SelectedPetTemplate);
  }
};

export const editPetIntoCurrentDocument = (newstate) => {

  validatePetData(newstate);
  if (newstate.appGlobals.isAddingOrEditingPetRecordValid) {
    newstate.currentDocument.pets = newstate.currentDocument.pets.filter(
      (v) => v.pet_id !== newstate.currentlyEditingPet.pet_id
    );
    newstate.currentDocument.pets.push(
      helpers.JPJS(newstate.currentlyEditingPet)
    );
    newstate.currentlyEditingPet = helpers.JPJS(newstate.SelectedPetTemplate);
  }
};

export const updatePetStickerInfo = (newstate, action) => {
  if (action.label === "tagExpiry") {
    let value = action.val ? action.val.format("YYYY-MM-DD") : "";
    newstate.currentDocument.pets.filter((v) => v.pet_id === action.id)[0][
      "sticker_tag_expiry"
    ] = value;
  } else {
    const { name, value } = action.val.target;
    newstate.currentDocument.pets.filter((v) => v.pet_id === action.id)[0][
      name
    ] = value;
  }
};

export const updateVehicleStickerInfo = (newstate, action) => {
  if (action.label === "tagExpiry") {
    let value = action.val ? action.val.format("YYYY-MM-DD") : "";
    newstate.currentDocument.vehicles.filter(
      (v) => v.vehicle_id === action.id
    )[0]["sticker_tag_expiry"] = value;
  } else {
    const { name, value } = action.val.target;
    newstate.currentDocument.vehicles.filter(
      (v) => v.vehicle_id === action.id
    )[0][name] = value;
  }
};

export const setCurrentlyEditingVehicle = (newstate, val) => {
  newstate.currentlyEditingVehicleValidations = {
    plate_number: {
      error: false,
      errorMsg: "",
    },
    make: {
      error: false,
      errorMsg: "",
    },
    model: {
      error: false,
      errorMsg: "",
    },
    color: {
      error: false,
      errorMsg: "",
    },
    model_year: {
      error: false,
      errorMsg: "",
    },
    sticker_code_description: {
      error: false,
      errorMsg: "",
    },
  };
  newstate.appGlobals.isAddingOrEditingVehicleRecordValid = null;
  if (val === "initialAdd") {
    newstate.currentlyEditingVehicle = helpers.JPJS(
      newstate.SelectedVehicleTemplate
    );
    newstate.currentlyEditingVehicle.vehicle_id =
      newstate.appGlobals.newVehicleId;
    newstate.appGlobals.newVehicleId = newstate.appGlobals.newVehicleId - 1;
  } else {
    let vehicleData = newstate.currentDocument.vehicles.filter(
      (v) => v.vehicle_id === val
    );
    newstate.currentlyEditingVehicle = helpers.JPJS(
      newstate.currentDocument.vehicles.filter((v) => v.vehicle_id === val)[0]
    );
    newstate.currentlyEditingVehicle.model_year =
      "" + newstate.currentlyEditingVehicle.model_year;
  }
};
export const setCurrentlyEditingVehicleData = (newstate, val, val2) => {
  if (val2 === "color") {
    newstate.currentlyEditingVehicle.color = val === null ? "" : val;
  } else if (val2 === "feeCategory") {
    newstate.currentlyEditingVehicle.sticker_code.code_description =
      val === null ? "" : val;
    newstate.stickersConfig.map((sticker) => {
      if (sticker.code_description === val) {
        newstate.currentlyEditingVehicle.sticker_code.fee = parseInt(
          sticker.fee
        );
        newstate.currentlyEditingVehicle.sticker_code.code_id = parseInt(
          sticker.code_id
        );
        newstate.currentlyEditingVehicle.sticker_code.code_type =
          helpers.upperCaseNullHandler(sticker.code_type);
      }
    });
  } else if (val2 === "modelYear") {
    if (Number(val?.format("YYYY")) < 1800 && val != null) {
      newstate.currentlyEditingVehicleValidations.model_year.error = true;
      newstate.currentlyEditingVehicleValidations.model_year.errorMsg =
        "Enter a valid year";
      return;
    }
    // else {
    //   newstate.currentlyEditingVehicleValidations.model_year.error = false;
    //   newstate.currentlyEditingVehicleValidations.model_year.errorMsg =
    //     "";
    // }
    newstate.currentlyEditingVehicle.model_year = val ? val.format("YYYY") : "";
  } else {
    const { name, value } = val.target;
    if (name === "make" || name === "model") {
      // const alphanumRegex = /^[a-zA-Z0-9 ]+$/;
      // if (value !== "" && !alphanumRegex.test(value)) {
      //   return;
      // }
      newstate.currentlyEditingVehicle[name] =
        helpers.upperCaseNullHandler(value);
    } else {
      newstate.currentlyEditingVehicle[name] =
        helpers.upperCaseNullHandler(value);
    }
  }
};

export const validateUserData = (newstate) => {
  const values = ["applicant_id", "address_id", "stripe_customer_id"];
  let x = Object.fromEntries(
    Object.entries(newstate.currentDocument.applicant).filter(
      ([key]) => !values.includes(key)
    )
  );
  Object.keys(x).forEach((val, idx) => {
    if (val === "address") {
      const values2 = ["address_id", "address_line_2"];
      let y = Object.fromEntries(
        Object.entries(newstate.currentDocument.applicant.address).filter(
          ([key]) => !values2.includes(key)
        )
      );
      Object.keys(y).forEach((field, idx) => {
        if (field === "state") {
          if (
            newstate.currentDocument.applicant.address.state.state_id === null
          ) {
            newstate.currentUserValidations.address.state_id.errorMsg =
              "This is a required field";
            newstate.currentUserValidations.address.state_id.error = true;
          } else {
            newstate.currentUserValidations.address.state_id.errorMsg = "";
            newstate.currentUserValidations.address.state_id.error = false;
          }
        } else {
          if (
            newstate.currentDocument.applicant.address[field] === "" ||
            newstate.currentDocument.applicant.address[field] === null
          ) {
            newstate.currentUserValidations.address[field].errorMsg =
              "This is a required field";
            newstate.currentUserValidations.address[field].error = true;
          } else {
            newstate.currentUserValidations.address[field].errorMsg = "";
            newstate.currentUserValidations.address[field].error = false;
          }
        }
      });
    } else {
      if (
        newstate.currentDocument.applicant[val] === "" ||
        newstate.currentDocument.applicant[val] === null
      ) {
        newstate.currentUserValidations[val].errorMsg =
          "This is a required field";
        newstate.currentUserValidations[val].error = true;
      } else {
        newstate.currentUserValidations[val].errorMsg = "";
        newstate.currentUserValidations[val].error = false;
      }
    }
  });
  if (
    newstate.currentDocument.applicant.family_name === "" ||
    newstate.currentDocument.applicant.first_name === "" ||
    newstate.currentDocument.applicant.phone_number === "" ||
    newstate.currentDocument.applicant.email === "" ||
    newstate.currentDocument.applicant.address.state_id === null ||
    newstate.currentDocument.applicant.address.address_line_1 === "" ||
    newstate.currentDocument.applicant.address.city === "" ||
    newstate.currentDocument.applicant.address.postal_code === ""
  ) {
    newstate.appGlobals.isCurrentUserDataValid = false;
  } else {
    newstate.appGlobals.isCurrentUserDataValid = true;
  }
};

export const validateVehicleData = (newstate) => {
  newstate.appGlobals.isAddingOrEditingVehicleRecordValid = false;
  const values = [
    "tag_number",
    "sticker_tag_expiry",
    "sticker_tag_number",
    "vehicle_id",
    "late_fee_applied",
    "prorated_fee_applied",
  ];
  let x = Object.fromEntries(
    Object.entries(newstate.currentlyEditingVehicle).filter(
      ([key]) => !values.includes(key)
    )
  );
  Object.keys(x).forEach((val, idx) => {
    if (val === "sticker_code") {
      let a = newstate.stickersConfig.map((x) => x.code_description);
      if (
        !a.includes(newstate.currentlyEditingVehicle[val]["code_description"])
      ) {
        newstate.currentlyEditingVehicle[val]["code_description"] = "";
      }
      if (newstate.currentlyEditingVehicle[val]["code_description"] === "") {
        newstate.currentlyEditingVehicleValidations.sticker_code_description.errorMsg =
          "This is a required field";
        newstate.currentlyEditingVehicleValidations.sticker_code_description.error = true;
      } else {
        newstate.currentlyEditingVehicleValidations.sticker_code_description.errorMsg =
          "";
        newstate.currentlyEditingVehicleValidations.sticker_code_description.error = false;
      }
    } else {
      if (
        newstate.currentlyEditingVehicle[val] === "" ||
        newstate.currentlyEditingVehicle[val] === null
      ) {
        newstate.currentlyEditingVehicleValidations[val].errorMsg =
          "This is a required field";
        newstate.currentlyEditingVehicleValidations[val].error = true;
      } else {
        newstate.currentlyEditingVehicleValidations[val].errorMsg = "";
        newstate.currentlyEditingVehicleValidations[val].error = false;
      }
    }
  });

  if (
    newstate.currentlyEditingVehicle.plate_number === "" ||
    newstate.currentlyEditingVehicle.make === "" ||
    newstate.currentlyEditingVehicle.color === "" ||
    newstate.currentlyEditingVehicle.model === "" ||
    newstate.currentlyEditingVehicle.model_year === "" ||
    newstate.currentlyEditingVehicle.model_year === null ||
    newstate.currentlyEditingVehicle.sticker_code.code_description === ""
  ) {
    newstate.appGlobals.isAddingOrEditingVehicleRecordValid = false;
    newstate.sidebarOpen = true;
  } else {
    newstate.appGlobals.isAddingOrEditingVehicleRecordValid = true;
    newstate.sidebarOpen = false;
    newstate.currentlyEditingVehicleValidations = {
      plate_number: {
        error: false,
        errorMsg: "",
      },
      make: {
        error: false,
        errorMsg: "",
      },
      model: {
        error: false,
        errorMsg: "",
      },
      color: {
        error: false,
        errorMsg: "",
      },
      model_year: {
        error: false,
        errorMsg: "",
      },
      sticker_code_description: {
        error: false,
        errorMsg: "",
      },
    };
  }
};

export const addVehicleIntoCurrentDocument = (newstate) => {
  validateVehicleData(newstate);
  if (newstate.appGlobals.isAddingOrEditingVehicleRecordValid) {
    // newstate.currentDocument.vehicles =
    //   newstate.currentDocument.vehicles.filter(
    //     (v) => v.id !== newstate.currentlyEditingVehicle.id
    //   );
    newstate.currentDocument.vehicles.push(
      helpers.JPJS(newstate.currentlyEditingVehicle)
    );

    newstate.currentlyEditingVehicle = helpers.JPJS(
      newstate.SelectedVehicleTemplate
    );
  }
};

export const editVehicleIntoCurrentDocument = (newstate) => {
  validateVehicleData(newstate);
  if (newstate.appGlobals.isAddingOrEditingVehicleRecordValid) {
    newstate.currentDocument.vehicles =
      newstate.currentDocument.vehicles.filter(
        (v) => v.vehicle_id !== newstate.currentlyEditingVehicle.vehicle_id
      );
    newstate.currentDocument.vehicles.push(
      helpers.JPJS(newstate.currentlyEditingVehicle)
    );
    newstate.currentlyEditingVehicle = helpers.JPJS(
      newstate.SelectedVehicleTemplate
    );
  }
};

// export const validateNewPetData = (newstate) => {
//   const textRegex = /^[a-zA-Z]+$/;
//   const validOptions = ['male', 'female'];
//   if (!validOptions.includes(input.toLowerCase())) {
//     return false;
//   }
//   newstate.appGlobals.isAddingOrEditingVehicleRecordValid = true;
//   newstate.appGlobals.isAddingOrEditingVehicleRecordValid = false;
//   newstate.appGlobals.applicationErrorMessages.push({
//     name: "ValidVehicle",
//     errorMessage: "The vehicle is missing the following data: ",
//   });
// };

export const updateVehicleData = (newstate, value) => {
  if (value === "initiaAdd") {
    newstate.applicationVariables.isDataChanged = true;
  }
  const vehicleData = [...newstate.currentDocument.vehicles];
  // let l = vehicleData.length;
  if (vehicleData.length > 25) {
    newstate.showAlert = true;
  }
  if (
    (value === "initialAdd" || vehicleData.length === 0) &&
    vehicleData.length < 25
  ) {
    vehicleData.push(
      helpers.JPJS({
        // idx : idx,
        vehicle_id: null,
        plate_number: "plate no",
        make: "Make",
        model: "Model",
        color: "color",
        model_year: "year",
        sticker_code: {
          code_id: null,
          code_description: "Auto",
          fee: null,
          code_type: "",
        },
      })
    );
    newstate.vehicleIndex = vehicleData.length - 1;
    // l = l+1
  }
  newstate.currentDocument.vehicles = vehicleData;
  newstate.applicationData.allVillageUsersApplications.map((application) => {
    if (application.userID === value) {
      application.currentStickerApplications.ApplicationStatus =
        "RenewalStarted";
      application.currentStickerApplications.ApplicationStatusText =
        "Renewal Started";
      application.currentStickerApplications.DocumentStatus =
        "DocumentsPending";
      application.currentStickerApplications.DocumentStatusText =
        "Documents Pending";
      application.currentStickerApplications.PaymentStatus = "PaymentPending";
      application.currentStickerApplications.PaymentStatusText =
        "Payment Pending";
    }
  });
  return newstate;
};

export const setBillingAddress = (newstate, val) => {
  newstate.applicationVariables.isDataChanged = true;
  const { name, value } = val.target;
  const addressDetails = newstate.currentDocument.paymentAddress;
  if (newstate.PaymentPageVariables.isChecked === true) {
    addressDetails.billingAddress =
      newstate.currentDocument.address_line_1 +
      " " +
      newstate.currentDocument.address_line_2;
    addressDetails.zipCode = newstate.currentDocument.postal_code;
    addressDetails.city = newstate.currentDocument.city;
    addressDetails.stat = newstate.currentDocument.state.full_name;
  } else {
    addressDetails[name] = value;
  }
  newstate.currentDocument.paymentAddress = addressDetails;
  return newstate;
};

export const setVehicleData = (newstate, val) => {
  const index = newstate.vehicleIndex;
  newstate.applicationVariables.isDataChanged = true;
  const { name, value } = val.target;
  const vehicleData = newstate.currentDocument.vehicles;
  if (name === "feeCategory") {
    vehicleData[index].sticker_code.code_id = parseInt(value);
    newstate.stickersConfig.map((sticker) => {
      if (sticker.code_id === parseInt(value)) {
        vehicleData[index].sticker_code.fee = parseInt(sticker.fee);
        // vehicleData[index].sticker_code.formatted_fee =
        //   "$" +
        //   parseInt(sticker.fee)
        //     .toFixed(2)
        //     .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        vehicleData[index].sticker_code.code_description =
          sticker.code_description;
        vehicleData[index].sticker_code.code_type = sticker.code_type;
      }
    });
  } else {
    vehicleData[index][name] = value;
  }
  newstate.currentDocument.vehicles = vehicleData;
  return newstate;
};

export const setVehicleIndex = (newstate, index) => {
  newstate.vehicleIndex = index;
  return newstate;
};

export const setPetIndex = (newstate, index) => {
  newstate.petIndex = index;
  return newstate;
};

export const loadApplicationPage = (newstate, value, index) => {
  let pages = [];
  newstate.userVariables.isLoggedIn = value;
  if (value === "Register" || index === 0) {
    newstate.userVariables.profileVerified = true;
  }
  if (newstate.userIsAdmin === true) {
    const adminFlow = [
      "Register",
      "Upload Documents",
      "Preview application",
      // "Make Payment",
      // "Card payment",
      "Confirmation",
    ];
    pages = adminFlow;
  } else {
    const citizenFlow = ["Register", "Preview application", "Confirmation"];
    pages = citizenFlow;
  }
  if (
    newstate.userIsAdmin === false &&
    newstate.applicationVariables.isDataChanged === true
  ) {
    newstate.applicationVariables.currentPage = "Data Change";
  } else if (index !== null && index < 3) {
    newstate.applicationVariables.currentPageIndex = index;
    newstate.applicationVariables.currentPage = pages[index];
  } else {
    const idx = pages.indexOf(value);
    newstate.applicationVariables.currentPageIndex = idx;
    newstate.applicationVariables.currentPage = value;
  }
};

export const setExpand = (newstate, value) => {
  newstate.leftSidebarVariables.isOpen = value;
};
// export const loadCitizenPage = (newstate, action) => {
//   let newstate = { ...oldstate };
//   // let a = newstate.applicationData.allVillageUsersApplications;
//   if (action.apiData === "Nothing") {
//     newstate.currentDocument =   {
//       "status": "In Progress",
//       "applicant": {
//         "applicant_id": 1,
//         "family_name": "Karkoska",
//         "first_name": "Ellen",
//         "phone_number": "(708) 317-4132",
//         "email": "karkoska@test.com",
//         "address": {
//           "address_line_1": "7031",
//           "address_line_2": "40th",
//           "city": "Stickney",
//           "postal_code": "602456",
//           "state_id": 14
//         }
//       },
//       "vehicles": [
//         {
//           "vehicle_id": 1,
//           "plate_number": "TLW 411",
//           "make": "Chev",
//           "model": "Blazer",
//           "color": "Green",
//           "model_year": 2002,
//           "id": 3,
//           "sticker_code":{
//             "sticker_code_id":2,
//             "fee":10,

//           }

//         }
//       ],
//       "pets": [
//         {
//           "tag_number": 1,
//           "pet_id": 1,
//             "name": "kitty",
//             "sex": "female",
//             "breed": "pug",
//             "rabies_certificate_number": "1224",
//             "rabies_certificate_expiry": "12/24",
//             "color": "black",
//             "sticker_code": {
//               "code_id": 1,
//               "code_description": "gnhn",
//               "fee": 1,
//               "code_type": "3",
//             },
//         }]
//     };
//   } else {
//     newstate.currentDocument = action.apiData;
//   }

//   newstate.citizenViewVariables.currentPage = action.value;
//   return newstate;
// };

export const loadCitizenPage = (newstate, action) => {
  if (typeof action.apiData !== "undefined") {
    newstate.userVariables.application_id = action.apiData.application_id;
    if (action.apiData === "Nothing") {
      newstate.currentDocument = {
        submission_date: "",
        application_id: null,
        status: "ToBeCreatedApplication",
        applicant: {
          applicant_id: null,
          family_name: "",
          first_name: "",
          address_id: null,
          phone_number: "",
          email: "",
          address: {
            address_id: null,
            address_line_1: "",
            address_line_2: "",
            city: "",
            postal_code: "",
            state: {
              state_id: null,
              abbreviation: "",
              full_name: "",
            },
          },
        },
        transaction_info: {
          payment_credit: 0,
          payment_types: [],
        },
        pets: [
          // {
          //   pet_id: null,
          //   name: "",
          //   breed: "",
          //   color: "",
          //   sex: "",
          //   rabies_certificate_number: "",
          //   rabies_certificate_expiry: "",
          //   sticker_code: {
          //     code_id: null,
          //     code_description: "",
          //     fee: 0,
          //     code_type: "",
          //   },
          // },
        ],
        vehicles: [
          // {
          //   vehicle_id: null,
          //   plate_number: "",
          //   make: "",
          //   model: "",
          //   color: "",
          //   model_year: null,
          //   sticker_code: {
          //     code_id: null,
          //     code_description: "",
          //     fee: 0,
          //     code_type: "",
          //   },
          // },
        ],
      };
    } else {
      newstate.currentDocument = action.apiData;
    }
  }
  newstate.citizenViewVariables.currentPage = action.value;
  return newstate;
};

export const createCitizenApplication = (nstate) => {
  if (nstate.citizenViewVariables.citizenApplications.length > 0) {
    nstate.currentDocument.submission_date =
      nstate.citizenViewVariables.citizenApplications[0].submission_date;
    nstate.currentDocument.application_id =
      nstate.citizenViewVariables.citizenApplications[0].application_id;
    nstate.currentDocument.status =
      nstate.citizenViewVariables.citizenApplications[0].status;
    nstate.currentDocument.document_status =
      nstate.citizenViewVariables.citizenApplications[0].document_status;
    nstate.currentDocument.citizen_user_id =
      nstate.citizenViewVariables.citizenApplications[0].citizen_user_id;
    nstate.currentDocument.applicant = helpers.JPJS(
      nstate.citizenViewVariables.citizenApplications[0].applicant
    );
    nstate.currentDocument.pets = helpers.JPJS(
      nstate.citizenViewVariables.citizenApplications[0].pets
    );
    nstate.currentDocument.vehicles = helpers.JPJS(
      nstate.citizenViewVariables.citizenApplications[0].vehicles
    );
  }
};

export const setCurrentUser = (newstate) => {
  const username = newstate.userVariables.username;
  const a = newstate.applicationData.allVillageUsersApplications;
  a.map((app, index) => {
    if (app.userID === username) {
      newstate.currentDocument = a[index];
    }
  });
  return newstate;
};

export const loadApplicationLogin = (newstate, action) => {
  newstate.citizenViewVariables.currentPage = action.value[0];
  newstate.applicationVariables.enterCode = action.value[1];
  if (action.value[1] === false) {
    newstate.userVariables.usernameError = null;
  }
  newstate.userVariables.cognitoResponse = { ...action.loginResponse };
};

export const setPaymentMode = (newstate, value) => {
  newstate.applicationVariables.paymentIsOnline = !value;
};

export const setApplicationType = (newstate, value) => {
  newstate.applicationVariables.applicationIsNew = value;
  if (newstate.applicationVariables.applicationIsNew === true) {
    newstate.currentDocument = {
      submission_date: "",
      application_id: null,
      status: "",
      applicant: {
        applicant_id: null,
        family_name: "",
        first_name: "",
        address_id: null,
        phone_number: "",
        email: "",
        address: {
          address_id: null,
          address_line_1: "",
          address_line_2: "",
          city: "",
          postal_code: "",
          state: {
            state_id: null,
            abbreviation: "",
            full_name: "",
          },
        },
      },

      pets: [
        {
          tag_number: null,
          pet_id: null,
          name: "",
          breed: "",
          color: "",
          sex: "",
          rabies_certificate_number: "",
          rabies_certificate_expiry: null,
          sticker_code: {
            code_id: null,
            code_description: "",
            fee: null,
            code_type: "",
          },
        },
      ],

      vehicles: [
        {
          tag_number: null,
          vehicle_id: null,
          plate_number: "",
          make: "",
          model: "",
          color: "",
          model_year: null,
          sticker_code: {
            code_id: null,
            code_description: "",
            fee: null,
            code_type: "",
          },
        },
      ],
      paymentAddress: {
        billingAddress: "",
        city: "",
        stat: "",
        zipCode: "",
      },
    };
  }

  return newstate;
};

export const loadTestData = (newstate, value) => {
  newstate.applicationData.allVillageUsersApplications = [
    ...value.existingApps,
  ];
  return newstate;
};

export const setVariablesInDecisionTree = (newstate, value) => {
  switch (value.tree) {
    case "Admin":
      // switch(value.treeFirstNode):

      break;
    case "VillageUser":
      break;
    default:
      break;
  }
  // newstate.
};

let getLatestAndAllDetailsOfDocument = (nState, aplcns) => {
  const ids = aplcns
    .filter((m) => m !== null)
    .map((o) => {
      return o.application_id;
    });
  const maxid = Math.max(...ids);
  let latestApp = aplcns.filter((m) => m === maxid)[0];
  if (latestApp && latestApp.length > 0) {
    nState.SelectionCurrentDocument.applicant = latestApp[0].applicant;
    //nState.SelectionCurrentDocument.pets = aplcns.
  }
};

export const AddApplicantDetailsFromExisting = (nState) => {
  //nState.SelectionCurrentDocument.applicant
  nState.SelectionCurrentDocument.status = "In Progress";
  //nState.SelectionCurrentDocument.applicant =
};
export const AddAVehicleStickersFromExisting = (nState) => {};
export const AddPetStickersFromExisting = (nState) => {};
export const AddPaymentMethodsFromExisting = (nState) => {};

export const CreateNewApplication = (nState) => {
  nState.currentDocument = helpers.JPJS(nState.SelectionCurrentDocument);
  nState.SelectionCurrentDocument = helpers.JPJS(
    nState.SelectionCurrentDocumentTemplate
  );
};
