/* eslint-disable react/prop-types */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { connect } from "react-redux";
// import TableFooter from '@mui/material/TableFooter';
// import TablePagination from '@mui/material/TablePagination';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { Link } from "react-router-dom";
import { allEvents } from "../../statesandlogics/cnstshelpers";

const openApplication = (e, getUserApplicationByID, getUploadedDocs, row,showApplication) => {
  showApplication(true);
  getUserApplicationByID(row.application_id);
  getUploadedDocs(row.application_id);  
};

const toRoute = "ReviewingApplication";

class ApplicationDataTable extends React.Component {
  render() {
    const {
      setCurrentUser,
      setApplicationType,
      searchResults,
      getUserApplicationByID,
      getUploadedDocs,
      showApplication,
    } = this.props;

    return (
      <div>
        <div>
          <Grid
            container
            flex
            sx={{
              margin: "0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                borderRadius: "5px",
                boxShadow: "8px 8px 8px 8px rgba(43, 42, 42, 0.1)",
                padding: "2% 0",
                width: "75%",
              }}
            >
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Application Number</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Street Address</TableCell>
                        <TableCell align="right">Date of Purchase</TableCell>
                        <TableCell align="right">Fee Categories</TableCell>
                        <TableCell align="right">Total Fee</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="left">Sticker Mailed?</TableCell>
                        <TableCell align="right">Receipt</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row) => (
                        <TableRow
                          key={row.user_id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="right">
                            <Button
                              component={Link}
                              onClick={(e) => {
                                openApplication(e, getUserApplicationByID, getUploadedDocs, row, showApplication );
                              }}
                              to={toRoute}
                            >
                              {/* 1 */}
                              {row.application_id}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              component={Link}
                              to={toRoute}
                              onClick={(e) => {
                                openApplication(
                                  e,
                                  getUserApplicationByID,
                                  getUploadedDocs,
                                  row,
                                  showApplication
                                );
                              }}
                            >
                              {/* Ellen */} {row.first_name}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              component={Link}
                              to={toRoute}
                              onClick={(e) => {
                                openApplication(
                                  e,
                                  getUserApplicationByID,
                                  getUploadedDocs,
                                  row,
                                  showApplication
                                );
                              }}
                            >
                              {row.address_line_1 + " " + row.address_line_2}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              component={Link}
                              to={toRoute}
                              onClick={(e) => {
                                openApplication(
                                  e,
                                  getUserApplicationByID,
                                  getUploadedDocs,
                                  row,
                                  showApplication
                                );
                              }}
                            >
                              {/* 12/04/2022 */}
                              {row.date_of_purchase === null
                                ? "N/A"
                                : row.date_of_purchase}
                            </Button>
                          </TableCell>

                          <TableCell align="right">
                            <Button
                              component={Link}
                              to={toRoute}
                              onClick={(e) => {
                                openApplication(
                                  e,
                                  getUserApplicationByID,
                                  getUploadedDocs,
                                  row,
                                  showApplication
                                );
                              }}
                            >
                              {row.fee_categories[0]}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              component={Link}
                              to={toRoute}
                              onClick={(e) => {
                                openApplication(
                                  e,
                                  getUserApplicationByID,
                                  getUploadedDocs,
                                  row,
                                  showApplication
                                );
                              }}
                            >
                              {/* 10 */} {row.total_fee}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button>{row.status}</Button>
                          </TableCell>
                          <TableCell align="right"> Fulfilled</TableCell>
                          <TableCell align="right">
                            <IconButton>
                              <DownloadRoundedIcon></DownloadRoundedIcon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  applicationData: state.applicationData.allVillageUsersApplications,
  searchResults: state.searchSectionVariables.searchResults,
  currentPage: state.applicationDataTableVariables.currentPage,
  currentDocument: state.currentDocument,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (val) => dispatch({ type: "CHANGE_TABLE_PAGE", value: val }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    updateUsername: (text) =>
      dispatch({ type: "UPDATE_USERNAME", value: text }),
    setCurrentUser: () => dispatch({ type: "SET_CURRENT_USER" }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
 
    getUserApplicationByID: (val) => api.getUserApplicationByID(val, dispatch),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
    // setSubmitButtonText: (text) => dispatch({ type: 'SET_SUBMIT_BUTTON_TEXT', value: text })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDataTable);
