import { loadApiSetting } from "./ApiCalls/Api";
import quicketLogo from "../images/quicketLogo.svg";
import stickneyLogo from "../images/villageLogo.png";

function get_logo_path() {
  let logoName = "quicket";
  // loadApiSetting(() => {});
  if (localStorage.getItem("siteLogoName") !== null) {
    logoName = localStorage.getItem("siteLogoName");
    // loadApiSetting(() => {});
  }
  const logoMap = {
    stickney: stickneyLogo,
    quicket: quicketLogo,
  };
  
  return typeof(logoMap[logoName]) === "undefined" ?  quicketLogo : logoMap[logoName];
}

export default get_logo_path;
