import React, { Component } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  CardHeader
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
const CurrentGlobalUIData = {
  
  status: "In Progress",
  payment_status: "Pending",
  document_status: "Pending",
  application_id: 15,
  applicant: {
      family_name: "Drew",
      first_name: "Akhil",
      phone_number: "(327) 462-3453",
      email: "ricoh@gmail.com",
      stripe_customer_id: null,
      applicant_id: 15,
      address: {
          address_line_1: "U71/50 U block ",
          address_line_2: "Street 21",
          city: "Stickney",
          postal_code: "65354",
          address_id: 15,
          state: {
              abbreviation: "Illinois",
              full_name: "IL",
              state_id: 14
          }
      }
  },
  pets: [
    {
        name: "Brownie",
        breed: "Kitten",
        color: "Brown",
        sex: "Male",
        rabies_certificate_number: "CD23453",
        rabies_certificate_expiry: "2023-01-01",
        pet_id: 9,
        sticker_code: {
            code_description: "Sterile Animal",
            fee: 5,
            code_id: 14
        }
    },
    {
      name: "Johnny Jr",
      breed: "Shiba",
      color: "Brown",
      sex: "Male",
      rabies_certificate_number: "CD102345",
      rabies_certificate_expiry: "2023-02-10",
      pet_id: 8,
      sticker_code: {
          code_description: "Sterile Animal",
          fee: 10,
          code_id: 15
      }
  }
],
  vehicles: [
      {
          plate_number: "ZV123",
          make: "Ford",
          model: "Figo",
          color: "Black",
          model_year: 2002,
          vehicle_id: 15,
          sticker_code: {
              code_description: "Senior Citizen",
              fee: 0,
              code_id: 2
          }
      }
  ],
  documents: [
      {
        filename: "notice_trust_accounting_524.pdf",
          filepath: "arn:aws-us-gov:s3:::qs-il-test/stickney/muni-sticker/Cpq4ax39Ke828kTWNmhv5k_notice_trust_accounting_524.pdf",
          upload_completed: false,
          pet_id: null,
          vehicle_id: null,
          session_id: "00d0b32f-2868-4e9e-b1d6-a34c8d9a9f78",
          document_id: 30
      },
      {
        filename: "notice_civil_act_status_559.pdf",
          filepath: "arn:aws-us-gov:s3:::qs-il-test/stickney/muni-sticker/4hpMQ4sMpu7mpG8xRtApoA_notice_civil_act_status_letter_559.pdf",
          upload_completed: false,
          pet_id: null,
          vehicle_id: null,
          session_id: "00d0b32f-2868-4e9e-b1d6-a34c8d9a9f78",
          document_id: 31
      },
      {
        filename: "notice_trust_accounting_524.pdf",
          filepath: "arn:aws-us-gov:s3:::qs-il-test/stickney/muni-sticker/Cpq4ax39Ke828kTWNmhv5k_notice_trust_accounting_524.pdf",
          upload_completed: false,
          pet_id: null,
          vehicle_id: null,
          session_id: "00d0b32f-2868-4e9e-b1d6-a34c8d9a9f78",
          document_id: 30
      },

  ]

}
export default class DocumentCard extends Component {
  render() {
    return (
        <>
      <Box>
        <Typography
          sx={{
            color: "black",
            fontSize: 28,
           
            fontWeight: 600,
            marginBottom: 1,
            marginLeft: 2,
          }}
        >
          Documents History
        </Typography>
        
       
        <Card className="document_cards">
           
        {CurrentGlobalUIData.documents.map((document) => (
            <Card className="document_card">
             <div>
             <InsertDriveFileIcon />

             </div>
             <div>
                
                <Typography variant="p" sx={{
                  textOverflow: "Ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
            color:  "#2f67a3",
            fontSize: "14px",
            fontWeight: "600",  
            margin: 0,
            padding: 0,

          }}>{document.filename}</Typography>
                </div>
            </Card>
          ))}
        </Card>
      </Box>


      </>
    );
  }
}
