import storage from "redux-persist/lib/storage";
import { appreducer } from "./appreducer";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

export const persistedReducer = persistReducer(persistConfig,appreducer)
