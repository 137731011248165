/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import { Button, TextField, List, ListItem, Autocomplete } from "@mui/material";
import {
  InputAdornment,
  IconButton,
  Listbox,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Checkbox,
} from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormControl, Typography } from "@mui/material";
import * as api from "../../statesandlogics/ApiCalls/Api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { clearSearchVariables } from "../../statesandlogics/brls";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class SearchSection extends React.Component {
  // componentDidMount() {
  //   if (this.props.searchFirstTimeLoaded === true) {
  //     this.props.searchApplications(
  //       "GetAll",
  //       this.props.searchSectionVariables.limit
  //     );
  //   }
  //   // this.props.clearSearchVariables();
  // }

  componentDidUnmount() {
    this.props.clearSearchVariables();
  }

  render() {
    const {
      application_status,
      allApplicationStatus,
      getApplicationStatus,
      updateApplicationNumber,
      updateDateFrom,
      updateDateTo,
      getStickerCodes,
      sticker_code,
      dateTo,
      dateFrom,
      stickersConfig,
      searchApplications,
      searchSectionVariables,
      clearSearchVariables,
      status,
    } = this.props;

    return (
      <>
        <Box className="main-search-container">
          <Card className="rowcard-cc-ss">
            <div style={{ marginBottom: "15px" }}>
              <div className="first-row-ss">
                <div>
                  <TextField
                    id="standard-basic"
                    name="fname"
                    type="text"
                    value={searchSectionVariables.fname}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    variant="standard"
                    className="input-wrapper-ss"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            First Name :{" "}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className="input-wrapper-ss"
                    value={searchSectionVariables.lname}
                    name="lname"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            Last Name :{" "}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className="input-wrapper-ss"
                    name="application_number"
                    value={searchSectionVariables.application_number}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    // type="number"
                    InputProps={{
                      // inputProps: {
                      //   min: 0,
                      // },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            Application No:
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>
                <div className="address_wrapper">
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    name="address"
                    value={searchSectionVariables.address}
                    className="input-wrapper-ss address-wrapper-ss"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 250,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            Address :
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>
                <div className="license_wrapper">
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    name="license_plate"
                    className="input-wrapper-ss"
                    value={searchSectionVariables.license_plate}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            License Plate :
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>

                <div className="input-wrapper-ss">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="min_fee"
                    type="number"
                    className="input-wrapper-ss"
                    value={searchSectionVariables.min_fee}
                    onChange={(e) => updateApplicationNumber(e.target)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            Min Fee :
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    name="max_fee"
                    className="input-wrapper-ss"
                    value={searchSectionVariables.max_fee}
                    onChange={(e) => updateApplicationNumber(e.target)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            Max Fee :
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="address_wrapper">
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    name="tag_number"
                    value={searchSectionVariables.tag_number}
                    className="input-wrapper-ss address-wrapper-ss"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchApplications(searchSectionVariables, 0, 0);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "gray",
                              display: "inline",
                              textAlign: "left",
                            }}
                          >
                            Tag Number :
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => updateApplicationNumber(e.target)}
                  />
                </div>
              </div>

              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  "@media (max-width: 850px)": {
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "100%",
                  },
                }}
              >
                <Autocomplete
                  multiple
                  variant="standard"
                  name="fee_category"
                  sx={{
                    backgroundColor: "white",
                    width: "50%",
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "1% 1%",
                    "@media (max-width: 850px)": {
                      width: "85vw",
                    },
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchApplications(searchSectionVariables, 0, 0);
                    }
                  }}
                  id="tags-outlined"
                  options={stickersConfig}
                  value={sticker_code}
                  onChange={(e, newValue) => {
                    getStickerCodes(newValue);
                  }}
                  getOptionLabel={(option) => option.code_description}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "1% 1%",
                      }}
                      {...params}
                      label="Fee Categories"
                      placeholder="Fee Categories"
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  variant="standard"
                  name="fee_status"
                  sx={{
                    backgroundColor: "white",
                    width: "50%",
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "1% 1%",
                    "@media (max-width: 850px)": {
                      width: "85vw",
                    },
                  }}
                  id="tags-outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchApplications(searchSectionVariables, 0, 0);
                    }
                  }}
                  options={allApplicationStatus}
                  value={application_status}
                  onChange={(e, newValue) => {
                    getApplicationStatus(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "1% 1%",
                      }}
                      {...params}
                      label="Application Status"
                      placeholder="Status"
                    />
                  )}
                />
              </Box>
            </div>

            <Box className="side-date">
              <label sx={{ marginTop: "0px" }}>Submission Date Range</label>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="small"
                  id="standard"
                  label="Earliest"
                  value={dateFrom}
                  maxDate={dateTo}
                  inputFormat="MM/DD/YYYY"
                  variant="standard"
                  onChange={(val) => updateDateFrom(val)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchApplications(searchSectionVariables, 0, 0);
                        }
                      }}
                      {...params}
                      style={{
                        width: "200px",
                        color: "#2f67a3",
                        "@media (max-width: 1000px)": {
                          width: "100px",
                          marginLeft: "10px",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="small"
                  id="standard"
                  label="Latest"
                  value={dateTo}
                  minDate={dateFrom}
                  inputFormat="MM/DD/YYYY"
                  variant="standard"
                  onChange={(val) => updateDateTo(val)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          searchApplications(searchSectionVariables, 0, 0);
                        }
                      }}
                      {...params}
                      style={{
                        width: "200px",
                        color: "#2f67a3",
                        "@media (max-width: 1000px)": {
                          width: "100px",
                          marginLeft: "10px",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    color="success"
                    size="small"
                    id="standard"
                    value={dateTo}
                    minDate={dateFrom}
                    label="Latest"
                    inputFormat="MM/DD/YYYY"
                    variant="standard"
                    onChange={(val) => updateDateTo(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "200px", color: "#2f67a3" }}
                      />
                    )}
                  />
                </LocalizationProvider> */}

              <Button
                variant="contained"
                className="clear-filter-button"
                onClick={() => {
                  clearSearchVariables();
                  searchApplications("GetAll", 50, 0);
                }}
               
              >
              
                Clear Filters
              </Button>
            </Box>
          </Card>
        </Box>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button
           variant="contained"
          className="search-button-newapp"

            onClick={() => {
              searchApplications(searchSectionVariables, 0, 0);
            }}
          >
            Search
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationNumber: state.searchSectionVariables.applicationNumber,
  tagNumber: state.searchSectionVariables.tagNumber,
  searchText: state.searchSectionVariables.searchText,
  sticker_code: state.searchSectionVariables.sticker_code,
  application_status: state.searchSectionVariables.application_status,
  dateFrom: state.searchSectionVariables.dateRange.from,
  dateTo: state.searchSectionVariables.dateRange.to,
  feeRange: state.searchSectionVariables.feeRange,
  searchResults: state.searchSectionVariables.searchResults,
  stickersConfig: state.stickersConfig,
  allApplicationStatus: state.allApplicationStatus,
  cognitoResponse: state.userVariables.cognitoResponse,
  searchSectionVariables: state.searchSectionVariables,
  searchFirstTimeLoaded: state.appGlobals.searchFirstTimeLoaded,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchApplications: (text, value, offset) =>
      api.searchApplications(text, value, offset, dispatch),
    clearSearchVariables: () => dispatch({ type: "CLEAR_SEARCH_VARIABLES" }),
    updateApplicationNumber: (val) =>
      dispatch({ type: "UPDATE_APPLICATION_NUMBER", value: val }),
    updateTagNumber: (val) =>
      dispatch({ type: "UPDATE_TAG_NUMBER", value: val }),
    updateDateFrom: (val) => dispatch({ type: "UPDATE_DATE_FROM", value: val }),
    updateDateTo: (val) => dispatch({ type: "UPDATE_DATE_TO", value: val }),
    updateSearchText: (val) =>
      dispatch({ type: "UPDATE_SEARCH_TEXT", value: val }),
    getStickerCodes: (val) => dispatch({ type: "GET_STICKERCODES", data: val }),
    getApplicationStatus: (val) =>
      dispatch({ type: "GET_APPLICATION_STATUS", data: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
