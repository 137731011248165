import React, { Component } from "react";
import { connect } from "react-redux";
import EditDetails from "./EditDetails";
// import Button from '@mui/material/Button'
import { Box } from "@mui/material";
import { allEvents } from "../../statesandlogics/cnstshelpers";

import ExitScreen from "./ExitScreen";

import VisitOfficeScreen from "./VisitOfficeScreen";
import AdminApplicationPreview from "./AdminApplicationPreview";
import NewEditDetails from "./NewEditDetails";
import A00Workflow from "./A00Workflow";

class A00DocEdit extends Component {
  componentWillUnmount() {
    // this.props.resetValidations();
  }
  render() {
    const { rname, allInnerRoutes, setSelectedIdx, editDoc } = this.props;
    let avaialableComps = {
      EditDetails: <EditDetails />,
      NewEditDetails: <NewEditDetails />,

      ExitScreen: <ExitScreen />,

      VisitOfficeScreen: <VisitOfficeScreen />,
      AdminPreviewApplication: <AdminApplicationPreview />,
    };

    let selPages = editDoc.selectPages[editDoc.selectedIdx];
    let selDoc = editDoc.isAdmin
      ? selPages["AdminPage"]
      : editDoc.isViewOnly
      ? selPages["ViewPage"]
      : selPages["NewappPage"];

    let comp = avaialableComps[selDoc];
    return (
      <div>
        <div style={{ marginBottom: "16px" }}>
          <A00Workflow />
        </div>
        <Box sx={{ width: "100%" }}>{comp}</Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  editDoc: state.editDoc,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedIdx: (idx) =>
      dispatch({ type: allEvents.SET_AFB_SEL_IDX, value: idx }),
    resetValidations: () => dispatch({ type: "RESET_VALIDATIONS" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(A00DocEdit);
