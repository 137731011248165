import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as api from "../../statesandlogics/ApiCalls/Api";
import Dropzone from "react-dropzone";

export class SubmitDocumentButton extends Component {
  render() {
    const { totalFiles, preview_uploaded_files, uploaded_files, showFiles,currentDocument } =
      this.props;

    return (
      <>
        <Card
          sx={{
            height: 160,
            backgroundColor: "#162B42",
            borderRadius: 3,
            
            marginBottom: 1,
            "@media (width <= 1400px)": { width: 275 },
            "@media (width>=1400px)": { width: 350 },
          }}
        >
          <CardContent>
            <Typography
              sx={{
                fontSize: 14,
                color: "rgba(255, 255, 255, 0.8)",
                fontFamily: "poppins",
                textAlign: "left",
              }}
              color="text.secondary"
              gutterBottom
            >
              Total Uploaded Documents
            </Typography>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  fontSize: 28,
                  color: "rgba(255, 255, 255, 1)",
                  fontFamily: "poppins",
                  padding: 0,
                }}
              >
                {totalFiles}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "rgba(255, 255, 255, 0.8)",
                  fontFamily: "poppins",
                  textAlign: "left",
                  "@media (width <= 1400px)": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                Click here to submit added documents
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Dropzone
                  onDrop={(e) => this.props.fileDrop(e)}
                  onDropAccepted={(e) => showFiles(e)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <Button
                        {...getRootProps()}
                        variant="contained"
                        disabled={
                          currentDocument.status === "ToBeCreatedApplication" ? false : true
                        }
                        sx={{
                          backgroundColor: "#ddb00a",
                          textTransform: "none",
                          color: "white",
                          whiteSpace: "nowrap",
                          marginTop: "12px",
                          // width: "150px",
                          height: "30px",
                          color: "#162b42",
                          "@media (width <= 1400px)": {
                            width: 120,
                            fontSize: 13,
                          },
                          "@media (width>=1400px)": { width: 150 },
                          "&.Mui-disabled": {
                            background: "#B6A97C",
                            color: "#ebebeb",
                          },
                        }}
                      >
                        Add Documents
                      </Button>
                      <input {...getInputProps()} />
                    </>
                  )}
                </Dropzone>
                <Button
                  variant="contained"
                  disabled={
                    currentDocument.status === "ToBeCreatedApplication" ? false : true
                  }
                  sx={{
                    backgroundColor: "#ddb00a",
                    textTransform: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                    marginTop: "12px",
                    width: "150px",
                    height: "30px",
                    color: "#162b42",
                    minWidth: "120px",
                    "@media (width <= 1400px)": { width: 120, fontSize: 13 },
                    "@media (width>=1400px)": { width: 150 },
                    "&.Mui-disabled": {
                      background: "#B6A97C",
                      color: "#ebebeb",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.uploadDocuments(
                      uploaded_files,
                      preview_uploaded_files
                    );
                  }}
                >
                  Submit Documents
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  preview_uploaded_files: state.UploadPageVariables.preview_uploaded_files,
  uploaded_files: state.UploadPageVariables.uploaded_files,
  currentDocument: state.currentDocument,
});

const mapDispatchToProps = (dispatch) => {
  return {
    showFiles: (val) => dispatch({ type: "SHOW_FILES", value: val }),
    uploadDocuments: (val, val1) => api.uploadDocuments(val, val1, dispatch),
    fileDrop: (val) => api.dropCall(val, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitDocumentButton);
