import { loadApiSetting } from "./ApiCalls/Api";

function get_footer_text() {
  let footerText =""
  // loadApiSetting(() => {});
  if (localStorage.getItem("siteLogoName") !== null) {
    footerText =
      "Stickney Village Hall 6533 W Pershing Rd, Stickney, IL 60402 Phone: (708) 749-4400 - Powered by Quicket Solutions";
  }

  return footerText === "" ? "1 S Dearborn St 20th Floor, Chicago, IL 60603,. Phone Number: (630) 723-7723" : footerText
}

export default get_footer_text;
