import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PetsIcon from "@mui/icons-material/Pets";
import { Button, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LogRocket from "logrocket";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import addCar from "../../images/addCar.png";
import addPet from "../../images/addPet.svg";
import SideDrawer from "./SideDrawer";
import UserCard from "./UserCard";
import { helpers } from "../../statesandlogics/cnstshelpers";
import Dropzone from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import RecordPaymentButton from "./RecordPaymentButton";
import SubmitDocumentButton from "./SubmitDocumentButton";
import { vehicleLateFee } from "./AdminApplicationPreview";
import { petLateFee } from "./AdminApplicationPreview";
class EditDetails extends Component {
  componentDidMount() {
    const { currentDocument } = this.props;
    // LogRocket.identify(currentDocument.applicant.email);
    if (this.props.currentDocument.application_id !== null) {
      this.props.getUploadedDocs(this.props.currentDocument.application_id);
    }
  }

  componentWillUnmount() {
    this.props.closeSidebar();
    this.props.resetValidations();
  }

  render() {
    const {
      removePetSticker,
      psApplicationTab,
      vsApplicationTab,
      showFiles,
      showAlert,
      setCurrentlyEditingVehicleData,
      removeVehicleSticker,
      vehicles,
      showStickerAlert,
      pets,
      preview_uploaded_files,
      files,
      uploaded_files,
      updateVehicleData,
      updatePetData,
      updateApplication,
      loadApplicationPage,
      updateStep,
      deleteSticker,
      currentDocument,
      application_id,
      userIsAdmin,
      removeDocument,
      setCurrentlyEditingVehicle,
      setCurrentlyEditingPet,
      editDoc,
    } = this.props;

    let vehicleFee = 0;
    let petFee = 0;
    vehicles.map((v) => {
      if (v.late_fee_applied && v.prorated_fee_applied) {
        vehicleFee += (v.sticker_code.fee + 20) / 2;
      } else if (v.late_fee_applied) {
        vehicleFee += v.sticker_code.fee + 20;
      } else if (v.prorated_fee_applied) {
        vehicleFee += v.sticker_code.fee / 2;
      } else {
        vehicleFee += v.sticker_code.fee;
      }
    });

    pets.map((p) => {
      if (p.late_fee_applied && p.prorated_fee_applied) {
        petFee += (p.sticker_code.fee + 5) / 2;
      } else if (p.late_fee_applied) {
        petFee += p.sticker_code.fee + 5;
      } else if (p.prorated_fee_applied) {
        petFee += p.sticker_code.fee / 2;
      } else {
        petFee += p.sticker_code.fee;
      }
    });
    let totalFees = vehicleFee + petFee;

    return (
      <>
        <Dropzone
          onDrop={(e) => this.props.fileDrop(e)}
          onDropAccepted={(e) => showFiles(e)}
          noClick
          noDrag={editDoc.isDocumentViewOnly}
        >
          {({ getRootProps, isDragAccept }) => (
            <div
              style={
                isDragAccept
                  ? {
                      border: "2px dashed #2957A3",
                      backgroundColor: "#2957A312",
                      minHeight: "75vh",
                    }
                  : {}
              }
              {...getRootProps()}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UserCard />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "@media (width <= 1000px)": {
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  marginRight: "5vw",
                  marginLeft: "5vw",

                  alignItems: "center",
                }}
              >
                <Card
                  sx={{
                    height: 160,
                    backgroundColor: "#046E9E",
                    borderRadius: 3,
                    marginRight: 1,
                    marginBottom: 1,
                    "@media (width <= 1400px)": { width: 275 },
                    "@media (width>=1400px)": {
                      width: 350,
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "poppins",
                        }}
                        color="text.secondary"
                      >
                        Vehicles added
                      </Typography>

                      <img
                        src={addCar}
                        className="addcar-vehicle"
                        alt="Add Pet"
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography
                        sx={{
                          fontSize: 28,
                          color: "rgba(255, 255, 255, 1)",
                          fontFamily: "poppins",
                          padding: 0,
                        }}
                      >
                        {vehicles.length}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "poppins",
                          textAlign: "left",
                        }}
                      >
                        Click here to add vehicle stickers
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {editDoc.isCitizenViewOnly === true ? null : (
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              color: "white",
                              marginTop: "12px",
                              width: "150px",
                              height: "30px",
                              fontSize: "12",
                              color: "#162b42",
                              backgroundColor: "#ddb00a",
                              "&.Mui-disabled": {
                                background: "#B6A97C",
                                color: "#ebebeb",
                              },
                            }}
                            disabled={
                              currentDocument.status ===
                              "ToBeCreatedApplication"
                                ? false
                                : totalFees === 0 &&
                                  currentDocument.status === "Pending"
                                ? false
                                : totalFees ===
                                  currentDocument.transaction_info
                                    .payment_credit
                            }
                            onClick={() => {
                              // updateNewVehicleData("initialAdd");
                              setCurrentlyEditingVehicle("initialAdd");
                              this.props.openSidebar("vehicle");
                            }}
                          >
                            Add Vehicles
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    height: 160,
                    backgroundColor: "#367974",
                    borderRadius: 3,
                    marginRight: 1,
                    marginBottom: 1,
                    "@media (width <= 1400px)": { width: 275 },
                    "@media (width>=1400px)": {
                      width: 350,
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "poppins",
                        }}
                        color="text.secondary"
                      >
                        Pets Added
                      </Typography>

                      <img
                        src={addPet}
                        className="addcar-vehicle"
                        alt="Add Pet"
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography
                        sx={{
                          fontSize: 28,
                          color: "rgba(255, 255, 255, 1)",
                          fontFamily: "poppins",
                          padding: 0,
                        }}
                      >
                        {pets.length}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "poppins",
                          textAlign: "left",
                        }}
                      >
                        Click here to add pet stickers
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {editDoc.isCitizenViewOnly === true ? null : (
                          <Button
                            variant="contained"
                            disabled={
                              currentDocument.status ===
                              "ToBeCreatedApplication"
                                ? false
                                : totalFees === 0 &&
                                  currentDocument.status === "Pending"
                                ? false
                                : totalFees ===
                                  currentDocument.transaction_info
                                    .payment_credit
                            }
                            sx={{
                              textTransform: "none",
                              color: "white",
                              marginTop: "12px",
                              width: "150px",
                              height: "30px",
                              color: "#162b42",
                              backgroundColor: "#ddb00a",
                              "&.Mui-disabled": {
                                background: "#B6A97C",
                                color: "#ebebeb",
                              },
                              fontSize: "12",
                            }}
                            onClick={(e) => {
                              // setPetIndex(idx);
                              e.preventDefault();
                              // updatePetData("initialAdd");
                              setCurrentlyEditingPet("initialAdd");
                              this.props.openSidebar("pet");
                            }}
                          >
                            Add Pet
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>

                <SubmitDocumentButton totalFiles={uploaded_files.length} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 3,
                  marginRight: "5vw",
                  marginLeft: "5vw",
                  marginBottom: "16px",
                  "@media (width <= 725px)": {
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  "@media (max-width: 400px)": {
                    marginLeft: 1,
                    justifyContent: "center",
                  },

                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                {vehicles.map(
                  (vehicle, idx) => (
                    // vehicle.sticker_code.fee === null ? null : (
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "none",
                        paddingLeft: 0,
                      }}
                      onClick={() => {
                        setCurrentlyEditingVehicle(vehicle.vehicle_id);
                        this.props.openSidebar("vehicle");
                      }}
                    >
                      <Card sx={vsApplicationTab}>
                        <CardContent
                          sx={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <DirectionsCarIcon />
                            {/* </Box> */}
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 20,
                                fontFamily: "poppins",
                                fontWeight: 600,
                              }}
                            >
                              {`${helpers.upperCaseNullHandler(
                                vehicle.make
                              )} ${helpers.upperCaseNullHandler(
                                vehicle.model
                              )}`}
                            </Typography>
                            <IconButton
                              hidden={
                                currentDocument.status ===
                                "ToBeCreatedApplication"
                                  ? false
                                  : totalFees === 0 &&
                                    currentDocument.status === "Pending"
                                  ? false
                                  : totalFees ===
                                    currentDocument.transaction_info
                                      .payment_credit
                              }
                              // sx={{
                              //   position: "absolute",
                              //   "@media (max-width: 400px)": {
                              //     right: 5,
                              //   },
                              //   top: 2,
                              //   right: 40,
                              // }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (vehicle.vehicle_id !== null) {
                                  // deleteSticker(
                                  //   currentDocument.application_id,
                                  //   vehicle.vehicle_id,
                                  //   "vehicle"
                                  // );
                                }
                                removeVehicleSticker(vehicle.vehicle_id);
                              }}
                            >
                              {currentDocument.status ===
                              "ToBeCreatedApplication" ? (
                                <CloseIcon />
                              ) : totalFees === 0 &&
                                currentDocument.status === "Pending" ? (
                                <CloseIcon />
                              ) : totalFees ===
                                currentDocument.transaction_info
                                  .payment_credit ? null : (
                                <CloseIcon />
                              )}
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                              }}
                            >
                              {helpers.upperCaseNullHandler(
                                vehicle.plate_number
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                              }}
                            >
                              {vehicle.model_year}
                            </Typography>

                            <Typography
                              sx={{
                                width: "100%",
                                // alignItems:"left",
                                // justifyContent:"left",
                                textAlign: "left",
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                              }}
                            >
                              {helpers.upperCaseNullHandler(
                                vehicle.sticker_code.code_description
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                marginRight: 1,
                              }}
                            >
                              {"$ " +
                                helpers.formatFee(
                                  vehicle.late_fee_applied &&
                                    vehicle.prorated_fee_applied
                                    ? (vehicle.sticker_code.fee +
                                        vehicleLateFee) /
                                        2
                                    : vehicle.late_fee_applied
                                    ? vehicle.sticker_code.fee + vehicleLateFee
                                    : vehicle.prorated_fee_applied
                                    ? vehicle.sticker_code.fee / 2
                                    : vehicle.sticker_code.fee
                                )}
                            </Typography>

                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                              }}
                            >
                              {helpers.upperCaseNullHandler(vehicle.color)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Button>
                  )
                  // )
                )}
                {showAlert === true ? (
                  <Alert
                    severity="warning"
                    variant="filled"
                    onClose={() => {
                      showStickerAlert(false);
                    }}
                    sx={{
                      position: "absolute",
                      top: 70,
                      right: 0,
                    }}
                  >
                    Sticker Limit reached!
                  </Alert>
                ) : null}
                {pets.map(
                  (pet, idx) => (
                    // pet.sticker_code.fee === null ? null : (
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "none",
                        paddingLeft: 0,
                      }}
                      onClick={() => {
                        setCurrentlyEditingPet(pet.pet_id);
                        this.props.openSidebar("pet");
                      }}
                    >
                      <Card sx={psApplicationTab}>
                        <CardContent sx={{ padding: 0, border: "2px #367974" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <PetsIcon />
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 20,
                                fontFamily: "poppins",
                                fontWeight: 600,
                              }}
                            >
                              {helpers.upperCaseNullHandler(pet.name)}
                            </Typography>
                            <IconButton
                              // sx={{
                              //   position: "absolute",
                              //   "@media (max-width: 400px)": {
                              //     right: 5,
                              //   },
                              //   top: 2,
                              //   right: 40,
                              // }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (pet.pet_id !== null) {
                                  // deleteSticker(
                                  //   currentDocument.application_id,
                                  //   pet.pet_id,
                                  //   "pet"
                                  // );
                                }
                                removePetSticker(pet.pet_id);
                              }}
                            >
                              {currentDocument.status ===
                              "ToBeCreatedApplication" ? (
                                <CloseIcon />
                              ) : totalFees === 0 &&
                                currentDocument.status === "Pending" ? (
                                <CloseIcon />
                              ) : totalFees ===
                                currentDocument.transaction_info
                                  .payment_credit ? null : (
                                <CloseIcon />
                              )}
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {helpers.upperCaseNullHandler(pet.breed)}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {helpers.upperCaseNullHandler(pet.sex)}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {helpers.upperCaseNullHandler(pet.color)}
                            </Typography>
                            <Typography
                              sx={{
                                width: "100%",
                                textAlign: "left",
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {pet.rabies_certificate_expiry}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {pet.rabies_certificate_number}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                // marginRight: 1,
                              }}
                            >
                              {helpers.upperCaseNullHandler(
                                pet.sticker_code.code_description
                              )}
                            </Typography>

                            <Typography
                              sx={{
                                color: "#3C385E",
                                fontSize: 16,
                                fontFamily: "poppins",
                                fontWeight: 400,
                                marginRight: 1,
                              }}
                            >
                              {"$ " +
                                helpers.formatFee(
                                  pet.late_fee_applied &&
                                    pet.prorated_fee_applied
                                    ? (pet.sticker_code.fee + petLateFee) / 2
                                    : pet.late_fee_applied
                                    ? pet.sticker_code.fee + petLateFee
                                    : pet.prorated_fee_applied
                                    ? pet.sticker_code.fee / 2
                                    : pet.sticker_code.fee
                                )}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Button>
                  )
                  // )
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    margin: "0vh 0",
                    display: "flex",
                    // height: "18vh",
                    width: "90vw",
                    flexWrap: "wrap",
                    // padding:1,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    // overflowY: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      margin: 0.5,
                      marginTop: -2,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: "inline",
                        fontSize: 18,
                        textAlign: "left",
                      }}
                      color="text.primary"
                    >
                      Files
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        display: "inline",
                        fontSize: 18,
                        textAlign: "left",
                        marginLeft: 5,
                        color: "red",
                        fontWeight: 600,
                      }}
                      color="text.primary"
                    >
                      {files.length === 0 && preview_uploaded_files.length === 0
                        ? 'Please upload the files before creating the application and hit "Submit Documents" button. Once the application is created uploading of documents is not allowed.'
                        : null}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {/* {editDoc.isDocumentViewOnly ? null : (
                      <Box
                        sx={{
                          margin: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Card raised sx={{ borderRadius: 20 }}>
                          <CardContent
                            sx={{
                              backgroundColor: "#162B42",
                              color: "#fff",
                              padding: 1,
                              "&:last-child": {
                                paddingBottom: 1,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                padding: 0,
                                backgroundColor: "#162B42",
                                color: "#fff",
                              }}
                            >
                              <Box
                                sx={{
                                  // width: 40,
                                  height: 20,
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  backgroundColor: "#162B42",
                                  color: "#fff",
                                }}
                              ></Box>
                              <Box>
                                <Dropzone
                                  onDrop={(e) => this.props.fileDrop(e)}
                                  onDropAccepted={(e) => showFiles(e)}
                                  noDrag
                                >
                                  {({ getRootProps }) => (
                                    <IconButton {...getRootProps()}>
                                      <AddIcon
                                        fontSize="medium"
                                        sx={{
                                          backgroundColor: "#162B42",
                                          color: "#fff",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </Dropzone>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    )} */}
                    {preview_uploaded_files.length === 0
                      ? null
                      : preview_uploaded_files.map((file) => (
                          <>
                            <Box
                              sx={{
                                margin: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                padding: 0,
                              }}
                            >
                              <Card raised sx={{ borderRadius: 2 }}>
                                <CardContent
                                  sx={{
                                    padding: 1,
                                    "&:last-child": {
                                      paddingBottom: 1,
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      flexDirection: "row",
                                      padding: 0,
                                    }}
                                  >
                                    <Tooltip
                                      title={file.filename}
                                      placement="bottom"
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          display: "inline",
                                          fontSize: 13,
                                          textAlign: "left",
                                          width: "80%",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        color="text.secondary"
                                      >
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {file.filename}
                                        </p>
                                      </Typography>
                                    </Tooltip>

                                    <Box>
                                      <IconButton
                                        onClick={() =>
                                          saveAs(
                                            file.presigned_url,
                                            "filename.pdf"
                                          )
                                        }
                                      >
                                        <DownloadIcon
                                          fontSize="small"
                                          sx={{ color: "#162B42" }}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </>
                        ))}
                    {files.length === 0
                      ? null
                      : files.map((file, idx) => (
                          <>
                            <Box
                              sx={{
                                margin: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                padding: 0,
                              }}
                            >
                              <Card raised sx={{ borderRadius: 2 }}>
                                <CardContent
                                  sx={{
                                    padding: 1,
                                    "&:last-child": {
                                      paddingBottom: 1,
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      flexDirection: "row",
                                      padding: 0,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "60%",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        padding: 1.5,
                                      }}
                                    >
                                      <Tooltip title={file} placement="bottom">
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            display: "inline",
                                            fontSize: 13,
                                            textAlign: "left",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                          // className="in-progress"
                                          color="text.secondary"
                                        >
                                          {file}
                                        </Typography>
                                      </Tooltip>
                                    </Box>

                                    <Box>
                                      <div
                                        id={file}
                                        // className="progress-bar"
                                      ></div>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeDocument(idx, files);
                                          // removeVehicleSticker(idx);
                                        }}
                                      >
                                        <CloseIcon />
                                        {/* <DeleteIcon/> */}
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </>
                        ))}
                  </Box>
                  {/* <DocumentUpload /> */}
                </Box>
              </Box>

              <SideDrawer />
            </div>
          )}
        </Dropzone>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  psApplicationTab: state.appGlobals.stickercardstyles.psApplicationTab,
  vsApplicationTab: state.appGlobals.stickercardstyles.vsApplicationTab,

  sidebarOpen: state.sidebarOpen,
  vehicles: state.currentDocument.vehicles,
  pets: state.currentDocument.pets,
  currentDocument: state.currentDocument,
  username: state.username,
  userIsAdmin: state.userIsAdmin,
  showAlert: state.showAlert,
  application_id: state.userVariables.application_id,
  editDoc: state.editDoc,
  preview_uploaded_files: state.UploadPageVariables.preview_uploaded_files,
  uploaded_files: state.UploadPageVariables.uploaded_files,
  files: state.UploadPageVariables.uploaded_files,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetValidations: () => dispatch({ type: "RESET_VALIDATIONS" }),
    setCurrentlyEditingVehicle: (val) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_VEHICLE",
        value: val,
      }),
    setCurrentlyEditingPet: (val) =>
      dispatch({
        type: "SET_CURRENTLY_EDITING_PET",
        value: val,
      }),
    deleteSticker: (application_id, sticerId, stickerType) =>
      api.deleteSticker(application_id, sticerId, stickerType),
    removePetSticker: (val) =>
      dispatch({ type: "REMOVE_PET_STICKER", value: val }),
    removeVehicleSticker: (val) =>
      dispatch({ type: "REMOVE_VEHICLE_STICKER", value: val }),
    setVehicleIndex: (val) =>
      dispatch({ type: "SET_VEHICLE_INDEX", value: val }),
    setPetIndex: (val) => dispatch({ type: "SET_PET_INDEX", value: val }),
    updateVehicleData: (val, val2) =>
      dispatch({ type: "UPDATE_NEW_VEHICLE_DATA", value: val, value2: val2 }),
    updatePetData: (val) => dispatch({ type: "UPDATE_PET_DATA", value: val }),
    openSidebar: (val) =>
      dispatch({ type: "OPEN_SIDEBAR", value: true, click: val }),
    uploadDocuments: (val, val1) => api.uploadDocuments(val, val1, dispatch),
    closeSidebar: (val) =>
      dispatch({ type: "CLOSE_SIDEBAR", value: false, click: val }),
    updateStep: () => dispatch({ type: "UPDATE_STEP" }),
    updateApplication: (val, val2) =>
      api.updateApplication(val, val2, dispatch),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    showStickerAlert: (val) =>
      dispatch({ type: "SHOW_STICKER_ALERT", value: val }),
    showFiles: (val) => dispatch({ type: "SHOW_FILES", value: val }),
    fileDrop: (val) => api.dropCall(val, dispatch),
    removeDocument: (val, files) => api.removeDocument(val, files, dispatch),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);
