/* eslint-disable react/prop-types */
/* eslint-disable no-dupe-keys */
import React from "react";
import { connect } from "react-redux";
// import MainContent from "./MainContent";
// import AnalyticsIcon from "@mui/icons-material/Analytics";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import * as s from "../../statesandlogics/sxs";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { allEvents } from "../../statesandlogics/cnstshelpers";

const listItems = [
  {
    listIcon: <HomeIcon sx={{ color: "white" }} />,
    listText: "Home",
    listItems: [],
    val: "Home",
  },
  {
    listIcon: <AddBoxIcon sx={{ color: "white" }} />,
    listText: "New Application",
    listItems: [],
    val: "Register",
  }
];

let HomeButton = ({ showApplication, text, createApp }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate("/villageStickerApplicationAdmin/Applications");
  return (
    <Button
      variant="contained"
      sx={{marginTop: "7%",background: "#162B42",color:"#fff", textTransform:"none" }}
      // className="navbar-buttons-fp"
      onClick={() => {
        showApplication(false);
        handleClick();
      }}
      component={Link}
      to={"/villageStickerApplicationAdmin/Applications"}
    >
      <HomeIcon sx={{ color: 'white', mr:2 }} />
      {text}
    </Button>
  );
};

let NewAppButton = ({ showApplication, text, createApp }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate("/villageStickerApplicationAdmin/Applications/NewApplication");
  return (
    <Button
      variant="contained"
      sx={{marginTop: "7%",background: "#162B42",color:"#fff", textTransform:"none" }}
      // className="navbar-buttons-fp"
      onClick={() => {
        showApplication(true);
        handleClick();
        createApp();
      }}
    >
      <AddBoxIcon sx={{ color: 'white', mr:2 }} />
      {text}
    </Button>
  );
};

let TransactionReportButton = ({ text, showApplication }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate(
      "/villageStickerApplicationAdmin/Applications/GenerateTransactionReport"
    );
  return (
    <Button
      variant="contained"
      sx={{marginTop: "7%",background: "#162B42",color:"#fff", textTransform:"none" }}
      // className="navbar-buttons-fp"
      onClick={() => {
        showApplication(true);
        handleClick();
      }}
    >
      <AssessmentIcon sx={{ color: 'white', mr:2 }} />
      {text}
    </Button>
  );
};

class DrawerComponent extends React.Component {
  render() {
    const { loadCurrentPage, isOpen, setExpand, showApplication, createApp } =
      this.props;

    const sideList = () => (
      <Box
        sx={s.SXs.DrawerComponent.SX1}
        role="presentation"
        onClick={() => setExpand(false)}
      >
        <Divider />
        <Box sx={{}}>
          <HomeButton
            showApplication={showApplication}
            text={listItems[0].listText}
          />
        </Box>
        <Box sx={{}}>
          <NewAppButton
            showApplication={showApplication}
            text={listItems[1].listText}
            createApp={createApp}
          />
        </Box>
        <Box sx={{}}>
          <TransactionReportButton
            showApplication={showApplication}
            text={"Generate Transaction Report"}
          />
        </Box>
        <Divider />
      </Box>
    );
    return (
      <>
        <Box sx={s.SXs.DrawerComponent.SX4}>
          <div>
            <Drawer
              open={isOpen}
              onClose={() => setExpand(false)}
              onOpen={() => setExpand(true)}
            >
              {sideList()}
            </Drawer>
            <IconButton
              sx={s.SXs.DrawerComponent.SX5}
              onClick={() => setExpand(true)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  officerName: state.leftSidebarVariables.username,
  isOpen: state.leftSidebarVariables.isOpen,
  isLoggedIn: state.appGlobals.isLoggedIn,
  transactionReportDate: state.transactionReportDate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTransactionReportDate: (val) =>
      dispatch({ type: "SET_TRANSACTION_REPORT_DATE", val: val }),
    generateTransactionReport: (val) =>
      api.generateTransactionReport(val, dispatch),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    loadCitizenCurrentPage: (text) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: text }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    setExpand: (val) => dispatch({ type: "SET_EXPAND", value: val }),
    setUser: () => dispatch({ type: "SET_USER" }),
    createApp: () =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", apiData: "Nothing" }),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
