import React, { Component } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ChaletIcon from "@mui/icons-material/Chalet";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PaymentsIcon from "@mui/icons-material/Payments";
import { allEvents } from "../../../statesandlogics/cnstshelpers";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


class CardComponentNew extends Component {
  render() {
    const { createCitizenRenewalApplication, showCitizenStickers, citizenApp } =
      this.props;
    let mApp =
      // citizenApp
      //   ? citizenApp
      //   :
      {
        applicant: {
          applicant_id: 2,
          family_name: "Sonal",
          first_name: "sonal",
          address_id: 2,
          phone_number: "(990) 122-4144",
          secondary_phone_number: null,
          email:"xyz@gmail.com",
       
          address: {
            address_id: 2,
            address_line_1: "ADDRESS LINE ONE",
            address_line_2: "ADDRESS LINE TWO",
            city: "BIG",
            postal_code: "123245",
            state: { state_id: 14, abbreviation: "Illinois", full_name: "IL" },
          },
        },
      };
    return (
        <Box>
        <Typography
          sx={{
            color: "black",
            fontSize: 28,

            fontWeight: 600,
            marginBottom: 1,
            marginLeft: 2,
          }}
        >
          Applicant Details
        </Typography>
        <Card className="rowcard-cc">
          <div>
            <div className="first_row">
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <AccountCircle className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Name : {mApp.applicant.first_name}{" "}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <LocalPhoneOutlinedIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Phone :{mApp.applicant.phone_number}{" "}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <EmailOutlinedIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Email : {mApp.applicant.email}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper address_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <HomeWorkOutlinedIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Address : {mApp.applicant.address.address_line_1}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="second_row">
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <ApartmentIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          
                      
                          City : {mApp.applicant.address.city}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <LocalShippingIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                        Postal Code :{mApp.applicant.address.postal_code}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <ChaletIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                        State : {mApp.applicant.address.state.abbreviation}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <MonetizationOnIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Fee Category :
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  className="input_wrapper"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          className="create_icons"
                          variant="contained"
                        >
                          <PaymentsIcon className="input_icon" />
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Total Fees :
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              
             
            </div>
          </div>

          


        </Card>
        <div
            style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
          >
            <Button
              sx={{
                backgroundColor: "#162B42",

                margin: "1% 25% ",
                width: "50%",
                "&:hover": { backgroundColor: "#0C325B" },
              }}
             
              className="createappbutton"
                variant="contained"
                component={Link}
                to="/villageStickerApplication/Stickers/CurrentApplication"
                onClick={(e) => {
                  e.preventDefault()
                  createCitizenRenewalApplication();
                  showCitizenStickers(true)
                }}
            >
              <Typography sx={{ color: "white" }}> Create Application</Typography>
            </Button>
          </div>
      </Box>
    );
  }
}
const mapStateToProps = (state) => ({
  paymentIsOnline: state.applicationVariables.paymentIsOnline,
  //   citizenApp: state.temp[0],
});
const mapDispatchToProps = (dispatch) => {
  return {
    createCitizenRenewalApplication: () =>
      dispatch({ type: allEvents.citizen.LOAD_CITIZEN_APPLICATION_CREATE }),
    showCitizenStickers: (val) =>
      dispatch({ type: allEvents.SHOW_CITIZEN_STICKERS, value: val }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CardComponentNew);