import * as cnsts from "./cnstshelpers";
import { initState } from "./initstate";
import {
  validateUserData,
  clearSearchVariables,
  addVehicleIntoCurrentDocument,
  createCitizenApplication,
  loadAdminHome,
  loadApplicationLogin,
  setTransactionReportDate,
  loadApplicationPage,
  loadCitizenPage,
  loadTestData,
  removePetSticker,
  removeVehicleSticker,
  setApplicationType,
  setBillingAddress,
  setClientSecret,
  setCurrentPage,
  setCurrentUser,
  setExpand,
  setOfficerName,
  setPaymentMode,
  setPetData,
  setPetIndex,
  setProfileMenu,
  setUser,
  setUserLogin,
  setVehicleData,
  setVehicleIndex,
  showFiles,
  updateAdminPassword,
  updateAdminUsername,
  updateApplicationNumber,
  updateApplicationStatus,
  updateDateFrom,
  updateDateTo,
  updateDocumentStatus,
  updateFees,
  updatePetData,
  updateVehicleData,
  updatePaymentStatus,
  updatePetFeeCategory,
  updatePetTagNumber,
  updateSearchText,
  updateStickerAppStatus,
  updateTagNumber,
  updateTransactionDetails,
  updateUserAddress,
  updateUserEmail,
  updateUserFname,
  updateUserLname,
  updateUserPhone,
  updateUsername,
  updateVehicleTagNumber,
  setCurrentlyEditingVehicleData,
  setCurrentlyEditingVehicle,
  editVehicleIntoCurrentDocument,
  setCurrentlyEditingPetData,
  setCurrentlyEditingPet,
  editPetIntoCurrentDocument,
  addPetIntoCurrentDocument,
  updateSearchOffset,
  updateSearchResultLimit,
  updatePetStickerInfo,
  updateVehicleStickerInfo,
  resetValidations,
  setGenerateReportMode,
  setTransactionReportEndDate,
  applyVehicleLateFee,
  applyPetLateFee,
  applyVehicleProratedFee,
  applyPetProratedFee,
} from "./brls";
import { allEvents } from "../statesandlogics/cnstshelpers";
import { ConstructionOutlined } from "@mui/icons-material";

export const appreducer = (ostate = initState, action) => {
  let nstate = cnsts.helpers.JPJS(ostate);
  switch (action.type) {
    case "GET_FEE_SCHEDULE":
      nstate.appGlobals.current_period = { ...action.value };
      if (nstate.appGlobals.current_period.late_period.active) {
        nstate.SelectedPetTemplate.late_fee_applied = true;
        nstate.SelectedVehicleTemplate.late_fee_applied = true;
      }
      break;
    case "APPLY_PET_PRORATED_FEE":
      applyPetProratedFee(nstate, action.value);
      break;
    case "APPLY_VEHICLE_PRORATED_FEE":
      applyVehicleProratedFee(nstate, action.value);
      break;
    case "APPLY_PET_LATE_FEE":
      applyPetLateFee(nstate, action.value);
      break;
    case "APPLY_VEHICLE_LATE_FEE":
      applyVehicleLateFee(nstate, action.value);
      break;
    case "TOGGLE_MODAL":
      nstate.isModalOpen = action.value;
      break;
    case "SET_TRANSACTION_REPORT_END_DATE":
      setTransactionReportEndDate(nstate, action.val);
      break;
    case "SET_GENERATE_REPORT_MODE":
      setGenerateReportMode(nstate, action.val);
      break;
    case "RESET_VALIDATIONS":
      resetValidations(nstate);
      break;
    case "VALIDATE_USER_DATA":
      validateUserData(nstate);
      break;
    case "UPDATE_VEHICLE_STICKER_INFO":
      updateVehicleStickerInfo(nstate, action);
      break;
    case "UPDATE_PET_STICKER_INFO":
      updatePetStickerInfo(nstate, action);
      break;
    case "UPDATE_UPLOADED_FILES":
      nstate.UploadPageVariables.uploaded_files = action.value;
      break;
    case "UPDATE_PAGE":
      nstate.searchSectionVariables.page = action.value;
      break;
    case "SET_CURRENTLY_EDITING_VEHICLE_DATA":
      setCurrentlyEditingVehicleData(nstate, action.value, action.value2);
      break;
    case "SET_CURRENTLY_EDITING_VEHICLE":
      setCurrentlyEditingVehicle(nstate, action.value);
      break;
    case "SET_CURRENTLY_EDITING_PET_DATA":
      setCurrentlyEditingPetData(nstate, action.value, action.value2);
      break;
    case "SET_CURRENTLY_EDITING_PET":
      setCurrentlyEditingPet(nstate, action.value);
      break;
    case allEvents.ADMIN_LOGIN_CLICK:
      nstate.appGlobals.isLoggedIn = true;
      break;

    case allEvents.SHOW_APPLICATION_CLICK:
      nstate.appGlobals.showApplication = action.value;
      // nstate.editDoc.isViewOnly = false;
      break;
    case allEvents.SET_CURRENT_DOCUMENT_STATUS_TO_NEW:
      nstate.currentDocument.application_id = null;
      nstate.currentDocument.status = "ToBeCreatedApplication";
      nstate.currentDocument.transaction_info = {
        payment_credit: 0,
        payment_types: [],
      };
      nstate.currentDocument.pets.map((p) => {
        p.late_fee_applied =
          nstate.appGlobals.current_period.late_period.active;
      });
      nstate.currentDocument.vehicles.map((v) => {
        v.late_fee_applied =
          nstate.appGlobals.current_period.late_period.active;
      });
      break;
    case allEvents.SHOW_CITIZEN_STICKERS:
      nstate.appGlobals.showCitizenApplication = action.value;
      break;
    case allEvents.SHOW_CITIZEN_APPLICATION:
      nstate.appGlobals.showCitizenApplication = action.value;
      break;
    case allEvents.SET_AFB_SEL_IDX:
      nstate.editDoc.selectedIdx = action.value;
      break;
    case allEvents.application.UPDATE_APPLICATION_ID:
      nstate.appGlobals.isCurrentUserDataValid = null;
      nstate.appGlobals.isAddingOrEditingPetRecordValid = null;
      nstate.appGlobals.isAddingOrEditingVehicleRecordValid = null;
      nstate.currentUserValidations = {
        family_name: {
          error: false,
          errorMsg: "",
        },
        first_name: {
          error: false,
          errorMsg: "",
        },
        phone_number: {
          error: false,
          errorMsg: "",
        },
        email: {
          error: false,
          errorMsg: "",
        },
        address: {
          address_line_1: {
            error: false,
            errorMsg: "",
          },
          city: {
            error: false,
            errorMsg: "",
          },
          postal_code: {
            error: false,
            errorMsg: "",
          },
          state_id: {
            error: false,
            errorMsg: "",
          },
        },
      };
      nstate.currentlyEditingVehicleValidations = {
        plate_number: {
          error: false,
          errorMsg: "",
        },
        make: {
          error: false,
          errorMsg: "",
        },
        model: {
          error: false,
          errorMsg: "",
        },
        color: {
          error: false,
          errorMsg: "",
        },
        model_year: {
          error: false,
          errorMsg: "",
        },
        sticker_code_description: {
          error: false,
          errorMsg: "",
        },
      };
      nstate.currentlyEditingPetValidations = {
        name: {
          error: false,
          errorMsg: "",
        },
        breed: {
          error: false,
          errorMsg: "",
        },
        color: {
          error: false,
          errorMsg: "",
        },
        sex: {
          error: false,
          errorMsg: "",
        },
        rabies_certificate_number: {
          error: false,
          errorMsg: "",
        },
        rabies_certificate_expiry: {
          error: false,
          errorMsg: "",
        },
        sticker_code_description: {
          error: false,
          errorMsg: "",
        },
      };
      nstate.currentDocument.application_id = action.value;
      nstate.currentDocument.status = "Pending";
      break;
    case allEvents.application.UPDATE_APPLICATION_STATUS:
      nstate.currentDocument.status = action.value;
      break;
    case "REMOVE_DOC":
      nstate.UploadPageVariables.uploaded_files = action.value;
      break;

    case "CREATE_APP":
      nstate.currentDocument = {
        submission_date: "",
        application_id: null,
        status: "ToBeCreatedApplication",
        applicant: {
          applicant_id: null,
          family_name: "",
          first_name: "",
          phone_number: "",
          email: "",
          address: {
            address_id: null,
            address_line_1: "",
            address_line_2: "",
            city: "",
            postal_code: "",
            state: {
              state_id: null,
              abbreviation: "",
              full_name: "",
            },
          },
        },

        pets: [
          {
            tag_number: null,
            pet_id: null,
            name: "",
            breed: "",
            color: "",
            sex: "",
            rabies_certificate_number: "",
            rabies_certificate_expiry: "",
            sticker_tag_number: "",
            sticker_tag_expiry: "2024-06-30",
            sticker_code: {
              code_id: null,
              code_description: "",
              fee: null,
              // formatted_fee:"",
              code_type: "",
            },
          },
        ],

        vehicles: [
          {
            tag_number: null,
            vehicle_id: null,
            plate_number: "",
            make: "",
            model: "",
            color: "",
            model_year: null,
            sticker_tag_number: "",
            sticker_tag_expiry: "2024-06-30",
            sticker_code: {
              code_id: null,
              code_description: "",
              fee: null,
              // formatted_fee:"",
              code_type: "",
            },
          },
        ],
        documents: [
          {
            filepath: "",
            upload_completed: true,
            pet_id: 0,
            vehicle_id: 0,
            session_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            document_id: 0,
          },
        ],
        document_session_id: "",
        citizen_user_id: null,
        paymentAddress: {
          billingAddress: "",
          city: "",
          stat: "",
          zipCode: "",
        },
      };
      break;
    case "USERNAME_VALIDITY_CHECK":
      const isEmail = nstate.userVariables.usernameIsEmail;
      if (isEmail === true) {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(action.value);
        nstate.userVariables.emailError = !isValid;
        nstate.userVariables.usernameError = nstate.userVariables.emailError;
        nstate.userVariables.emailHelperText = isValid
          ? ""
          : "Please enter a valid email address";
      } else {
        let l = action.value.length;
        nstate.userVariables.phoneNumberError = l < 14 ? true : false;
        nstate.userVariables.phoneNumberHelperText =
          l < 14 ? "Please enter a valid phone number" : "";
        nstate.userVariables.usernameError =
          nstate.userVariables.phoneNumberError;
      }
      break;
    case "VALIDATE_EMAIL":
      const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      nstate.SelectionCurrentDocument.isValidEmail =
        action.value !== "" && !EMAIL_REGEX.test(action.value);

      break;

    case "SHOW_UPLOADED_DOCS":
      nstate.UploadPageVariables.preview_uploaded_files =
        action.apiData.documents;
      break;
    case "DOWNLOAD_DOCUMENT":
      //
      break;
    case "UPDATE_TRANSACTION_DETAILS":
      updateTransactionDetails(nstate, action.value);
      break;
    case "UPDATE_STICKER_APP_STATUS":
      updateStickerAppStatus(nstate, action.value);
      break;
    case "LOAD_ADMIN_IDENTITY":
      nstate.appGlobals.adminIdentity = action.value;
      break;
    case "LOAD_REGIONS_DATA":
      nstate.regionsConfig = [...action.value];
      break;
    case "UPDATE_DOC_SESSION_ID":
      nstate.currentDocument.document_session_id = action.value;
      nstate.UploadPageVariables.isUploadSuccessful = true;
      break;
    case "LOAD_STICKER_DATA":
      nstate.stickersConfig = [...action.value];
      nstate.petStickersConfig = [...action.value].filter(
        (obj) => obj.code_type === "pet" || obj.code_type === "all"
      );
      nstate.vehicleStickersConfig = [...action.value].filter(
        (obj) => obj.code_type === "vehicle" || obj.code_type === "all"
      );
      break;
    case "SET_LOGIN_TOKEN":
      nstate.userVariables.loginTokens = { ...action.value };
      break;
    case "SET_CLIENT_SECRET_KEY":
      setClientSecret(nstate, action);
      break;
    case "SHOW_STICKER_ALERT":
      nstate.showAlert = action.value;
      break;
    case "SET_BILLING_ADDRESS":
      setBillingAddress(nstate, action.value);
      break;
    case "SET_VEHICLE_INDEX":
      setVehicleIndex(nstate, action.value);
      break;
    case "SET_PET_INDEX":
      setPetIndex(nstate, action.value);
      break;
    case "SHOW_FILES":
      showFiles(nstate, action.value);
      break;
    case "OPEN_SIDEBAR":
      nstate.sidebarOpen = action.value;
      nstate.type = action.click;
      break;
    case "CLOSE_SIDEBAR":
      nstate.sidebarOpen = action.value;
      nstate.type = action.click;
      break;

    case "CHANGE_TABLE_PAGE":
      nstate.applicationDataTableVariables.currentPage = action.value;
      break;
    case "SET_USER_LOGIN":
      setUserLogin(nstate, action.value);
      break;
    case "SET_PROFILE_MENU":
      setProfileMenu(nstate, action.idx, action.value);
      break;
    case "UPDATE_STEP":
      nstate.step = nstate.step + 1;
      break;
    case "UPDATE_PET_FEES":
      const vehicleData = nstate.currentDocument.pets;
      vehicleData.map((vehicle) => {
        if (vehicle.pet_id === null) {
          vehicle.sticker_code.fee = action.value;
        }
      });
      break;
    case "UPDATE_FESS":
      updateFees(nstate, action);
      break;
    case "SEARCH_RESULTS":
      if (action.firstLoad) {
        nstate.appGlobals.searchFirstTimeLoaded = false;
      }
      nstate.searchSectionVariables.searchResults = [...action.value.items];
      nstate.searchSectionVariables.totalCount = Math.ceil(
        action.value.total / action.value.limit
      );
      nstate.searchSectionVariables.limit = action.value.limit;
      nstate.searchSectionVariables.offset = action.value.offset;
      break;
    case "CLEAR_SEARCH_VARIABLES":
      clearSearchVariables(nstate);
      break;
    case "GET_STICKERCODES":
      let a = [...action.data];
      function unique_values(d, k) {
        return [...new Map(d.map((x) => [k(x), x])).values()];
      }
      nstate.searchSectionVariables.sticker_code = unique_values(
        a,
        (it) => it.code_id
      );
      nstate.searchSectionVariables.sticker_code_ids = a.map((x) => x.code_id);
      // nstate.searchSectionVariables.sticker_code =
      //   typeof action.data.target.value === "string"
      //     ? action.data.target.value.split(",")
      //     : action.data.target.value;
      break;
    case "GET_APPLICATION_STATUS":
      nstate.searchSectionVariables.application_status = [...action.data];
      // typeof action.data.target.value === "string"
      //   ? action.data.target.value.split(",")
      //   : action.data.target.value;
      break;
    case "VERIFIED_FORM":
      nstate.userVariables.profileVerified =
        !nstate.userVariables.profileVerified;
      break;
    case "SEARCH_METHOD":
      let x = [...nstate.applicationData.allVillageUsersApplications];
      x = x.filter((application) => application.userID === action.app_id);
      nstate.applicationData.allVillageUsersApplications = x;

      break;
    case "LOAD_API_SETTING":
      nstate.ApiLocationLoaded = true;
      // nstate.showFirstTimeUser = localStorage.getItem("showFirstTimeUser");
      break;
    case "SET_CARD_DETAILS":
      const { elementType, value } = action.details;
      nstate.PaymentPageVariables[elementType] = value;
      break;
    case "HANDLE_FOCUS":
      nstate.PaymentPageVariables.focus = action.value;
      break;
    case "SET_CURRENT_USER":
      nstate = setCurrentUser(nstate, action.value);
      break;
    case allEvents.application.vehicle.REMOVE_VEHICLE_STICKER:
      nstate = removeVehicleSticker(nstate, action.value);
      break;

    case allEvents.application.pet.REMOVE_PET_STICKER:
      nstate = removePetSticker(nstate, action.value);
      break;

    case allEvents.application.pet.UPDATE_PET_FEE_CATEGORY:
      updatePetFeeCategory(nstate, action.value);
      break;

    case allEvents.application.pet.UPDATE_PET_DATA:
      // nstate = updateNewPetData(nstate, action.value);
      if (nstate.currentlyEditingPet.pet_id !== null) {
        editPetIntoCurrentDocument(nstate);
      }
      // nstate = updateNewVehicleData(nstate, action.value, action.value2);
      else {
        addPetIntoCurrentDocument(nstate);
      }
      if (action.val2 === "previewAdd") {
        updatePetData();
      }

      break;
    case allEvents.application.pet.SET_PET_DATA:
      setPetData(nstate, action.value, action.value2, action.value3);
      break;
    case allEvents.citizen.UPDATE_USERNAME:
      nstate = updateUsername(nstate, action);
      break;
    case allEvents.user.SET_USER:
      // let newstate = { ...oldstate }
      // newstate.userIsAdmin = !newstate.userIsAdmin
      // nstate = newstate
      nstate = setUser(nstate, action.value);
      // setUser(nstate)
      break;

    case allEvents.application.status.UPDATE_APPLICATION_STATUS:
      updateApplicationStatus(nstate, action.value);
      break;

    case allEvents.application.status.UPDATE_DOCUMENT_STATUS:
      updateDocumentStatus(nstate, action.value);
      break;

    case allEvents.application.status.UPDATE_PAYMENT_STATUS:
      updatePaymentStatus(nstate, action.value);
      break;

    case allEvents.application.vehicle.UPDATE_VEHICLE_TAG_NUMBER:
      nstate = updateVehicleTagNumber(nstate, action.value, action.index);
      break;

    case allEvents.application.pet.UPDATE_PET_TAG_NUMBER:
      nstate = updatePetTagNumber(nstate, action.value, action.index);
      break;

    // case allEvents.application.vehicle.UPDATE_VEHICLE_FEE_CATEGORY:
    //   updateVehicleFeeCategory(nstate, action.value);
    //   break;

    case allEvents.application.vehicle.UPDATE_NEW_VEHICLE_DATA:
      if (nstate.currentlyEditingVehicle.vehicle_id !== null)
        editVehicleIntoCurrentDocument(nstate);
      // nstate = updateNewVehicleData(nstate, action.value, action.value2);
      else addVehicleIntoCurrentDocument(nstate);
      if (action.val2 === "previewAdd") {
        updateVehicleData();
      }
      break;
    case allEvents.application.vehicle.SET_VEHICLE_DATA:
      nstate = setVehicleData(nstate, action.value, action.value2);
      break;

    case allEvents.user.UPDATE_USER_LNAME:
      updateUserLname(nstate, action.value);
      break;
    case allEvents.user.UPDATE_USER_FNAME:
      updateUserFname(nstate, action.value);
      break;
    case allEvents.user.UPDATE_USER_EMAIL:
      updateUserEmail(nstate, action.value);
      break;

    case allEvents.user.UPDATE_USER_PHONE:
      updateUserPhone(nstate, action.value);
      break;
    // case allEvents.user.UPDATE_USER_BDAY:
    //   updateUserBirthday (nstate,action.value)
    //   break;
    case allEvents.user.UPDATE_USER_ADDRESS:
      updateUserAddress(nstate, action.value);
      break;

    case allEvents.admin.LOAD_ADMIN_HOME:
      loadAdminHome(nstate);
      break;

    case allEvents.admin.UPDATE_ADMIN_USERNAME:
      updateAdminUsername(nstate, action.value);
      break;
    case allEvents.admin.UPDATE_PASSWORD:
      updateAdminPassword(nstate, action.value);
      break;
    case allEvents.admin.SET_OFFICER_NAME:
      setOfficerName(nstate, action.value);
      break;
    case allEvents.admin.SET_CURRENT_PAGE:
      nstate = setCurrentPage(nstate, action);
      break;
    case allEvents.application.LOAD_APPLICATION_PAGE:
      loadApplicationPage(nstate, action.value, action.index);
      break;
    // changes the current page
    case allEvents.leftSidebar.SET_EXPAND:
      setExpand(nstate, action.value);
      break;
    case allEvents.adminHomeSearch.UPDATE_APPLICATION_NUMBER:
      updateApplicationNumber(nstate, action.value, action.value2);
      break;
    // updates the application number
    case allEvents.adminHomeSearch.UPDATE_TAG_NUMBER:
      updateTagNumber(nstate, action.value);
      break;
    // updates the tag number
    case "SET_TRANSACTION_REPORT_DATE":
      setTransactionReportDate(nstate, action.val);
      break;
    case allEvents.adminHomeSearch.UPDATE_DATE_FROM:
      updateDateFrom(nstate, action.value);
      break;
    // updates the date from field
    case allEvents.adminHomeSearch.UPDATE_DATE_TO:
      updateDateTo(nstate, action.value);
      break;
    // updates the date to field
    case allEvents.adminHomeSearch.UPDATE_SEARCH_TEXT:
      updateSearchText(nstate, action.value);
      break;
    case allEvents.adminHomeSearch.UPDATE_SEARCH_OFFSET:
      updateSearchOffset(nstate, action.value);
      break;
    case allEvents.adminHomeSearch.UPDATE_SEARCH_RESULT_LIMIT:
      updateSearchResultLimit(nstate, action.value);
      break;
    case allEvents.citizen.LOAD_CITIZEN_PAGE:
      nstate = loadCitizenPage(nstate, action);
      break;
    case allEvents.citizen.LOAD_CITIZEN_APPLICATION_LIST:
      nstate.citizenViewVariables.citizenApplications = action.value;
      break;
    case allEvents.citizen.LOAD_TEMP_CITIZEN_APPLICATION:
      nstate.citizenViewVariables.citizenApplications = nstate.temp;
      break;

    case allEvents.citizen.LOAD_CITIZEN_APPLICATION_CREATE:
      createCitizenApplication(nstate);
      break;
    case allEvents.citizen.LOAD_APPLICATION_LOGIN:
      loadApplicationLogin(nstate, action);
      break;
    case allEvents.application.SET_PAYMENT_MODE:
      setPaymentMode(nstate, action.value);
      break;
    case allEvents.application.SET_APPLICATION_TYPE:
      nstate = setApplicationType(nstate, action.value);
      break;
    case "LOAD_TESTDATA":
      nstate = loadTestData(nstate, action.value);
      break;
    case allEvents.citizen.FORWARD_LOGIN:
      nstate.appGlobals.isCitizenLoggedIn = true;
      break;
    default:
      break;
  }
  // if (
  //   nstate.searchSectionVariables &&
  //   nstate.searchSectionVariables.searchResults &&
  //   nstate.searchSectionVariables.searchResults.length === 0
  // ) {
  //   nstate.searchSectionVariables.searchResults = nstate.tempDataApplications;
  // }
  return nstate;
};
