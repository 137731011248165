/* eslint-disable react/prop-types */
import { AppBar, Typography, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@mui/material/Modal";
import React from "react";
import { connect } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DrawerComponent from "./DrawerComponent";
import * as s from "../../statesandlogics/sxs";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { Link } from "react-router-dom";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import { useNavigate } from "react-router-dom";
import get_logo_path from "../../statesandlogics/logoloader";

let NewAppButton = ({ showApplication, text, createApp }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate("/villageStickerApplicationAdmin/Applications/NewApplication");
  return (
    <Button
      variant="contained"
      className="navbar-buttons-fp"
      onClick={() => {
        showApplication(true);
        handleClick();
        createApp();
      }}
    >
      {text}
    </Button>
  );
};

let TransactionReportButton = ({ text, showApplication }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate(
      "/villageStickerApplicationAdmin/Applications/GenerateTransactionReport"
    );
  return (
    <Button
      variant="contained"
      className="navbar-buttons-fp"
      onClick={() => {
        showApplication(true);
        handleClick();
      }}
    >
      {text}
    </Button>
  );
};

class NavBar1 extends React.Component {
  render() {
    const {
      transactionReportDate,
      generateTransactionReport,
      setTransactionReportDate,
      loadCurrentPage,
      loadApplicationPage,
      setApplicationType,
      isLoggedIn,
      showApplication,
      createApp,
    } = this.props;

    const listItems = [
      {
        listText: "New application",
        val: "/villageStickerApplicationAdmin/Applications/NewApplication",
      },
    ];

    return (
      <Box sx={s.SXs.NavBar.SX1}>
        <AppBar position="static" style={s.SXs.NavBar.SX2}>
          <Toolbar>
            <DrawerComponent />
            <Avatar
              alt="Stickney Village Logo"
              src={get_logo_path()}
              variant="square"
              sx={s.SXs.NavBar.SX3}
            />
            {
              <>
                <Box sx={s.SXs.NavBar.SX4}>
                  <NewAppButton
                    showApplication={showApplication}
                    text={listItems[0].listText}
                    createApp={createApp}
                  />
                </Box>
                <Box sx={s.SXs.NavBar.SX4}>
                  <TransactionReportButton
                  showApplication={showApplication}
                    text={"Generate Transaction Report"}
                  />
                </Box>
              </>
            }

            {/* // ) */}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.appGlobals.isLoggedIn,
  transactionReportDate: state.transactionReportDate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setTransactionReportDate: (val) =>
      dispatch({ type: "SET_TRANSACTION_REPORT_DATE", val: val }),
    generateTransactionReport: (val) =>
      api.generateTransactionReport(val, dispatch),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    loadCitizenCurrentPage: (text) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: text }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    setExpand: (val) => dispatch({ type: "SET_EXPAND", value: val }),
    setUser: () => dispatch({ type: "SET_USER" }),
    createApp: () =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", apiData: "Nothing" }),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar1);
