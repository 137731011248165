/* eslint-disable react/prop-types */
import { AppBar } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";
import DrawerComponent from "./DrawerComponent";
import get_logo_path from '../../statesandlogics/logoloader';
import * as s from "../../statesandlogics/sxs";
import { Link } from "react-router-dom";
import { allEvents } from "../../statesandlogics/cnstshelpers";

class NavBar2 extends React.Component {
  render() {
    const {
      loadCurrentPage,
      loadApplicationPage,
      setApplicationType,
      isLoggedIn,
      showApplication,
    } = this.props;

    const listItems = [
      {
        listText: "Applications",
        val: "/villageStickerApplicationAdmin/Applications",
      },
    ];
    return (
      <Box sx={s.SXs.NavBar.SX1}>
        <AppBar position="static" style={s.SXs.NavBar.SX2}>
          <Toolbar>
            <DrawerComponent />

            <Avatar
              alt="Stickney Village Logo"
              src={get_logo_path()}
              variant="square"
              sx={s.SXs.NavBar.SX3}
            />
            {
              <>
                <Box sx={s.SXs.NavBar.SX4}>
                  <Button
                   variant="contained"
                   className="navbar-buttons-fp"
                    onClick={() => showApplication(false)}
                    component={Link}
                    to={listItems[0].val}
                    // sx={{ color: "white" }}
                  >
                    
                      {listItems[0].listText}
                    
                  </Button>
                </Box>
              </>
            }

            {/* // ) */}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.appGlobals.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
    loadCitizenCurrentPage: (text) =>
      dispatch({ type: "LOAD_CITIZEN_PAGE", value: text }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    setExpand: (val) => dispatch({ type: "SET_EXPAND", value: val }),
    setUser: () => dispatch({ type: "SET_USER" }),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar2);
