/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";
import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { Box, Typography, Button } from "@mui/material";

class PaymentForm extends Component {
  render() {
    const {
      stripe,
      elements,
      submitPaymentDetails,
      application_id,
      currentDocument,
    } = this.props;

    let vehicleFee = 0;
    let petFee = 0;
    currentDocument.vehicles.map((v) => {
      if (v.late_fee_applied && v.prorated_fee_applied) {
        vehicleFee += (v.sticker_code.fee + 20) / 2;
      } else if (v.late_fee_applied) {
        vehicleFee += v.sticker_code.fee + 20;
      } else if (v.prorated_fee_applied) {
        vehicleFee += v.sticker_code.fee / 2;
      } else {
        vehicleFee += v.sticker_code.fee;
      }
    });

    currentDocument.pets.map((p) => {
      if (p.late_fee_applied && p.prorated_fee_applied) {
        petFee += (p.sticker_code.fee + 5) / 2;
      } else if (p.late_fee_applied) {
        petFee += p.sticker_code.fee + 5;
      } else if (p.prorated_fee_applied) {
        petFee += p.sticker_code.fee / 2;
      } else {
        petFee += p.sticker_code.fee;
      }
    });

    let totalFees = vehicleFee + petFee;
    const isLoading = false;
    return (
      <form
        id="payment-form"
        onSubmit={(e) =>
          submitPaymentDetails(
            e,
            currentDocument,
            application_id,
            totalFees,
            stripe,
            elements
          )
        }
      >
        <PaymentElement id="payment-element" />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button style={{ backgroundColor: "#000" }}>
            <Button
              id="submit"
              style={{
                border: "1px solid #ddb00a",

                backgroundColor: "#000",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </Button>
          </button>
        </Box>
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">ji</div>} */}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentDocument: state.currentDocument,
  clientSecret: state.PaymentPageVariables.clientSecret,
  application_id: state.currentDocument.application_id,
});

const mapDispatchToProps = (dispatch) => {
  return {
    submitPaymentDetails: (
      e,
      currentDocument,
      application_id,
      totalFees,
      stripe,
      elements
    ) =>
      api.submitPaymentDetails(
        e,
        currentDocument,
        application_id,
        totalFees,
        stripe,
        elements,
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
