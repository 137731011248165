import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

function mapStateToProps(state) {
    return {

    };
}

let ForwardCall = () => {
    const navigate = useNavigate();
    const currRoute = useLocation();
  
    useEffect(() => {
      const timerId = setTimeout(() => {
        if (currRoute.pathname === "/" || currRoute.pathname === "") {
          navigate("/villageStickerApplicationIntro");
        }
      }, 10);
      return () => clearTimeout(timerId);
    }, [navigate, currRoute]);
  };

class NoDefaultBasePage extends Component {
    render() {
        return (
            <div>
                 <ForwardCall />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(NoDefaultBasePage);