import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { Card, Grid } from "@mui/material";
import { Button, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import * as api from "../../statesandlogics/ApiCalls/Api";
import { allEvents } from "../../statesandlogics/cnstshelpers";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const toRoute = "ReviewingApplication";
class TableCardResponsive extends Component {
  render() {
    const {
      classes,
      getUserApplicationByID,
      getUploadedDocs,
      showApplication,
      searchResults,
      setAppIdAndStatusToNew,
    } = this.props;
    return (
      <Box>
        {searchResults ? (
          searchResults.map((row) => (
            <Card className="stickerstablecard">
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Application Number </label>
                <label> : </label>
                <label className="valuestickerdata">{row.application_id}</label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> First Name </label>
                <label> : </label>
                <label className="valuestickerdata">{row.first_name}</label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Family Name </label>
                <label> : </label>
                <label className="valuestickerdata">{ row.family_name}</label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Street Address </label>
                <label> : </label>
                <label className="valuestickerdata">
                  {" "}
                  {row.address_line_1 + " " } {row.address_line_2 === null || row.address_line_2 === "null" ? "": row.address_line_2}
                </label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Date of Purchase </label>
                <label> : </label>
                <label className="valuestickerdata">
                  {" "}
                  {row.date_of_purchase ? "N/A" : row.date_of_purchase}{" "}
                </label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Fee Category </label>
                <label> : </label>
                <label className="valuestickerdata">
                  {row.fee_categories[0]}{" "}
                </label>
              </div>
              <div className="contentstickerdata">
                <label className="labelstickerdata"> Total Fee </label>
                <label> : </label>
                <label className="valuestickerdata">
                  {"$ " + Number(row.total_fee).toFixed(2)}
                </label>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  {row.status !== "Fulfilled" ? (
                    <div class="pending-label">{row.status}</div>
                  ) : (
                    <div class="fulfilled-label">Fulfilled</div>
                  )}
                  {/* <IconButton aria-label="Reciept Download">
                  <FileDownloadIcon />
                </IconButton>
                <IconButton aria-label="Approved">
                  <BeenhereIcon />
                </IconButton> */}
                </div>
                <div className="contentstickerdata">
                  {row.status !== "Fulfilled" ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className="labelstickerdata"
                      component={Link}
                      onClick={() => {
                        showApplication(true);
                        getUserApplicationByID(row.application_id);
                        getUploadedDocs(row.application_id);
                      }}
                      to={toRoute}
                    >
                      View Application
                    </Button>
                  ) : (
                    <>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="labelstickerdata"
                      component={Link}
                      onClick={() => {
                        showApplication(true);
                        getUserApplicationByID(row.application_id);
                        getUploadedDocs(row.application_id);
                      }}
                      to={toRoute}
                    >
                      View Application
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="labelstickerdata"
                      component={Link}
                      onClick={() => {
                        showApplication(true);
                        getUserApplicationByID(
                          row.application_id,
                          setAppIdAndStatusToNew
                        );
                      }}
                      to={toRoute}
                    >
                      Renewal or New
                    </Button>
                    </>
                  )}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <>No Search results</>
        )}
      </Box>
    );
  }
}
const mapStateToProps = (state) => ({
  applicationData: state.applicationData.allVillageUsersApplications,
  searchResults: state.searchSectionVariables.searchResults,
  currentPage: state.applicationDataTableVariables.currentPage,
  currentDocument: state.currentDocument,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (val) => dispatch({ type: "CHANGE_TABLE_PAGE", value: val }),
    setApplicationType: (text) =>
      dispatch({ type: "SET_APPLICATION_TYPE", value: text }),
    updateUsername: (text) =>
      dispatch({ type: "UPDATE_USERNAME", value: text }),
    setCurrentUser: () => dispatch({ type: "SET_CURRENT_USER" }),
    loadCurrentPage: (page) =>
      dispatch({ type: "SET_CURRENT_PAGE", value: page }),
    loadApplicationPage: (page) =>
      dispatch({ type: "LOAD_APPLICATION_PAGE", value: page }),
      setAppIdAndStatusToNew: () =>
      dispatch({ type: allEvents.SET_CURRENT_DOCUMENT_STATUS_TO_NEW}),
    getUserApplicationByID: (val, changeNew) => api.getUserApplicationByID(val, dispatch, changeNew),
    getUploadedDocs: (val) => api.getUploadedDocs(val, dispatch),
    showApplication: (val) =>
      dispatch({ type: allEvents.SHOW_APPLICATION_CLICK, value: val }),
    // setSubmitButtonText: (text) => dispatch({ type: 'SET_SUBMIT_BUTTON_TEXT', value: text })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCardResponsive);
