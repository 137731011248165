import React, { Component } from "react";
import ErrorModalIcon from "../../images/errormodal.png";
import { TextField, Paper, IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 500;


class ErrorDrawer extends Component {
  render() {
    const { open } = this.props;
    return (
      <>
        <div class="panel">
          <div class="header">
            <span class="title">Error Notifications</span>
            <span class="close-icon">
              <IconButton
                onClick={() => {
                 
                  this.props.closeSidebar(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </div>

          <div class="notifications-clearfix">
            <div class="line"></div>
            <div class="notification">
              <div class="circle"></div>
              <span class="any-data">Validate Data Error</span>
              <p>
                <b>Address Error</b> Number entered incorrectly.
              </p>
            </div>
            <div class="notification">
              <div class="circle"></div>
              <span class="any-data">12:19 PM</span>
              <p>
                <b>Payment Error </b> Payment not processed.
              </p>
            </div>
            <div class="notification">
              <div class="circle"></div>
              <span class="any-data">11:50</span>
              <p>
                <b>Documents Error </b> Documents verification failed
              </p>
            </div>
            <div class="notification">
              <div class="circle"></div>
              <span class="any-data">11:50</span>
              <p>
                <b>Documents Error </b> Documents verification failed
              </p>
            </div>
            <div class="notification">
              <div class="circle"></div>
              <span class="any-data">11:50</span>
              <p>
                <b>Documents Error </b> Documents verification failed
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  open: state.sidebarOpen,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openSidebar: () => dispatch({ type: "OPEN_SIDEBAR" }),
    closeSidebar: (val) =>
    dispatch({ type: "CLOSE_SIDEBAR", value: false, click: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDrawer);
