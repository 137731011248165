import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

let Text = [
  { type: "cash", text: "Paid by Cash" },
  { type: "card_online", text: "Paid by Card" },
  { type: "check", text: "Paid by Check" },
  { type: "card_in_person", text: "Paid by Card" },
];

export class RecordPaymentButton extends Component {
  render() {
    const { amount, method, currentDocument } = this.props;
    let displayText = "";
    let filteredText = [];
    if (method.length === 0) {
      displayText = "Amount Pending";
    } else {
      filteredText = Text.filter((x) => {
        return x.type === method[0];
      });
      displayText = filteredText[0]?.text;
    }

    return (
      <>
        <Card
          sx={{
            height: 160,
            backgroundColor: "#162B42",
            borderRadius: 3,
            marginRight: 3,
            marginTop:
              [...currentDocument.vehicles, ...currentDocument.pets].length ===
              0
                ? 0
                : -2,
            marginBottom: 3,
            "@media (width <= 1400px)": { width: 275 },
            "@media (width>=1400px)": { width: 350 },
          }}
        >
          <CardContent>
            <Typography
              sx={{
                fontSize: 14,
                color: "rgba(255, 255, 255, 0.8)",
                fontFamily: "poppins",
                textAlign: "left",
              }}
              color="text.secondary"
              gutterBottom
            >
              {displayText}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "rgba(255, 255, 255, 1)",
                  fontFamily: "poppins",
                  lineHeight: 2.5,
                }}
              >
                $
              </Typography>
              <Typography
                sx={{
                  fontSize: 28,
                  color: "rgba(255, 255, 255, 1)",
                  fontFamily: "poppins",
                  padding: 0,
                }}
              >
                {Number(amount).toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "rgba(255, 255, 255, 0.8)",
                  fontFamily: "poppins",
                  textAlign: "left",
                  "@media (width <= 1400px)": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                Click here to record the payment for the applicant.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                    marginTop: "12px",
                    width: "150px",
                    height: "30px",
                    color: "#162b42",
                    backgroundColor: "#ddb00a",
                    "&.Mui-disabled": {
                      background: "#B6A97C",
                      color: "#ebebeb",
                    },
                  }}
                  disabled={
                    currentDocument.transaction_info.payment_credit >= Number(amount)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.openSidebar("cardPayment");
                  }}
                >
                  Record Payment
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentDocument: state.currentDocument,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openSidebar: (val) =>
      dispatch({ type: "OPEN_SIDEBAR", value: true, click: val }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordPaymentButton);
