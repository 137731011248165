import { Grid, Pagination } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../statesandlogics/ApiCalls/Api";

class PaginationTool extends Component {
  componentDidUpdate(prevProps) {
    const limit = 0,
      offset = 0;
    if (
      prevProps.searchSectionVariables.offset !==
      this.props.searchSectionVariables.offset
    ) {
      this.props.searchApplications(
        this.props.searchSectionVariables,
        limit,
        offset
      );
    }
  }
  render() {
    const {
      searchResults,
      searchApplications,
      updateSearchOffset,
      updateSearchResultLimit,
      searchSectionVariables,
      updatePage,
    } = this.props;
    
    let limit = searchSectionVariables.limit;
    let offset = searchSectionVariables.offset;
    let count = searchSectionVariables.totalCount;
    let total = searchSectionVariables.total;

    return (
      
        <Pagination
          count={count}
          page={searchSectionVariables.page}
          color="success"
          // shape="rounded"
          showFirstButton
          showLastButton
          onChange={(event, page) => {
            updatePage(page);
            updateSearchResultLimit(limit);
            updateSearchOffset(Math.floor((page - 1) * limit));
          }}
        />
      
    );
  }
}

const mapStateToProps = (state) => ({
  searchResults: state.searchSectionVariables.searchResults,
  searchSectionVariables: state.searchSectionVariables,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchApplications: (text, limit, offset) =>
      api.searchApplications(text, limit, offset, dispatch),
    updateSearchOffset: (val) =>
      dispatch({
        type: "UPDATE_SEARCH_OFFSET",
        value: val,
      }),
    updateSearchResultLimit: (val) =>
      dispatch({
        type: "UPDATE_SEARCH_RESULT_LIMIT",
        value: val,
      }),
    updatePage: (val) => dispatch({ type: "UPDATE_PAGE", value: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginationTool);
