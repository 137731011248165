function getNearestBusinessAddress() {
    let businessAddress = {};

    switch (localStorage.getItem("siteLogoName")) {
        case "stickney":
            businessAddress.locationName = "Stickney Village Hall";
            businessAddress.line1 = "6533 W Pershing Rd";
            businessAddress.line2 = "Stickney, IL 60402";
            businessAddress.phoneNumber = "(708) 749-4400";
            break;
        default:
            businessAddress.locationName = "Quicket Solutions";
            businessAddress.line1 = "1 S Dearborn St 20th Floor";
            businessAddress.line2 = "Chicago, IL 60603";
            businessAddress.phoneNumber = "(630) 723-7723";
            break;
    }

    return businessAddress;
}

export default getNearestBusinessAddress;